import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ExchangeRequest } from "../models/exchange-request.model";

@Injectable({
  providedIn: "root"
})
export class ExchangeDataAccessService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  public GetExchangeRequests(
    submissionId: string
  ): Observable<ExchangeRequest[]> {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true",
      'Content-Type': 'application/json'
    });

    return this.httpClient.get<ExchangeRequest[]>(
      environment.exchangeRequestsUrl + `/list/${submissionId}`,
      { headers: headers, withCredentials: false }
    );    
  }
}
