import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from "src/app/core/services/spinner.service";
import { environment } from "../../../environments/environment";
import { Country } from "../models/country";

@Injectable({
  providedIn: "root"
})
export class CountryListService {
  constructor(
    private httpClient: HttpClient,
    private spinnerService: SpinnerService
  ) {}

  headers = new HttpHeaders({
    "Access-Control-Allow-Credentials": "true",
    "Content-Type": "application/json"
  });

  GetCountryList(): Observable<Country[]> {
    this.spinnerService.startSpinner();
    return this.httpClient
      .get<Country[]>(environment.countryListUrl, {
        headers: this.headers,
        withCredentials: false
      })
      .pipe(catchError((error) => {
        return of(error);
      }),
      finalize(() => this.spinnerService.stopSpinner()));
  }
}
