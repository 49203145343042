
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Registration } from '../models/registration.model';

// Used to link personal and gov accounts, and verify codes sent to link them.

@Injectable()
export class RegistrationService {
  pendingAccountLinkEmail: string;

  constructor(private httpClient: HttpClient) { }

  public PostRegistrationModel(registration: Registration): Observable<HttpResponse<any>> {
    this.pendingAccountLinkEmail = registration.MappedEmailAddress;
    const body = JSON.stringify(registration);

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    const params = new HttpParams();

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.post<any>(environment.registrationUrl, body, {
      headers,
      params
    });
  }

  ResendVerificationCode(): Observable<HttpResponse<any>>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.post<any>(environment.base + "/register/ResendVerificationCode", { headers });
  }

  public VerifyCode(registration: Registration): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify(registration);

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.put<boolean>(environment.base + "/register/verify", body, { headers });
  }
}
