<p-table #dt [value]="_selectedIncidentResponses" [globalFilterFields]="['TicketNumber','LEReferenceNumber']" [responsive]="true" [tableStyle]="{'overflow':'hidden'}" [loading]="( _loading$ | async )" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[20,50,100]" autoLayout="true" ptable508>
    <ng-template pTemplate="caption">
        <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Search Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th [pSortableColumn]="'TicketNumber'">MS Reference Number <p-sortIcon [field]="'TicketNumber'"></p-sortIcon></th>
            <th [pSortableColumn]="'OriginalReceivedDate'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Original Received Date<p-sortIcon [field]="'OriginalReceivedDate'"></p-sortIcon></th>
            <th>NDO Reference Number (Requested Expiration Date)</th>
            <th [pSortableColumn]="'LeReferenceNumber'">LE Reference Number <p-sortIcon [field]="'LeReferenceNumber'"></p-sortIcon></th>
            <th [pSortableColumn]="'State'">Request Status<p-sortIcon [field]="'State'"></p-sortIcon></th>
            <th [pSortableColumn]="'Status'">Request Status Reason<p-sortIcon [field]="'Status'"></p-sortIcon></th>
            <th class="th-center-text">View NDO Extensions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr class="table-row">
            <td class="ui-column-title">{{row.TicketNumber}}</td>
            <td class="ui-column-title">{{row.OriginalReceivedDate | date:'yyyy-MM-dd'}}</td>
            <td class="ui-column-title">
                <span *ngFor="let ndo of row.NonDisclosureOrders; let isLast = last">
                    {{ ndo.TicketNumber }} ({{ ndo.ExpirationDate | date:'yyyy-MM-dd'}})<span *ngIf="!isLast"><br></span>
                </span>
            </td>
            <td class="ui-column-title">{{row.LeReferenceNumber}}</td>
            <td class="ui-column-title">{{row.State}}</td>
            <td class="ui-column-title">{{row.Status}}</td>
            <td *ngIf="row.NonDisclosureOrders?.length > 0">
                <div class="icon-container">
                    <i class="pi pi-ellipsis-h view-ndo-extensions-icon" (click)="showIncidentResponse($event,row,op)" style="font-size:24px" pTooltip="View NDO Extensions" tooltipPosition="right"></i>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="5">
                <i>You have no NDO Extensions</i>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- NDO Pop-up -->
<p-overlayPanel #op [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        NDO Extensions for: <span style="font-weight:bold">{{_selectedIncidentResponse.TicketNumber}}</span>
        <p-table [value]="_selectedIncidentResponse.NonDisclosureOrders" [responsive]="true" [tableStyle]="{'overflow':'hidden','width':'50vw'}" autoLayout="true" ptable508 [paginator]="true" [rows]="10">
            <ng-template pTemplate="header">
                <tr>
                    <th>NDO Reference Number</th>
                    <th>Name</th>
                    <th>Created On</th>
                    <th>Requested Expiration Date</th>
                    <th>Status</th>
                    <th>Status Reason</th>
                    <th>Temporary Non-Disclosure</th>
                    <th>Document</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td class="ui-column-title">{{row.TicketNumber}}</td>
                    <td class="ui-column-title">{{row.Name}}</td>
                    <td class="ui-column-title">{{row.CreateDate | date:'yyyy-MM-dd'}}</td>
                    <td class="ui-column-title">{{row.ExpirationDate | date:'yyyy-MM-dd'}}</td>
                    <td class="ui-column-title">{{row.State}}</td>
                    <td class="ui-column-title">{{row.Status}}</td>
                    <td class="ui-column-title">{{row.TemporaryNondisclosure ? "Yes" : "No"}}</td>
                    <td class="ui-column-title"><button pButton type="button" icon="pi pi-download" (click)="showIncidentResponseDocument(row.TicketNumber)" pTooltip="Primary NDO Extension Doc {{row.Name}}" tooltipPosition="right"></button></td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>
