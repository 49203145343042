import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ServiceMessage } from '../models/service-message.model';

@Injectable()
export class MessageCenterService {
  constructor(private httpClient: HttpClient) { }

  GetMessages() {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.get<ServiceMessage[]>(environment.messageCenterUrl, { headers: headers });
  }
}
