const baseUri = 'https://apim-lens-ppe.azure-api.net/leportalapi/api';
const authClientId = 'd0aa6395-a536-4170-8151-fa32f3a95289';

export const environment = {
  appInsightsConfig: {
    key: '0dba811b-e707-4903-8414-f43c66426876'
  },
  authClientId: authClientId,
  authUrl: baseUri + '/login/validatetoken',
  base: baseUri,
  countryListUrl: baseUri + '/countrylist',
  configSettingUrl: null,
  contentReactivationUrl: baseUri + '/contentreactivation',
  deliveryUrl: baseUri + '/storage',
  exchangeRequestsUrl: baseUri + '/exchangeRequests',
  featureFlagUrl: baseUri + '/configsetting/GetFeatureFlag?key=',
  feedbackUrl: baseUri + '/feedback',
  hmr: false,
  internationalReviewUrl: baseUri + '/international/review',
  isDsaEnabled: false,
  messageCenterUrl: baseUri + '/serviceMessages',
  mySubmissionsUrl: baseUri + '/submission/my',
  production: false,
  redirectUrl: "https://leportalppe.microsoft.com/auth",
  registrationUrl: baseUri + '/register',
  scopes: [
    "openid",
    "profile",
    "email",
    `api://${authClientId}/access_as_user`
  ],
  submissionAttachmentUploadUrl: baseUri + '/submission/uploadFile',
  submissionCreateGCCUrl: baseUri + '/submission/sendToCRM',
  submissionGetAttachmentUrl: baseUri + '/submission/getfile/',
  submissionUrl: baseUri + '/submission',
  tenant: '9188040d-6c67-4c5b-b112-36a304b66dad',
  videoAssets: {
    registrationVideoUrl: 'https://leportalpublicstore.z22.web.core.windows.net/assets/videos/1a8e7bb15fd1404a812e20d6a93323ca/LE_Portal_Video_1_MIXEDAudio_2Mbps.mp4',
    dataDownloadVideoUrl: 'https://leportalpublicstore.z22.web.core.windows.net/assets/videos/94f1f4f7cba94297982afc75518cdc42/LE_Portal_Video_2_MIXEDAudio_2Mbps.mp4'
  },
};
