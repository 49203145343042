import { AbstractControl, ValidatorFn } from "@angular/forms";

export function AcknowledgementValidator(stringToMatch: string = 'I acknowledge'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === stringToMatch) {
      return null; 
    }
    return { notSame: true }; 
  };
}

