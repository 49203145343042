import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MicrosoftService } from "../../models/microsoft-service";
import { AtLeastOneValue } from '../validators/at-least-one.validator';

@Component({
  selector: "type-of-service",
  templateUrl: "./type-of-service.component.html",
  styleUrls: ["./type-of-service.component.scss"]
})
export class TypeOfServiceComponent implements OnInit {
  @Input() form: FormGroup;
  services: MicrosoftService[];

  constructor() {}

  ngOnInit() {
    this.form.addControl("selectedServices", new FormControl());
    this.form.addControl("manualServices", new FormControl());
    // They either need to select a service or enter a manual one
    this.form.setValidators(AtLeastOneValue());
    this.form.updateValueAndValidity();
    this.setServices();
  }

  private setServices() {
    this.services = [
      { Id: 1, Name: "Microsoft Services Account (MSA)" },
      { Id: 2, Name: "Skype" },
      { Id: 3, Name: "Email" },
      { Id: 4, Name: "Xbox" },
      { Id: 5, Name: "OneDrive" }
    ];
  }
}
