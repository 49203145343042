import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/portal/services/authorization.service';

@Component({
  selector: 'login-display',
  templateUrl: './login-display.component.html',
  styleUrls: ['./login-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDisplayComponent {
  show = false;
  
  constructor(
    private authService: AuthorizationService    
    ) { }

  public msalUser(): Observable<AccountInfo>{
    return this.authService.GetMsalUser();
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  signOut() {
    this.authService.Logout();
  }
}
