import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AiLoggingService } from 'src/app/core/services/ai-logging.service';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private aiLogger: AiLoggingService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authorizationService.GetLePortalUser().pipe(
      map(user => {
        return user === null
          ? this.handleNotVerified()
          : user.IsVerified
            ? true
            : this.handleNotVerified();
      }),
      catchError((error) => {
        this.aiLogger.logWarning(error?.message || String(error));
        this.router.navigate(['error']);
        return of(false);
      })      

    )
    
  }  

  handleNotVerified(): boolean{
    this.router.navigate(['/newuseraccount']);
    return false;
  } 
}
