import { AbstractControl, ValidatorFn } from "@angular/forms";

export function  AcknowledgementValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {    
    const stringToMatch: string = 'I acknowledge';
    if (
      control.value == stringToMatch
    ) {
      return null;
    }
    return { notSame: true };
  };
}