import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DeliveryContent } from '../models/delivery-content.model';


@Injectable()
export class DeliveryContentService {

    constructor(private httpClient: HttpClient) { }

    getContent(submissionId: string) {
        const headers = new HttpHeaders
            ({
                'Access-Control-Allow-Credentials': 'true'
            });
        return this.httpClient
            .get<DeliveryContent[]>(environment.deliveryUrl + `/list/${submissionId}`, { headers: headers, withCredentials: false });
    }

    getFile(submissionId: string, fileName: string) {
        const headers = new HttpHeaders
            ({
                'Access-Control-Allow-Credentials': 'true'
            });
        return this.httpClient
            .get<DeliveryContent>(environment.deliveryUrl + `/getfile/${submissionId}/${fileName}/`, { headers: headers, withCredentials: false });
    }

    getMaximumDownloadAttempts(): Observable<number> {
        const headers = new HttpHeaders
            ({
                'Access-Control-Allow-Credentials': 'true'
            });
        return this.httpClient
            .get<number>(environment.deliveryUrl + `/getMaximumDownloadAttempts`, { headers: headers, withCredentials: false });
    }

    reactivateContent(submissionId: string) {
        const headers = new HttpHeaders
            ({
                'Access-Control-Allow-Credentials': 'true'
            });
        return this.httpClient
            .get<string>(environment.contentReactivationUrl + `/reactivate-delivery-content/${submissionId}/`, { headers: headers, withCredentials: false });
    }
}

