import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Data } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'wk-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  routeParams: Params;
  data: Data;
  firstName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    

    this.routeParams = this.activatedRoute.snapshot.paramMap;
    // refactor and add accessors for the template items 
    
    this.data = this.activatedRoute.snapshot.data;
    this.spinnerService.stopSpinner();
    this.firstName = this.routeParams && this.user && this.user.split(' ').slice(0, 1).join(' ');
  } 

  get error(){
    if(this.routeParams){
      return  this.routeParams.get('error') || null;      
    }
    return null;
  }

  get id(){
    if(this.routeParams){
      return this.routeParams.get('id') || null;      
    }
    return null;
  }

  get message(){
    if(this.routeParams){
      return  this.routeParams.get('message') || null;      
    }
    return null;
  }

  get status(){
    if(this.routeParams){
      return this.routeParams.get('status') || null;
    }
    return null;
  }

  get url(){
    if(this.routeParams){
      return this.routeParams.get('url') || null;      
    }
    return null;
  }

  get user(): string{
    if(this.routeParams){
      return this.routeParams.get('user') || null;      
    }
    return null;
  }

}
