import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { Subject, of } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { AiLoggingService } from 'src/app/core/services/ai-logging.service';
import { SpinnerService } from "src/app/core/services/spinner.service";
import { Country } from "../../models/country";
import { NatureOfCrimesDTO } from "../../models/nature-of-crimes-dto.model";
import { NatureOfCrimes } from '../../models/nature-of-crimes-model';
import { NewRequestDomestic } from "../../models/new-request-domestic";
import { SubmissionAttachment } from "../../models/submission-attachment.model";
import { FileUploadService } from "../../services/file-upload.service";
import { Store } from "../../services/store.service";
import { SubmissionService } from "../../services/submission.service";
import { AcknowledgementValidator } from "../validators/acknowledgement.validator";

@Component({
  selector: "new-request-domestic",
  templateUrl: "./new-request-domestic.component.html",
  styleUrls: ["./new-request-domestic.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewRequestDomesticComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() country: Country;
  @Input() showDomesticWarning: boolean;
  domesticHeader: string;
  domesticWarningText: string;
  items: MenuItem[];
  activeIndex: Store<number>;
  needsNdo: boolean = false;
  hasNdo: boolean = false;
  submitting: boolean = false;
  submissionHistoryLocator: string;
  fileNames: string[];
  private ngUnsub: Subject<void> = new Subject<void>();
  showCancelWithFileDeleteDialog: boolean;
  showCancelSimpleDialog: boolean;
  isFaulted: boolean = false;

  get isSubmitDisabled(): boolean {
    return this.form.invalid || this.submitting || this.isFaulted;
  }


  constructor(
    private fileUploadService: FileUploadService,
    private submissionService: SubmissionService,
    private messageService: MessageService,
    private router: Router,
    private spinnerService: SpinnerService,
    private logger: AiLoggingService
  ) { }

  ngOnInit() {
    this.form.addControl(
      "domestic",
      new FormGroup({
        step1Form: new FormGroup({}),
        step2Form: new FormGroup({}),
        step3Form: new FormGroup({}),
        step4Form: new FormGroup({}),
        countryOrigin: new FormControl(this.country.Id),
      })
    );

    this.setStepperLabels();
    this.activeIndex = new Store<number>(0);

    this.domesticHeader = "Microsoft Corporation, USA";
    this.domesticWarningText =
      "Please note that your legal process must be addressed to Microsoft Corporation, USA.";

    this.domestic.addControl(
      "WAShieldLawAcknowledgement",
      new FormControl(null, [Validators.required, AcknowledgementValidator()])
    )
  }

  private setStepperLabels() {
    this.items = [
      { label: "Type of Request" },
      { label: "Nature of Crimes Details" },
      { label: "Non-Disclosure Order (NDO)" },
      { label: "File Upload" },
      { label: "Acknowledge & Submit" },
    ];
  }

  ngOnDestroy() {
    this.form.removeControl("domestic");
  }

  //#region Accessors
  // provided as forms to sub-components
  get domestic(): FormGroup {
    return this.form.get("domestic") as FormGroup;
  }
  get step1Form(): FormGroup {
    return this.domestic.get("step1Form") as FormGroup;
  }
  get step2Form(): FormGroup {
    return this.domestic.get("step2Form") as FormGroup;
  }
  get step3Form(): FormGroup {
    return this.domestic.get("step3Form") as FormGroup;
  }
  get step4Form(): FormGroup {
    return this.domestic.get("step4Form") as FormGroup;
  }

  // for the review step
  get childExploitation() {
    return this.step1Form.get("cpConcern").value === true ? "Yes" : "No";
  }

  get isSubmittingNDO() {
    return this.hasNdo ? "Yes" : "No";
  }

  get isPreservation() {
    return this.step1Form.get("isPreservationRequest").value === true
      ? "Yes"
      : "No";
  }

  get differentRecipient() {
    if (
      this.step1Form.get("hasDifferentRecipient") &&
      this.step1Form.get("hasDifferentRecipient").value
    ) {
      return this.step1Form.get("deliveryEmail").value;
    } else {
      return "N/A";
    }
  }

  get leReferenceNumber() {
    return this.step1Form.get("leReferenceNumber").value;
  }

  // errors
  get notSame() {
    return (
      this.domestic.get("acknowledgement").hasError("notSame") &&
      this.domestic.get("acknowledgement").dirty
    );
  }

  /**
 * This function retrieves the values of the NatureOfCrimes form.
 * 
 * It first gets the list of the field names of the NatureOfCrimes class.
 * It then iterates through these names and uses them to retrieve the corresponding values from the form controls.
 * 
 * The `reduce` function is used to build up an object of the NatureOfCrimesDTO type, where each property 
 * corresponds to a field name of the NatureOfCrimes class, and the value is the value of the corresponding form control.
 * 
 * This object will be used to send data to the backend in the required format.
 * 
 * @returns NatureOfCrimesDTO object which contains selected nature of crimes.
 */
  getNatureOfCrimeValues(): NatureOfCrimesDTO {
    const natureOfCrimesProperties = NatureOfCrimes.getPropertyNames();
    const natureOfCrimesObject: NatureOfCrimesDTO = natureOfCrimesProperties.reduce(
      (acc: NatureOfCrimesDTO, value: string) => {
        const formControlName = NatureOfCrimes.modifyFormControlName(NatureOfCrimes[value]);
        acc[value] = this.step2Form.get(formControlName).value;
        return acc;
      },
      {} as NatureOfCrimesDTO
    );
    natureOfCrimesObject["DescriptionOther"] = this.step2Form.get("isOtherDescription").value;
    return natureOfCrimesObject;
  }


  get notSameWAShieldLawAcknowledgement() {
    return (
      this.domestic.get("WAShieldLawAcknowledgement").hasError("notSame") &&
      this.domestic.get("WAShieldLawAcknowledgement").dirty
    );
  }


  //#endregion

  // Used to download the files a user uploads during the submission process
  doGetFile(fileName) {
    this.fileUploadService
      .getFile(this.submissionHistoryLocator, fileName)
      .pipe(
        takeUntil(this.ngUnsub),
        tap((value) => {
          const file: string = value;
          window.open(file, "_blank");
        }),
        catchError((error) => {
          this.sendUserMessagePopup(
            "error",
            "Content Download Error",
            "Your attempt to download this file failed. Please try again."
          );

          throw error;
        })
      )
      .subscribe();
  }

  previous() {
    this.activeIndex.setState(this.activeIndex.state - 1);
  }

  next() {
    switch (this.activeIndex.state) {
       case 0:
        if (this.step1Form.valid) {
          this.activeIndex.setState(this.activeIndex.state + 1);
        } else {
          this.sendUserMessagePopup(
            "error",
            "Incomplete Type of Request Step",
            "Please ensure all fields in the Request step are filled out"
          );
        }
      break;
      case 1:    
      if (this.step2Form.valid) {
        this.activeIndex.setState(this.activeIndex.state + 1);
      }
      else if (this.step2Form.get('isOtherDescription').invalid) {
        this.sendUserMessagePopup(
          "error",
          "Nature Of Crime Other Description is missing.",
          "Description is required when Other is selected."
        );
      } else {
        this.sendUserMessagePopup(
          "error",
          "Incomplete Nature of Crime(s) Step",
          "Please validate your Nature of crime(s) selections."
        );
      }
      break;
      case 2:
        if (this.step3Form.valid) {
          this.activeIndex.setState(this.activeIndex.state + 1);
        } else {
          this.sendUserMessagePopup(
            "error",
            "Incomplete NDO Step",
            "Please validate your NDO selections."
          );
        }
        break;
      case 3:
        this.uploadValidation();
        break;
      default:
        this.sendUserMessagePopup(
          "error",
          "Error: Invalid Index",
          "You should not be here. Please go back."
        );
        break;
    }
  }

  addControlValuesToForm(
    formGroup: FormGroup | FormArray,
    formToSend: FormData
  ): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key] as
        | FormControl
        | FormGroup
        | FormArray;
      // handle our files. Brittle as this assumes only our files use formarray, but that's the case today
      if (control instanceof FormArray) {
        for (const file of control.controls) {
          const fileToUpload = file.value;
          formToSend.append(fileToUpload.name, fileToUpload);
        }
      } else if (control instanceof FormControl) {
        formToSend.append(key, control.value);
      } else if (control instanceof FormGroup) {
        this.addControlValuesToForm(control, formToSend);
      } else {
        console.log("ignoring control");
      }
    });
  }

  // This method cancels the submission request the user is working on
  deleteUploadedFilesAndCancel() {
    this.showCancelWithFileDeleteDialog = false;
    this.spinnerService.startSpinner();
    this.fileUploadService
      .removeSubmissionRequest(this.submissionHistoryLocator)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(
          () => {
            this.sendUserMessagePopup(
              "success",
              "Submission Canceled",
              "Successfully canceled your submission request."
            );

            this.spinnerService.stopSpinner();
            // navigate to success page
            this.router.navigate(["/dashboard"]);
          },
          (error: any) => {
            this.sendUserMessagePopup(
              "error",
              "Cancel Error",
              "Part of the clean-up failed."
            );

            this.spinnerService.stopSpinner();
            this.router.navigate(["/dashboard"]);
          }
        )
      )
      .subscribe();
  }

  prepareFormData(): FormData {
    const formToSend = new FormData();
    this.addControlValuesToForm(this.domestic, formToSend);
    return formToSend;
  }

  resetForm() {
    this.domestic.reset(this.domestic);
  }

  // Utility method for creating pop messages that the user will see
  sendUserMessagePopup(type: string, title: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup",
    });
  }

  prepareForm(): NewRequestDomestic {
    return {
      CountryOrigin: this.country,
      NatureOfCrimes: this.getNatureOfCrimeValues(),
      DeliveryEmail: this.step1Form.get("deliveryEmail")
        ? this.step1Form.get("deliveryEmail").value
        : "",
      HasDifferentRecipient: this.step1Form.get("hasDifferentRecipient")
        ? this.step1Form.get("hasDifferentRecipient").value
        : false,
      HasNdo: this.step3Form.get("hasNdo")
        ? this.step3Form.get("hasNdo").value
        : false,
      IsChildExploitation: this.step1Form.get("cpConcern").value,
      IsWAShieldLaw: this.step1Form.get("isWAShieldLaw").value,
      IsPreservation: this.step1Form.get("isPreservationRequest").value,
      NoNdoConfirm: this.step3Form.get("isNdoConfirm")
        ? this.step3Form.get("isNdoConfirm").value
        : false,
      SubmissionHistoryLocator: this.submissionHistoryLocator,
      LEReferenceNumber: this.step1Form.get("leReferenceNumber").value,
      IdentifiersToExtend: this.step3Form.get("listOfNumbersToExtend")
        ? this.step3Form.get("listOfNumbersToExtend").value
            .replace(/^[ ,\n\t]+|[ ,\n\t]+$/g, " ") // replace commas, new lines, tabs with space
            .trim()
            .split(/\s+/)
        : []
    };
  }

  // This method sends the submission to the web api for completion
  submit() {

    const formToSend = this.prepareForm();
    this.submitting = true;
    this.spinnerService.startSpinner();
    this.logger.logInformation("Submitting new request.");
    this.submissionService
      .postDomesticSubmission(formToSend)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(() => {
          this.logger.logInformation("Successfully submitted new request.");
          this.sendUserMessagePopup(
            "success",
            "Submission Confirmation",
            "Thank you for your submission.  Your request will be reviewed."
          );

          this.submitting = false;
          this.spinnerService.stopSpinner();
          // navigate to success page
          this.router.navigate(["/dashboard"], {
            queryParamsHandling: "preserve",
          });
        }),
        catchError((error) => {
          this.logger.logInformation("Failed to submit new request.");
          this.sendUserMessagePopup(
            "error",
            "Submission Error",
            "Submission failed.  Please try again."
          );
          this.submitting = false;
          this.isFaulted = true;
          this.spinnerService.stopSpinner();
          // throw so the Global handler can log this
          return of(error);
        })
      )
      .subscribe();
  }

  // This method is used to show/hide the cancel dialog box
  toggleCancelWithFileDeleteDialog() {
    this.showCancelWithFileDeleteDialog = !this.showCancelWithFileDeleteDialog;
  }

  // This is used to toggle the simple cancel function, which simply routes the user back to the dashboard.
  // This is only used in steps 1-3 before they upload any files.
  toggleSimpleCancelDialog() {
    this.showCancelSimpleDialog = !this.showCancelSimpleDialog;
  }

  updateNeedsNdo(needsNdo) {
    if (needsNdo) {
      this.domestic.addControl(
        "acknowledgement",
        new FormControl(null, [Validators.required, AcknowledgementValidator()])
      );
    }
    this.needsNdo = needsNdo;
  }

  updateHasNdo(hasNdo) {
    if (hasNdo) {
      this.domestic.addControl(
        "isNdoUploadFinalConfirm",
        new FormControl(null, Validators.requiredTrue)
      );
      this.domestic.updateValueAndValidity();
    } else {
      this.domestic.removeControl("isNdoUploadFinalConfirm");
    }
    this.hasNdo = hasNdo;
  }

  uploadAttachments() {
    const formModel: FormData = this.prepareFormData();
    this.spinnerService.startSpinner();
    this.fileUploadService
      .postAttachment(formModel)
      .pipe(
        takeUntil(this.ngUnsub),
        tap((resp) => {
          let sub: SubmissionAttachment = resp.body;
          this.submissionHistoryLocator = sub.SubmissionHistoryLocator;
          this.fileNames = sub.FileNames;
          this.spinnerService.stopSpinner();
          this.sendUserMessagePopup(
            "success",
            "Attachments Upload Confirmation",
            "Successfully uploaded your attachment, please continue to finish the submission."
          );
          this.activeIndex.setState(this.activeIndex.state + 1);
        }),
        catchError((error: any) => {
          this.spinnerService.stopSpinner();
          this.sendUserMessagePopup(
            "error",
            "File Upload Error",
            "Submission failed. Please try again."
          );
          // throw so the Global handler can log this
          throw error;
        })
      )
      .subscribe();
  }

  uploadValidation() {
    if (this.step4Form.get("files").hasError("invalidMinCount")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "You must upload a file before you continue.  Please try again."
      );
    } else if (this.step4Form.get("files").hasError("invalidSize")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file did not meet the size requirements.  Please try again."
      );
    } else if (this.step4Form.get("files").hasError("invalidExt")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file has an invalid file extension.  Please try again."
      );
    } else if (!this.step4Form.valid) {
      this.sendUserMessagePopup(
        "error",
        "Confirm NDO Upload",
        "Please confirm you have uploaded an NDO with your attachment upload."
      );
    } else {
      // Upload will handle incrementing the activeIndex depending on successful upload status
      this.uploadAttachments();
    }
  }
}
