import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  // DateAgo calculates time duration given Date value
  // Optionally, argument can be passed in to specify the endDate
  // If no endDate is passed in, Date() (i.e. now) is used

  transform(value: any, end?: any): any {
    if (value) {

      // Assuming startDate is in UTC
      const startDate = new Date(value);
      const startDateInMillis = startDate.getTime();

      // Get Now in UTC
      let endDate = new Date();
      let endDateInMillis = endDate.getTime() + (endDate.getTimezoneOffset() * 60000);

      // If endDate is passed in via args, overwrite the endDateInMillis
      if (end) {
        endDate = new Date(end);
        // Assuming endDate is in UTC
        endDateInMillis = endDate.getTime();
      }

      const seconds = Math.floor((endDateInMillis - startDateInMillis) / 1000);

      if (seconds < 29) { // less than 30 ceconds, show as "Just now"
        return 'just now';
      }

      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };

      const keys = Object.keys(intervals);

      // Attempt to return intervals 2 levels deep (e.g. 1 month, 10 days)
      for (let i = 0; i < keys.length; i++) {
        const interval1 = this.getDurationFromSeconds(seconds, intervals[keys[i]], keys[i]);
        let interval2 = null;

        if (interval1) {
          if (keys[i + 1]) {
            const counter = Math.floor(seconds / intervals[keys[i]]);
            interval2 = this.getDurationFromSeconds(seconds - (counter * intervals[keys[i]]), intervals[keys[i + 1]], keys[i + 1]);
          }

          return interval2 ? `${interval1}, ${interval2}` : interval1;
        } else {
          continue;
        }
      }

      return value;
    }
  }

  getDurationFromSeconds(totalSeconds: number, intervalValue: number, intervalName: string) {
    const counter = Math.floor(totalSeconds / intervalValue);
    if (counter > 0) {
      if (counter === 1) {
        return counter + ' ' + intervalName; // singular (1 day)
      } else {
        return counter + ' ' + intervalName + 's'; // plural (2 days)
      }
    }

    return null;
  }
}
