
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AccessDataStatus } from '../../enums/access-data-status';
import { SubmissionStatus } from '../../enums/submission-status.enum';
import { SubmissionHistory } from '../../models/submission-history.model';
import { User } from '../../models/user.model';
import { AuthorizationService } from '../../services/authorization.service';
import { SubmissionService } from '../../services/submission.service';

@Component({
  selector: 'my-submissions',
  templateUrl: './my-submissions.component.html',
  styleUrls: ['./my-submissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MySubmissionsComponent implements OnInit, OnDestroy {
  loading$ = new BehaviorSubject<boolean>(true);
  submissionData: SubmissionHistory[];
  submissionStatus: typeof SubmissionStatus = SubmissionStatus;
  accessDataStatus = AccessDataStatus;
  user: User;
  canViewStatus: number[] = [1, 4];

  private ngUnsub: Subject<void> = new Subject<void>();
  selectedStatus: number = 0;
  
  constructor(
    private router: Router,
    private submissionService: SubmissionService,
    private authorizationService: AuthorizationService
  ) { }

  ngOnInit() {
    this.authorizationService.GetLePortalUser()
    .pipe(
      takeUntil(this.ngUnsub),
      tap((user:User) => {
        this.user = user;
      }))
    .subscribe();
    
    this.getMySubmissions();
  }
  
  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  getMySubmissions() {
    this.submissionService
    .getSubmissions()
    .pipe(
      takeUntil(this.ngUnsub),
      tap((val: SubmissionHistory[]) => this.submissionData = val),      
      finalize(() => this.loading$.next(false))
      )
    .subscribe();
  }

  filterStatusTypes(status: number, dt: any) {
    if (status != -1) {
      dt.filter(status, 'Status', 'equals');
    }
    else {
      dt.reset();
    }
  }

  canAccessData(data: SubmissionHistory) : number {
    if(this.user == null || !this.canViewStatus.includes(data.Status)){
      return AccessDataStatus.notPublished;
    }    

    //There's an OnBehalfOf
    if(data.OnBehalfOf){
      //  and it is their official email address
        if(this.compareCaseInsensitive(this.user.OfficialEmailAddress, data.OnBehalfOf)) {
        return AccessDataStatus.hasAccessAsAlternateRecipient;
      }
      // There's an OnBehalfOf and it is not their email address
        return AccessDataStatus.doesNotHaveAccess;
    }
    
    // There's no OnBehalfOf (or it's null/undefined/empty). They are the submitter.
      if (this.compareCaseInsensitive(this.user.OfficialEmailAddress, data.Submitter)) {
        return AccessDataStatus.hasAccessAsSubmitter;
    }
  }

  compareCaseInsensitive(a, b): boolean {
    return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
  }
}
