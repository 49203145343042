import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { LEPortalError } from '../../portal/models/le-portal-error';

@Injectable()
export class AiLoggingService { 
  constructor(
    private appInsights: ApplicationInsights,
    private router: Router) {
    this.appInsights.loadAppInsights();    
    
    var telemetryInitializer = (envelope) => {
      envelope.tags["ai.cloud.role"] = "LE.Portal.UI";
    }
    this.appInsights.addTelemetryInitializer(telemetryInitializer);

    this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
      const activatedComponent = this.getActivatedComponent(event.state.root);
      if (activatedComponent) {
        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        this.appInsights.flush();
      }
    });   
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {    
    this.appInsights.trackPageView({ name, uri });
  }

  logUnhandledException(error: Error){
    this.appInsights.trackException({exception: error, severityLevel: SeverityLevel.Error});
  }
  
  logHandledException(error: LEPortalError){
    this.appInsights.trackException({exception: new Error(error.message), severityLevel: SeverityLevel.Error, properties: error});
  }

  logInformation(info: string) {
    this.appInsights.trackTrace({message: info, severityLevel: SeverityLevel.Information});        
  }

  logWarning(warning: string) {
    this.appInsights.trackTrace({message: warning, severityLevel: SeverityLevel.Warning});    
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  
}
