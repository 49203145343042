import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { SubmissionAttachment } from "../models/submission-attachment.model";

@Injectable()
export class FileUploadService {
  constructor(
    private httpClient: HttpClient
  ) {}

  postForm(formToPost: FormData) {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.post(environment.submissionUrl, formToPost, {
      headers: headers,
      withCredentials: false
    });
  }

  postAttachment(
    formToPost: FormData
  ): Observable<HttpResponse<SubmissionAttachment>> {    
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });
    return this.httpClient.post<SubmissionAttachment>(
      environment.submissionAttachmentUploadUrl,
      formToPost,
      { headers: headers, withCredentials: false, observe: "response" }
    );
  }

  postSubmission(submissionHistoryId: string) {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.post(
      environment.submissionCreateGCCUrl + `/${submissionHistoryId}`,
      { headers: headers, withCredentials: false }
    );
  }

  removeSubmissionRequest(submissionHistoryGuid: string) {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient.delete(
      environment.submissionUrl + `/${submissionHistoryGuid}`,
      { headers: headers, withCredentials: false }
    );
  }

  getFile(submissionHistoryGuid: string, fileName: string) {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });
    const encodedFileName = encodeURIComponent(fileName);
    return this.httpClient.get<string>(
      environment.submissionGetAttachmentUrl +
        `${submissionHistoryGuid}/${encodedFileName}/`,
      { headers: headers, withCredentials: false }
    );
  }
}
