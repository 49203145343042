import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable ,  of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class FeatureflagService {
  isFeatureEnabled: { [key: string]: boolean } = {};
  fetchingFeatureCondition: { [key: string]: Observable<any> } = {};
  constructor(
    private httpClient: HttpClient
    ) {}


  IsEnabled(key: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true'
    });

    if (key) {
      if (this.isFeatureEnabled[key]) {
        // Fetched already
        return of(this.isFeatureEnabled[key]);
      } else if (this.fetchingFeatureCondition[key]) {
        // Fetching already
        return this.fetchingFeatureCondition[key];
      } else {
        // Let's fetch!
        return (this.fetchingFeatureCondition[key] = this.httpClient
          .get<boolean>(environment.featureFlagUrl + key, { headers: headers })
          .pipe(
            map(response => {
              this.fetchingFeatureCondition[key] = null;
              return (this.isFeatureEnabled[key] = response);
            })
          ));
      }
    }
  }
}
