<div [formGroup]="form" class="p-grid p-dir-col">

  <div *ngIf="needsNdo">
    <div class="p-col">
      <label>
        Are you submitting a Non-Disclosure Order (NDO) <u>or</u> does
        applicable federal/state law otherwise restrict disclosure to the
        target?
      </label>
      <div class="input-switch-container">
        <p-selectButton
          [options]="yesno"
          formControlName="hasNdo"
        ></p-selectButton>
      </div>
    </div>
    <div class="p-col ndo-confirm" *ngIf="!hasNdoControl.value">
      <p-checkbox
        id="isNdoConfirm"
        binary="true"
        formControlName="isNdoConfirm"
        label="I acknowledge that I am not submitting a Non-Disclosure Order associated with this law enforcement request. I understand that Microsoft will, as appropriate, take steps to notify the user or customer of this law enforcement request for information."
        ariaLabelledBy="isNdoConfirm"
        ></p-checkbox>
    </div>
  </div>
  <div class="p-col ndo-confirm" *ngIf="!needsNdo">
    <p-message
    severity="success"
    text="You do not need to submit an NDO based on your selection. Please press 'Next' to continue."
    ></p-message>
    <hr />
  </div>
</div>
<hr />
