<div id="registration-form-container" class="row h-100 p-3 my-5 mx-auto justify-content-center">

    <form [formGroup]="registrationForm">
        <p-card>
            
            <header>
                <h2 id="registration-title" >
                    New User Account Validation
                </h2>
                <p class="small">
                    This portal is only for the use of authorized law enforcement officials who wish to submit lawful orders seeking the production of evidence in connection with an official law enforcement investigation.
                    Microsoft will evaluate each request on an individual, case-by-case basis consistent with applicable law, our customer commitments, and corporate policies.
                </p>
                <p>
                    Now that you've created or entered a Microsoft Account, you will need to link your Agency email address with this Microsoft account.
                    <br/>
                    <br/>
                    <b>Enter your <u>Agency</u> email address below. </b> Your Agency email address will receive all email 
                    communications notifying you when new packaged data is available for download, along with other helpful email 
                    notifications. Failure to link your Agency email address may result in delayed delivery of packaged data.
                </p>
            </header>
            <div id="registration-form-content" class="row">                  
                <div class="w-100"></div>
                <div id="registration-fields-container" class="col">
                    <div id="registration-fields-email" class="row justify-content-center mb-4">
                        <span class="ui-float-label">
                            <input id="emailAddress" type="email" size="38" formControlName="emailAddress" pInputText>
                            <label for="emailAddress">Government Agency Email Address</label>
                        </span>
                    </div>
                    <div id="registration-fields-confirmemail" class="row justify-content-center mb-4">
                        <span class="ui-float-label">
                            <input id="confirmEmail" type="email" size="38" formControlName="confirmEmail" autocomplete="off" pInputText>
                            <label for="confirmEmail">Confirm Government Agency Email Address</label>
                        </span>
                    </div>                      
                    <ng-container *ngIf="(confirmEmail.touched || emailAddress.touched) && registrationForm.invalid">
                        <div id="registration-form-errors" class="row justify-content-center mb-4">
                            <span class="ui-messages-error">{{getErrorMessage()}}</span>
                        </div>
                    </ng-container>
                    <div id="registration-form-submit" class="row justify-content-center">
                        <p-button [disabled]="IsPageInvalid()" (onClick)="SubmitRegistration()" label="Submit"></p-button>
                </div>
            </div>
        </div>
        <footer>
            <hr />
            <p class="registration-footer m-1">
                <strong>Warning:</strong> This portal may only be used by authorized law enforcement officials to submit legal production orders related to official investigations pursuant to Title 18, United States Code,
                Sections 2703 and 2711. Unauthorized use of this portal may be referred to law enforcement for investigation and prosecution.  By requesting access, you are acknowledging that you are a government official
                making a request in official capacity.
            </p>
        </footer>
    </p-card>
</form>

</div>