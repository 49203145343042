import { AbstractControl, ValidatorFn } from "@angular/forms";

export const  EmailMatchValidator = (
  firstEmailControlName: string,
  secondEmailControlName: string
): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    const firstEmail: string = control.get(firstEmailControlName).value;
    const secondEmail: string = control.get(secondEmailControlName).value;
    if (
      firstEmail &&
      secondEmail &&
      !(firstEmail.toLowerCase() == secondEmail.toLowerCase())&&
      control.get(secondEmailControlName).dirty
    ) {
      return { noMatch: true };
    }
    return null;
  };
}
