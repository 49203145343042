<div class="p-grid p-dir-col" [formGroup]="form">
  <div class="p-col-12">
    Nature of Crime(s) Being Investigated
  </div>
  <div class="flex-container">
    <ng-container *ngFor="let noc of natureOfCrimes; let i = index">
      <div class="crime-items-grid">
        <p-checkbox [formControlName]="noc.formControlName" binary="false"
          tabindex="{{i + 1}}" (keydown.space)="toggleCheckbox($event, form.get(noc.formControlName))">
        </p-checkbox>
        <label [for]="'checkbox_' + i">{{ noc.displayName }}</label>
      </div>
    </ng-container>
  </div>
  <!-- Other Description -->
  <div *ngIf="form.get('other').value" class="description-container">
    <label for="DescriptionOther">Description:</label>
    <input pInputText id="DescriptionOther" formControlName="isOtherDescription" placeholder="Nature of Crime"
      [maxlength]="crimeNatureMaxLength">
  </div>
</div>