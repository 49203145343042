import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import * as FileValidator from "../validators/file-upload.validator";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() hasNdo: boolean = false;

  uploadButton: boolean = false;

  get files(): FormArray {
    return this.form.get("files") as FormArray;
  }

  get invalidMinCount(){
    return this.files.hasError('invalidMinCount');
  }

  get invalidSize(){
    return this.files.hasError('invalidSize');
  }

  constructor() {}

  ngOnInit() {
    this.form.addControl(
      "files",
      new FormArray(
        [],
        [
          FileValidator.FileExtension(["PDF", "DOC", "DOCX", "XLS", "XLSX"]),
          FileValidator.FileSize(26214400),
          FileValidator.FileCountRange(1, null)
        ]
      )
    );
    if(this.hasNdo){
      this.form.addControl('isNdoUploadConfirm', new FormControl(null, Validators.requiredTrue));
    }else{
      this.form.removeControl('isNdoUploadConfirm');

    }
    this.edgeHack();
  }

  edgeHack() {
    // toggle
    this.uploadButton = !this.uploadButton;
  }  

  handleFileChange(fileList) {
    const newFiles = fileList;
    for (const newFile of newFiles) {
      const newFileControl = new FormControl(newFile, [
        FileValidator.FileExtension(["PDF", "DOC", "DOCX", "XLS", "XLSX"]),
        FileValidator.FileSize(26214400)
      ]);
      this.files.push(newFileControl);
    }
  }

  removeFile(index: number) {
    this.files.removeAt(index);
    this.edgeHack();
  }
}
