import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'ndoredirect',
  templateUrl: './ndoredirect.component.html',
  styleUrls: ['./ndoredirect.component.scss']
})
export class NdoRedirectComponent implements OnInit
{

  @Input() _ndoRedirectHide: Subject<boolean>;

  constructor(private _router: Router ) { }

  ngOnInit(): void {
  }

  showNDODashboard(): void {
    this._router.navigateByUrl('dashboard-ndo?create');
  }

  showNewRequest(): void {
    this._ndoRedirectHide.next(true);
  }

}
