// Modules
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, Optional, SkipSelf, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MessageService } from "primeng/api";
import { MessagesModule } from "primeng/messages";
import { SharedModule } from '../shared/shared.module';
// Components
import { CountdownTimerComponent } from "./components/countdown-timer/countdown-timer.component";
import { ErrorComponent } from "./components/error/error.component";
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MessageCenterComponent } from "./components/message-center/message-center.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { PageFooterComponent } from "./components/page-footer/page-footer.component";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
// Services
import { AiLoggingService } from "./services/ai-logging.service";
import { CountdownTimerService } from "./services/countdown-timer.service";
import { FeedbackService } from './services/feedback.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { LoginDisplayComponent } from "./components/navbar/login-display/login-display.component";
import { MessageCenterService } from "./services/message-center.service";
import { SpinnerService } from "./services/spinner.service";
import { ErrorService } from './services/error.service';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

@NgModule({
  declarations: [
    CountdownTimerComponent,
    ErrorComponent,
    FeedbackComponent,
    MessageCenterComponent,
    NavbarComponent,
    PageFooterComponent,
    PageHeaderComponent,
    LoginDisplayComponent,
    
  ],
  exports: [
    CommonModule,
    CountdownTimerComponent,
    ErrorComponent,
    FeedbackComponent,
    HttpClientModule,
    NavbarComponent,
    MessageCenterComponent,
    PageFooterComponent,
    PageHeaderComponent,    
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MessagesModule,
    RouterModule,
    SharedModule
  ],
  providers: [
    AiLoggingService,
    CountdownTimerService,    
    ErrorService,
    FeedbackService,
    MessageService,
    MessageCenterService,
    SpinnerService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},    
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
