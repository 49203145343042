import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'international-dashboard',
  templateUrl: './international-dashboard.component.html',
  styleUrls: ['./international-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternationalDashboardComponent implements OnInit {

  ngOnInit() {
  }
}
