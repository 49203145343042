export enum LEPortalApiRequestType{
  GET,
  POST
}
export interface LEPortalApiRequest<T>
{
  UrlSuffix: string
  Data: Array<T>
  ApiRequestType: LEPortalApiRequestType
}

export interface LEPortalApiResponseDiff<T, U>
{
  DataResponse: Array<U>
  ApiRequest: LEPortalApiRequest<T>
  StatusCode: string
  Exception: boolean
}

export interface LEPortalApiResponse<T> {
  Data: Array<T>
  StatusCode: string
  Exception: boolean
}
