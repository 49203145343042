export class NatureOfCrimes {
    static IsTerrorism = 'Terrorism';
    static IsChildExploitation = 'Child Exploitation';
    static IsHumanTrafficking = 'Human Trafficking';
    static IsMissingPerson = 'Missing person';
    static IsCorruption = 'Corruption';
    static IsCybercrime = 'Cybercrime';
    static IsDefamation = 'Defamation';
    static IsHateSpeech = 'Hate Speech';
    static IsMoneyLaundering = 'Money laundering';
    static IsHarassment = 'Harassment/Personal Safety';
    static IsTheft = 'Theft';
    static IsViolentCrime = 'Violent Crime/Crime Against Person';
    static IsAdultSexualAbuseOrExploitation = 'Adult Sexual Abuse/Exploitation';
    static IsDrugsOrDrugTrafficking = 'Drugs/Drug Trafficking';
    static IsFraudWithMoneyLaundering = 'Fraud/Financial crime (with Money Laundering)';
    static IsFraudNoMoneyLaundering = 'Fraud/Financial crime (no Money Laundering)';
    static IsOther = 'Other';

   // Returns an array of property names (crime types)
  static getPropertyNames(): string[] {
    const fields: string[] = [];
    const rawFields = Object.getOwnPropertyNames(NatureOfCrimes);
    rawFields.forEach((field) => {
      if (
        typeof NatureOfCrimes[field] === 'string' &&
        NatureOfCrimes[field] !== '' &&
        field !== 'name'
      ) {
        fields.push(field);
      }
    });
    return fields;
  }

  // Returns an array of property values (crime type names)
  static getPropertyValues(): string[] {
    const values: string[] = NatureOfCrimes.getPropertyNames().map((field) => NatureOfCrimes[field]);
    return values;
  }

  // Gets the modified form control name by removing spaces
  static modifyFormControlName(item: string): string {
    return item.replace(/\s/g, '').toLowerCase();
  }
}
