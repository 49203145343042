import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})

export class NewUserGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizationService.GetLePortalUser().pipe(
      map((user: User) => {
        return user.IsVerified
          ? this.handleIsExistingUser()
          : true
      })
    );
  }

  handleIsExistingUser(): boolean {
    // If they're already verified/linked, then the answer is no. This is to prevent users from attempting to remap to a new address. 
    this.router.navigate(['/unauthorized', { unauthorizedReason: 'User is already Verified. Unable to access this page while the account is in a verified state.' }]);
    return false;
  }
}
