import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Feedback } from "../models/feedback.model";

@Injectable({
  providedIn: "root"
})
export class FeedbackService{
  constructor( private httpClient: HttpClient ) {}

  submitFeedback(feedback: Feedback) {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Credentials": "true"
    });

    // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
    return this.httpClient
      .post(environment.feedbackUrl, feedback, {
        headers: headers,
        withCredentials: false
      });
  }
}
