import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  public token: string;
  isDeliveryEnabled: boolean;
  showNotForEmergenciesDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  msgs: Message[] = [];

  constructor(
    private router: Router,
  ) {}
  
  ngOnInit() {
    this.checkBrowserCompatibility();
  }

  showNDODashboard(): void {
    this.router.navigateByUrl('dashboard-ndo');
  }

  toggleNotForEmergenciesDialog(): void {
    this.showNotForEmergenciesDialog$.next(!this.showNotForEmergenciesDialog$.value)    
  }   

  continue(): void {
    this.toggleNotForEmergenciesDialog();
    this.router.navigate(['/new-request']);
  }

  public checkBrowserCompatibility() {
    var incompatibleWarnMessage = {
      severity:'warn', 
      summary:'Incompatible Browser', 
      detail:'This site does not support the browser you are using, which may cause unusual or unexpected behavior and issues. Please consider using a browser such as Chrome, IE, or Edge.'
    };
    const agent = window.navigator.userAgent.toLowerCase();

    switch (true) {
      // Important note: order matters, since some browsers are based on others. The current order was inspired by: https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
      // Firefox
      case agent.indexOf('firefox') > -1:
        break;
      // Samsung Internet
      case agent.indexOf("samsungbrowser") > -1:
        this.msgs.push(incompatibleWarnMessage);
        break;
      // Opera
      case agent.indexOf('opr') > -1 || agent.indexOf('opera'):
        this.msgs.push(incompatibleWarnMessage);
        break;
      // Internet Explorer
      case agent.indexOf('trident') > -1:
        break;
      // Edge
      case agent.indexOf('edge') > -1:
        break;
      // Chrome
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        break;
      // Safari
      case agent.indexOf('safari') > -1:
        this.msgs.push(incompatibleWarnMessage);
        break;
      // Other browsers - may have to be updated for edgium  
      default:
        this.msgs.push(incompatibleWarnMessage);
        break;
      };
    }
}
