import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/portal/models/user.model';
import { AuthorizationService } from 'src/app/portal/services/authorization.service';

@Component({
  selector: 'page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent {
  constructor(private authService: AuthorizationService) {}
  copyrightDate: number = new Date().getFullYear();  
  
  getUser(): Observable<User>
  {
    return this.authService.GetLePortalUser();
  }

}
