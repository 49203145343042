<div id="feature">
  <br />
  <div>
    <h1 style="align-content: center;">The Microsoft Law Enforcement Portal is currently down for maintenance.
    {{ message$ | async }} We appreciate your patience.</h1>
  <br />
  <h1 style="align-content: center;">If you have an immediate, urgent, threat to life issue, please contact
  lealert@microsoft.com. Non-emergency issues will not receive a response.</h1>
  </div>
</div>
