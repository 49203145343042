<div id="my-submissions">
    <p-table #dt [value]="submissionData" [responsive]="true" [tableStyle]="{'overflow':'hidden'}" [loading]="loading$ | async" [paginator]="true" [rows]="10" 
    [rowsPerPageOptions]="[5,10,20]" sortField="SubmittedOnUTC" sortMode="single" sortOrder="-1" autoLayout="true" ptable508>
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'RecordLocatorId'">MS Reference Number <p-sortIcon [field]="'RecordLocatorId'"></p-sortIcon></th>
                <th [pSortableColumn]="'LEReferenceNumber'">LE Reference Number <p-sortIcon [field]="'LEReferenceNumber'"></p-sortIcon></th>
                <th [pSortableColumn]="'Status'">Request Status <p-sortIcon [field]="'Status'"></p-sortIcon></th>
                <th [pSortableColumn]="'Type'">Type <p-sortIcon [field]="'Type'"></p-sortIcon></th>
                <th [pSortableColumn]="'SubmittedOnUTC'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Created <p-sortIcon [field]="'SubmittedOnUTC'"></p-sortIcon></th>
                <th></th>
            </tr>           
        </ng-template>
        <ng-template pTemplate="body" let-sub >
            <tr style="border-style: none">
                <td class="ui-column-title">{{sub.RecordLocatorId}}</td>
                <td class="ui-column-title">{{sub.LEReferenceNumber}}</td>
                <td class="ui-column-title">{{this.submissionStatus[sub.Status]}}</td>
                <td class="ui-column-title">{{sub.Type}}</td>
                <td class="ui-column-title">{{sub.SubmittedOnUTC | date:'yyyy-MM-dd HH:mm UTC'}}</td>
                <ng-container [ngSwitch]='canAccessData(sub)'>
                    <td *ngSwitchCase='accessDataStatus.notPublished'></td>
                    <td *ngSwitchCase='accessDataStatus.doesNotHaveAccess'>
                        <a pTooltip="Data Delivery was made to an Alternate Recipient as requested.  
                        The Alternate Recipient has access to download the packaged data." 
                        tooltipPosition="top"><span class="pi pi-info-circle" style="vertical-align: bottom;"></span>&nbsp;Recipient: {{ sub.OnBehalfOf }}</a>
                    </td>
                    <td *ngSwitchCase="accessDataStatus.hasAccessAsSubmitter">
                            <a [routerLink]="['/delivery-content', sub.Id]" 
                            queryParamsHandling="preserve"><span class="pi pi-arrow-down">
                            </span>&nbsp; Access Data</a>
                    </td>             
                    <td *ngSwitchCase="accessDataStatus.hasAccessAsAlternateRecipient">
                        <a [routerLink]="['/delivery-content', sub.Id]" 
                        queryParamsHandling="preserve"><span class="pi pi-arrow-down">
                        </span>&nbsp; Access Data</a>
                </td>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="5">
                    <i>You have no submissions of this type.</i>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>