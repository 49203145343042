// Components
import { BrowserModule, Title } from '@angular/platform-browser';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardNdoComponent } from './components/dashboard-ndo/dashboard-ndo.component';
import { NdoMySubmissionsComponent } from './components/dashboard-ndo/ndo-my-submissions/ndo-my-submissions.component';
import { DeliveryContentComponent } from './components/delivery-content/delivery-content.component';
import { DeliveryLocationComponent } from './components/delivery-location/delivery-location.component';
import { EmailMonitoringComponent } from './components/email-monitoring/email-monitoring.component';
import { EmergencyDialogComponent } from './components/emergency-dialog/emergency-dialog.component';
import { FaqComponent } from './components/faq/faq.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FilesToUploadComponent } from './components/files-to-upload/files-to-upload.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { InternationalContentReviewComponent } from './components/international-dashboard/international-content-review/international-content-review.component';
import { InternationalDashboardComponent } from './components/international-dashboard/international-dashboard/international-dashboard.component';
import { LegalNotificationComponent } from './components/legal-notification/legal-notification.component';
import { LoginTroubleDialogComponent } from './login-trouble-dialog/login-trouble-dialog.component';
import { MyInternationalSubmissionsComponent } from './components/international-dashboard/my-international-submissions/my-international-submissions.component';
import { MySubmissionsComponent } from './components/my-submissions/my-submissions.component';
import { NatureOfCrimeComponent } from './components/nature-of-crime/nature-of-crime.component';
import { NdoComponent } from './components/ndo/ndo.component';
import { NdoRedirectComponent } from "./components/ndo/ndoredirect/ndoredirect.component";
import { PortalAppComponent } from './portal-app.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { SubmissionNotificationComponent } from './components/submission-notification/submission-notification.component';
import { TypeOfRequestComponent } from './components/type-of-request/type-of-request.component';
import { TypeOfServiceComponent } from './components/type-of-service/type-of-service.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { ViewSubmissionsComponent } from './components/view-submissions/view-submissions.component';
import { RequestAgentInfoComponent } from './components/request-agent-info/request-agent-info.component';

// Misc
import { environment } from '../../environments/environment';

// Modules
import { CoreModule } from '../core/core.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxLoadingModule } from 'ngx-loading';
import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { PortalAppPrimeNgModule } from './portal-app-primeng.module';
import { PortalAppRoutingModule } from './portal-app-routing.module';
import { SharedModule } from '../shared/shared.module';

// Services
import { ConfirmationService } from 'primeng/api';
import { CountryListService } from './services/country-list.service';
import { DeliveryContentService } from './services/delivery-content.service';
import { DeliveryLocationService } from './services/delivery-location.service';
import { ExchangeDataAccessService } from './services/exchange-data-access.service';
import { FaqService } from './services/faq.service';
import { FeatureflagService } from '../core/services/featureflag.service';
import { FileUploadService } from './services/file-upload.service';
import { PasswordService } from './services/password.service';
import { RegistrationService } from './services/registration.service';
import { SubmissionService } from './services/submission.service';
import { InternationalReviewService } from './services/international-review.service';
import { NewRequestContainerComponent } from './components/new-request-container/new-request-container.component';
import { NewRequestDomesticComponent } from './components/new-request-domestic/new-request-domestic.component';
import { NewRequestInternationalComponent } from './components/new-request-international/new-request-international.component';
import { NewUserAccountComponent } from './components/newuseraccount/newuseraccount.component';
import { NewUserVerifyComponent } from './components/newuserverify/newuserverify.component';
import { AuthorizationGuard } from './services/authorization.guard';
import { AuthorizationService } from './services/authorization.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http/http-error-interceptor';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GlobalErrorHandler } from '../global-error-handler';
import { 
  IPublicClientApplication, 
  PublicClientApplication, 
  InteractionType, 
  BrowserCacheLocation, 
  LogLevel } from '@azure/msal-browser';
import { 
  MsalGuard, 
  MsalInterceptor, 
  MsalBroadcastService, 
  MsalInterceptorConfiguration, 
  MsalModule, 
  MsalService, 
  MSAL_GUARD_CONFIG, 
  MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, 
  MsalGuardConfiguration, 
  MsalRedirectComponent } from '@azure/msal-angular';
import { AppConfigService } from './services/app-config.service';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientId,
      authority: "https://login.microsoftonline.com/" + environment.tenant,
      redirectUri: environment.redirectUrl,
      navigateToLoginRequestUrl: true,
      postLogoutRedirectUri: '/home'
    },    
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.base, environment.scopes)
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect
  };
}


/**
 * Setup the Application Insights for the portal application.
 * @returns {ApplicationInsights} configuration.
 */
export function appInsightsFactory(): ApplicationInsights {
  return new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightsConfig.key
    }
  });
}

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardNdoComponent,
    NdoMySubmissionsComponent,
    DateAgoPipe,
    DeliveryContentComponent,
    DeliveryLocationComponent,
    EmailMonitoringComponent,
    EmergencyDialogComponent,
    FaqComponent,
    FilesToUploadComponent,
    FileUploadComponent,
    FileUploadButtonComponent,
    HomePageComponent,
    InternationalContentReviewComponent,
    InternationalDashboardComponent,
    LegalNotificationComponent,
    LoginTroubleDialogComponent,
    MyInternationalSubmissionsComponent,
    MySubmissionsComponent,
    NewRequestContainerComponent,
    NatureOfCrimeComponent,
    NdoComponent,
    NdoRedirectComponent,
    NewRequestDomesticComponent,
    NewRequestInternationalComponent,
    NewUserAccountComponent,
    NewUserVerifyComponent,
    PortalAppComponent,
    RequestAgentInfoComponent,
    SubmissionNotificationComponent,
    TypeOfRequestComponent,
    TypeOfServiceComponent,
    UnauthorizedComponent,
    UnderConstructionComponent,
    UnderMaintenanceComponent,
    ViewSubmissionsComponent
  ],
  imports: [
    BrowserModule,
    ClipboardModule,
    CoreModule,
    MsalModule,
    NgxLoadingModule,
    PortalAppRoutingModule,
    PortalAppPrimeNgModule,
    SharedModule,
  ],
  exports: [CoreModule],
  providers: [
    AuthorizationGuard,
    AppConfigService,
    AuthorizationService,
    ConfirmationService,
    CountryListService,
    DeliveryContentService,
    DeliveryLocationService,
    ExchangeDataAccessService,
    FaqService,
    FeatureflagService,
    FileUploadService,
    InternationalReviewService,
    PasswordService,
    RegistrationService,
    SubmissionService,
    Title,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },    
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [PortalAppComponent, MsalRedirectComponent]
})
export class PortalAppModule {
  constructor() { }
}
