export class DsaContentTypes{
    static AnimalWelfare = 'Animal Welfare';
    static DataProtectionAndPrivacyViolations = 'Data Protection and Privacy Violations';
    static IllegalOrHarmfulSpeech = 'Illegal or Harmful Speech';
    static IntellectualPropertyInfringements = 'Intellectual Property Infringements';
    static NegativeEffectsOnCivicDiscourseOrElections = 'Negative Effects on Civic Discourse or Elections';
    static NonConsensualBehaviour = 'Non-Consensual Behaviour';
    static PornographyOrSexualizedContent = 'Pornography or Sexualized Content';
    static ProtectionOfMinors = 'Protection of Minors';
    static RiskForPublicSecurity = 'Risk for Public Security';
    static ScamsAndOrFraud = 'Scams and/or Fraud';
    static SelfHarm = 'Self-Harm';
    static UnsafeAndOrIllegalProducts = 'Unsafe and/or Illegal Products';
    static Violence = 'Violence';
    static CrimeNotListed = 'Crime Not Listed';
  
    // Returns an array of property names
    static getPropertyNames(): string[] {
      const fields: string[] = [];
      const rawFields = Object.getOwnPropertyNames(DsaContentTypes);
      rawFields.forEach((field) => {
        if (
          typeof DsaContentTypes[field] === 'string' &&
          DsaContentTypes[field] !== '' &&
          field !== 'name'
        ) {
          fields.push(field);
        }
      });
      return fields;
    }
  
    // Returns an array of property values
    static getPropertyValues(): string[] {
      const values: string[] = DsaContentTypes.getPropertyNames().map((field) => DsaContentTypes[field]);
      return values;
    }
  
    // Gets the modified form control name by removing spaces
    static modifyFormControlName(item: string): string {
      return item.replace(/\s/g, '').toLowerCase();
    }
  
  }
  