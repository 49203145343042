import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MessageService, SelectItem } from "primeng/api";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { takeUntil, finalize, tap, catchError } from "rxjs/operators";
import { SpinnerService } from "../../../../core/services/spinner.service";
import { IntlReviewStatus } from "../../../enums/international-review-status.enum";
import { InternationalReviewAttachmentModel } from "../../../models/international-review-attachment.model";
import { InternationalSubmissionHistory } from "../../../models/international-submission-history.model";
import { InternationalReviewService } from "../../../services/international-review.service";
import { InternationalSubmissionService } from "../../../services/international-submission.service";
import * as FileValidator from "../../validators/file-upload.validator";
import { NatureOfCrimes } from "src/app/portal/models/nature-of-crimes-model";


@Component({
  selector: "international-content-review",
  templateUrl: "./international-content-review.component.html",
  styleUrls: ["./international-content-review.component.scss"]
})
export class InternationalContentReviewComponent implements OnInit, OnDestroy {
  public loading = true;
  public submissionId: string;
  public formNeedsNotes: boolean;
  public showRejectDialog: boolean;
  private _completed: boolean = true;

  private natureOfCrimesSubject: BehaviorSubject<NatureOfCrimes | null> = new BehaviorSubject<NatureOfCrimes | null>(null);
  public natureOfCrimes$: Observable<NatureOfCrimes | null> = this.natureOfCrimesSubject.asObservable();

  public submissionData: InternationalSubmissionHistory;
  public submissionAttachments: InternationalReviewAttachmentModel[];

  public showAddMoreFilesButton: boolean = true;
  public showAddMoreFilesForm: boolean = false;
  public uploadButton: boolean = false;
  public approveSubmissionForm: FormGroup;
  public filesFormArray: FormArray;

  public manualDeliveryStatusEnabled = false;
  public intlReviewStatusTypes: SelectItem[];
  public intlReviewStatus: typeof IntlReviewStatus = IntlReviewStatus;
  private ngUnsub: Subject<void> = new Subject<void>();

  get completed(): boolean {
    return this._completed;
  }

  constructor(
    private internationalReviewService: InternationalReviewService,
    private route: ActivatedRoute,
    private internationalSubmissionService: InternationalSubmissionService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit() {
    this.submissionId = this.route.snapshot.paramMap.get('id');
    this.intlReviewStatusTypes = Object.keys(this.intlReviewStatus)
      .filter(f => isNaN(Number(f)))
      .map(key => ({ value: this.intlReviewStatus[key], label: key }));
    this.loadPageData();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  loadPageData() {
    try {
      this.loading = true;
      this.resetForm();
      this.getSubmissionItem(this.submissionId);
      this.getAttachments(this.submissionId);
    } catch (err) {
      this.loading = false;
      this.sendUserMessagePopup("error", "Error", err);
    }
  }

  getSubmissionItem(submissionId) {
    try {
      this.loading = true;
      this.spinnerService.startSpinner();
      this.internationalSubmissionService
        .getInternationalSubmission(submissionId)
        .pipe(
          takeUntil(this.ngUnsub),
          tap((value: InternationalSubmissionHistory) => {
            this.submissionData = value;
          if (this.submissionData.NatureOfCrimes != null) {
              const transformedNatureOfCrimes = this.transformNatureOfCrimes(value.NatureOfCrimes);
              this.natureOfCrimesSubject.next(transformedNatureOfCrimes);
         } else {
            this.natureOfCrimesSubject.next(this.submissionData.NatureOfCrimes);
         }
          this._completed = (this.submissionData.IntlReviewStatus === IntlReviewStatus.Approved || this.submissionData.IntlReviewStatus === IntlReviewStatus.Rejected);
          if (value.IntlReviewFlagForEscalation) {
            this.flagForEscalation.setValue(value.IntlReviewFlagForEscalation);
          }
          if (value.IntlReviewNoticePermitted) {
            this.noticePermitted.setValue(value.IntlReviewNoticePermitted);
          }
            if (this.completed) {
              this.flagForEscalation.disable();
              this.noticePermitted.disable();
            }
            this.loading = false;
            this.spinnerService.stopSpinner();
          }),
          catchError((err: any) => throwError(err)),
          finalize(() => {})
        )
        .subscribe();
    } catch (err) {
      this.loading = false;
      this.spinnerService.stopSpinner();
      this.sendUserMessagePopup("error", "Error", err);
    }
  }

  toggleRejectDialog(): void {
    if (
      !this.reviewNote.value ||
      0 === this.reviewNote.value.length
    ) {
      this.formNeedsNotes = true;
      this.reviewNote.valueChanges
        .pipe(takeUntil(this.ngUnsub))
        .subscribe((newValue: string) => {
          if (!newValue || newValue.length === 0) {
            this.formNeedsNotes = true;
          } else {
            this.formNeedsNotes = false;
          }
        });
    } else {
      this.showRejectDialog = !this.showRejectDialog;
    }
  }

  submitApprove(): void {
    const formModel: FormData = this.prepareForm();
    this.loading = true;
    this.spinnerService.startSpinner();

    this.internationalReviewService
      .approve(this.submissionId, formModel)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(() => {
          this.sendUserMessagePopup(
            "success",
            "Approval Submitted",
            "Request approval submitted successfully."
          );
          this._completed = true;
          this.loadPageData();
        }),
        catchError(error => {
          this.sendUserMessagePopup(
            "error",
            "Approval Submission Error",
            "Approval failed. Please try again."
          );
          return throwError(error);

        }),
        finalize(() => {
          this.spinnerService.stopSpinner();
          this.loading = false;
        }))
      .subscribe();
  }

  submitReject(): void {
    this.loading = true;
    this.spinnerService.startSpinner();
    const formModel: FormData = this.prepareForm();
    this.internationalReviewService
      .reject(this.submissionId, formModel)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(() => {
          this.sendUserMessagePopup(
            "success",
            "Rejection Submitted",
            "Request rejection submitted successfully."
          );
          this._completed = true;
          this.toggleRejectDialog();
          this.loadPageData();
        }),
        catchError(error => {
          this.sendUserMessagePopup(
            "error",
            "Rejection Submission Error",
            "Rejection failed. Please try again."
          );
          this.toggleRejectDialog();
          return throwError(error);
        }),
        finalize(() => {
          this.spinnerService.stopSpinner();
          this.loading = false;
        }))
      .subscribe();
  }

  resetForm() {
    this.approveSubmissionForm = this.setForm();
  }

  // A hack to circumvent an edge bug that prevents the user from uploading the same file twice in a row
  // if they clear the form or delete the file and try to re-add the same file
  // By toggling the existence of the sub-component, we destroy/recreate it.   - ARP 06/2019
  edgeHack() {
    // toggle
    this.uploadButton = !this.uploadButton;
  }

  setForm(): FormGroup {
    this.filesFormArray = new FormArray(
      [],
      [
        FileValidator.FileExtension(["PDF", "DOC", "DOCX", "XLS", "XLSX"]),
        FileValidator.FileSize(26214400),
        FileValidator.FileCountRange(1, null)
      ]
    );

    const formGroup = this.fb.group({
      reviewNote: new FormControl(null),
      files: this.filesFormArray,
      flagForEscalation: new FormControl({ value: false, disabled: false }),
      noticePermitted: new FormControl({ value: false, disabled: false })
    });
    this.edgeHack();

    return formGroup;
  }

  prepareForm(): FormData {
    const formToSend = new FormData();

    formToSend.append("Note", this.reviewNote.value);
    formToSend.append("FlagForEscalation", this.flagForEscalation.value);
    formToSend.append("NoticePermitted", this.noticePermitted.value);

    for (const file of this.files) {
      const fileToUpload = file.value;
      formToSend.append(fileToUpload.name, fileToUpload);
    }

    return formToSend;
  }

  handleFileChange(fileList) {
    const newFiles = fileList;
    for (const newFile of newFiles) {
      const newFileControl = new FormControl(newFile, [
        FileValidator.FileExtension(["PDF", "DOC", "DOCX", "XLS", "XLSX"]),
        FileValidator.FileSize(26214400)
      ]);
      this.filesFormArray.push(newFileControl);
    }
  }

  removeFile(index: number) {
    this.filesFormArray.removeAt(index);
    this.edgeHack();

    if (this.filesFormArray.length === 0) {
      this.toggleAddMoreFiles();
    }
  }

  toggleAddMoreFiles() {
    this.showAddMoreFilesButton = !this.showAddMoreFilesButton;
    this.showAddMoreFilesForm = !this.showAddMoreFilesForm;
  }

  getAttachments(submissionId) {
    this.internationalReviewService
      .list(submissionId)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        value => {
          this.submissionAttachments = value;
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: "Delivery Content Error",
            detail:
              "Your attempt to retrieve a list of attachments has failed. Refresh the page to try again.",
            key: "toast-popup"
          });
          this.loading = false;
          throw error;
        },
        () => {
          this.loading = false;
        }
      );
  }

  // Used to download the files a user uploads during the submission process
  doGetFile(submissionHistoryLocator, fileName) {
    this.internationalReviewService
      .getFile(submissionHistoryLocator, fileName)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        value => {
          const file: string = value;
          window.open(file, "_blank");
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: "Content Download Error",
            detail:
              "Your attempt to download this file failed. Please try again.",
            key: "toast-popup"
          });
          throw error;
        }
      );
  }

  // Transform function
transformNatureOfCrimes(natureOfCrimes: NatureOfCrimes): { displayName: string; natureOfCrimesValue: any }[] {
  const trueNatureOfCrimesMapping: { displayName: string; natureOfCrimesValue: any }[] = [];
  const natureOfCrimesKeys: string[] = NatureOfCrimes.getPropertyNames();

  for (const key of natureOfCrimesKeys) {
    if (natureOfCrimes.hasOwnProperty(key) && natureOfCrimes[key]) {
      const displayName = NatureOfCrimes[key];
      const natureOfCrimesValue = natureOfCrimes[key];
      trueNatureOfCrimesMapping.push({ displayName, natureOfCrimesValue });
    }
  }

  return trueNatureOfCrimesMapping;
}

  // Utility method for creating pop-up messages for the user
  sendUserMessagePopup(type: string, title: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }

  get files() {
    return this.filesFormArray.controls;
  }

  get reviewNote() {
    return this.approveSubmissionForm.get("reviewNote");
  }

  get flagForEscalation() {
    return this.approveSubmissionForm.get("flagForEscalation");
  }

  get noticePermitted() {
    return this.approveSubmissionForm.get("noticePermitted");
  }

  get recordLocatorId() {
    return this.submissionData.RecordLocatorId;
  }
}
