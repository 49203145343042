import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FeedbackService } from "../../services/feedback.service";

export enum SubmitStatus {
  waitingToSubmit = 0,
  submitting = 1,
  submitted = 2
}

@Component({
  selector: "feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {
  display: boolean = false;
  form: FormGroup;
  submitted: boolean = false;
  ngUnsub: Subject<void> = new Subject<void>();
  status: SubmitStatus;

  constructor(
    private feedbackService: FeedbackService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.status = SubmitStatus.waitingToSubmit;
    this.setupForm();
  }

  setupForm() {
    this.form = new FormGroup({
      subject: new FormControl(null, Validators.required),
      comments: new FormControl(null, Validators.required)
    });
  }

  toggleFeedback() {
    this.display = !this.display;
  }

  submit() {
    const feedback = {
      FeedbackSubject: this.form.get("subject").value,
      FeedbackText: this.form.get("comments").value
    };
    this.feedbackService
      .submitFeedback(feedback)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(() => {
        this.resetForm();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Feedback received!"
        });
      }, 
    error => {
      this.resetForm();
        this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: "Failed to submit."
      });
    });
  }

  resetForm() {
    this.display = false;
    this.form.get("subject").setValue("");
    this.form.get("comments").setValue("");
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }
}
