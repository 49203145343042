import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class CountdownTimerService {
  private countdownCancelSource = new Subject<boolean>();
  private countdownCompleteSource = new Subject<boolean>();
  private countdownStartSource = new Subject<number>();
  private countdownStartedSource = new Subject<boolean>();

  countdownCancel$ = this.countdownCancelSource.asObservable();
  countdownComplete$ = this.countdownCompleteSource.asObservable();
  countdownStart$ = this.countdownStartSource.asObservable();
  countdownStarted$ = this.countdownStartedSource.asObservable();

  cancelCountdown() {
    this.countdownCancelSource.next(true);
    this.countdownCancelSource.complete();
  }

  completeCountdown() {
    this.countdownCompleteSource.next(true);
    this.countdownCompleteSource.complete();
  }

  startCountdown(expirationDate: number) {
    this.countdownStartSource.next(expirationDate);
  }

  startedCountdown() {
    this.countdownStartedSource.next(true);
  }
}
