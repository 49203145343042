<p-table #dt [value]="_selectedIncidentResponses" [globalFilterFields]="['TicketNumber','LEReferenceNumber']" [responsive]="true" [tableStyle]="{'overflow':'hidden'}" [loading]="( _loading$ | async )" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,20]" autoLayout="true" ptable508>
    <ng-template pTemplate="caption">
        <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Search Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th [pSortableColumn]="'TicketNumber'">MS Reference Number <p-sortIcon [field]="'TicketNumber'"></p-sortIcon></th>
            <th [pSortableColumn]="'LeReferenceNumber'">LE Reference Number <p-sortIcon [field]="'LeReferenceNumber'"></p-sortIcon></th>
            <th [pSortableColumn]="'Name'">Name<p-sortIcon [field]="'Name'"></p-sortIcon></th>
            <th [pSortableColumn]="'OriginalReceivedDate'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Submitted (Recent) <p-sortIcon [field]="'OriginalReceivedDate'"></p-sortIcon></th>
            <th [pSortableColumn]="'ExpirationDate'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Expiration (Recent)<p-sortIcon [field]="'ExpirationDate'"></p-sortIcon></th>
            <th [pSortableColumn]="'State'">Status<p-sortIcon [field]="'State'"></p-sortIcon></th>
            <th [pSortableColumn]="'Status'">Reason<p-sortIcon [field]="'Status'"></p-sortIcon></th>
            <th style="width: 10em;align-content:center;">Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>

        <tr style="border-style: none" >
            <td class="ui-column-title">{{row.TicketNumber}}</td>
            <td class="ui-column-title">{{row.LeReferenceNumber}}</td>
            <td class="ui-column-title">{{row.Name}}</td>
            <td class="ui-column-title">{{row.OriginalReceivedDate | date:'yyyy-MM-dd'}}</td>
            <td class="ui-column-title">{{row.ExpirationDate | date:'yyyy-MM-dd'}}</td>
            <td class="ui-column-title">{{row.State}}</td>
            <td class="ui-column-title">{{row.Status}}</td>
            <td *ngIf="row.NonDisclosureOrders?.length >0">
                <table>
                    <tr>
                        <td><i class="pi pi-search" (click)="showIncidentResponse($event,row,op)" style="font-size:24px" pTooltip="View NDO Extensions" tooltipPosition="right"></i></td>
                        <td><button pButton type="button" icon="pi pi-download" (click)="showIncidentResponseDocument(row.NonDisclosureOrders[0].TicketNumber)" pTooltip="Primary NDO Extension Doc {{row.NonDisclosureOrders[0].Name}}" tooltipPosition="right"></button></td>
                    </tr>
                </table>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="5">
                <i>You have no ndo extensions.</i>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-overlayPanel #op [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        NDO Extensions for: <span style="font-weight:bold">{{_selectedIncidentResponse.TicketNumber}}</span>
                            <p-table [value]="_selectedIncidentResponse.NonDisclosureOrders" [responsive]="true" [tableStyle]="{'overflow':'hidden','width':'50vw'}" autoLayout="true" ptable508 [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5,10,15,20]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [pSortableColumn]="'TemporaryNondisclosure'">Temporary<p-sortIcon [field]="'TemporaryNondisclosure'"></p-sortIcon></th>
                                        <th [pSortableColumn]="'Name'">Name<p-sortIcon [field]="'Name'"></p-sortIcon></th>
                                        <th [pSortableColumn]="'CreateDate'">Submitted Date<p-sortIcon [field]="'CreateDate'"></p-sortIcon></th>
                                        <th [pSortableColumn]="'ExpirationDate'">Expiration Date<p-sortIcon [field]="'ExpirationDate'"></p-sortIcon></th>
                                        <th>Document</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-row>
                                    <tr>
                                        <td class="ui-column-title">{{row.TemporaryNondisclosure}}</td>
                                        <td class="ui-column-title">{{row.Name}}</td>
                                        <td class="ui-column-title">{{row.CreateDate | date:'yyyy-MM-dd'}}</td>
                                        <td class="ui-column-title">{{row.ExpirationDate | date:'yyyy-MM-dd'}}</td>
                                        <td class="ui-column-title"><button pButton type="button" icon="pi pi-download" (click)="showIncidentResponseDocument(row.TicketNumber)" pTooltip="Primary NDO Extension Doc {{row.Name}}" tooltipPosition="right"></button></td>
                                    </tr>
                                </ng-template>
                            </p-table>
    </ng-template>
</p-overlayPanel>

