import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InternationalReviewModel } from '../models/international-review.model';
import { InternationalReviewAttachmentModel } from '../models/international-review-attachment.model';

@Injectable()
export class InternationalReviewService {

  constructor(private httpClient: HttpClient) { }

  approve(submissionId: string, formToPost: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true'
    });

    return this.httpClient.post(environment.internationalReviewUrl + `/approve/${submissionId}`,
      formToPost, { headers: headers, withCredentials: false, observe: 'response' });
  }

  reject(submissionId: string, formToPost: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
    });

    return this.httpClient.post(environment.internationalReviewUrl + `/reject/${submissionId}`,
    formToPost, { headers: headers, withCredentials: false, observe: 'response' });
  }

  list(submissionId: string) : Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true'
    });

    return this.httpClient.get<InternationalReviewAttachmentModel[]>(environment.internationalReviewUrl + `/List/${submissionId}`,
     { headers: headers, withCredentials: false });
  }

  modify(submissionId: string, modifyContent: InternationalReviewModel, recordLocatorId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json'
    });

    const body = JSON.stringify(modifyContent);

    return this.httpClient.post(environment.internationalReviewUrl + `/modify/${submissionId}`,
      body, { headers: headers, withCredentials: false, observe: 'response', params: {recordLocatorId: recordLocatorId} });
  }

  getFile(submissionHistoryGuid: string, fileName: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
    });
    const encodedFileName = encodeURIComponent(fileName);
    return this.httpClient.get<string>(environment.internationalReviewUrl + `/getfile/${submissionHistoryGuid}/${encodedFileName}/`, { headers: headers, withCredentials: false });
  }
}
