import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent {
  @Output() fileChanged = new EventEmitter();

  constructor() {}

  handleFileUpload(event) {
    this.fileChanged.emit(event.target.files);
  }
}
