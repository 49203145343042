// Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPrimeNgModule } from './shared-primeng.module';

// Pipes
import { DefaultPipe } from './pipes/default.pipe';
import { PCheckbox508Directive } from './directives/pcheckbox508.directive';
import { PTable508Directive } from './directives/ptable508.directive';
import { PListBox508Directive } from './directives/plistbox508.directive';

@NgModule({
  declarations: [
    DefaultPipe,
    PCheckbox508Directive,
    PTable508Directive,
    PListBox508Directive
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPrimeNgModule,
  ],
  exports: [
    CommonModule,
    DefaultPipe,
    PCheckbox508Directive,
    PTable508Directive,
    PListBox508Directive,
    FormsModule,
    ReactiveFormsModule,
    SharedPrimeNgModule
  ]
})

export class SharedModule { }
