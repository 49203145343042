<div class="p-grid p-dir-col " [formGroup]="form">  
  <div class="p-col">
    <p-message
      severity="info"
      text="Please do not submit more than one legal request per upload."
    ></p-message> 
  </div>  
  <div class="p-col">
    <label
      >Please upload legal documentation associated with this request.</label
    >
  </div>
  <div class="p-col">
    <ng-container [ngSwitch]="uploadButton">
      <file-upload-button
        *ngSwitchCase="true"
        (fileChanged)="handleFileChange($event)"
      ></file-upload-button>
      <file-upload-button
        *ngSwitchDefault
        (fileChanged)="handleFileChange($event)"
      ></file-upload-button>
    </ng-container>
  </div>
  <div class="p-col">
    <files-to-upload
      [files]="files"
      (remove)="removeFile($event)"
    ></files-to-upload>
    <div class="p-col">
      <p-message
        *ngIf="invalidMinCount"
        severity="error"
        text="At least one document is required."
      ></p-message>
      <p-message
        *ngIf="invalidSize"
        severity="error"
        text="The total size exceeds 25 MB."
      ></p-message>
    </div>
  </div>
  <hr />
  <div class="p-col ndo-confirm" *ngIf="hasNdo">
    <p-checkbox
      formControlName="isNdoUploadConfirm"
      binary="true"
      label="I have uploaded a Non-Disclosure Order that complies with 18 U.S.C. § 2705(b) and/or the legal process I have uploaded clearly identifies applicable federal/state law that may restrict notification to the user."
    ></p-checkbox>
    <hr />
  </div>
</div>
