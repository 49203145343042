import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from '../../../core/services/spinner.service';
import { Registration } from '../../models/registration.model';
import { RegistrationService } from '../../services/registration.service';
import { EmailMatchValidator } from '../validators/email.validator';

@Component({
  selector: 'newuseraccount',
  templateUrl: './newuseraccount.component.html',
  styleUrls: ['./newuseraccount.component.scss']
})
export class NewUserAccountComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  showDialog = false;
  statusText: string;
  submitEnabled = true;
  ngUnsub = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private registrationService: RegistrationService,
    private spinnerService: SpinnerService,
    private router: Router,
  ) {}

  get confirmEmail() {
    return this.registrationForm.get('confirmEmail');
  }

  get emailAddress() {
    return this.registrationForm.get('emailAddress');
  }

  ngOnInit() { 

    this.registrationForm = this.fb.group(
      {
        isLe: null,
        emailAddress: ['', [Validators.required, Validators.email]],
        confirmEmail: ['', Validators.required]
      },
      {
        validator: [EmailMatchValidator('emailAddress', 'confirmEmail')]
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
  getErrorMessage() {
    let errorMessage = '';
    if (
      this.emailAddress.hasError('required') ||
      this.emailAddress.hasError('email')
    ) {
      errorMessage = 'You must enter a valid email address.';
      return errorMessage;
    } else if (this.registrationForm.hasError('noMatch')) {
      errorMessage = 'Email addresses must match.';
    }

    return errorMessage;
  }

  IsPageInvalid() {
    return this.registrationForm.invalid || !this.submitEnabled;
  }

  SubmitRegistration() {
    this.spinnerService.startSpinner();
    const registration: Registration = new Registration();
    registration.MappedEmailAddress = this.emailAddress.value;
    this.registrationService
      .PostRegistrationModel(registration)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        res => {
          this.spinnerService.stopSpinner();
          this.router.navigate(['/newuserverify']);
        },
        error => {
          this.spinnerService.stopSpinner();
          this.messageService.add({
            severity: 'error',
            summary: 'Validation failed',
            detail:
              'Unable to submit your new user account at this time. Please wait, and try again.',
            key: 'toast-popup'
          });
        }
      );
  }
}
