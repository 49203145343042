import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DsaContentTypes } from '../../models/dsa-content-types';
import { atLeastOneChecked } from '../validators/at-least-one.validator';
import { dsaComplianceValidator } from "../validators/dsa-compliance.validator";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: 'dsa-items',
  templateUrl: './dsa-items.component.html',
  styleUrls: ['./dsa-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsaItemsComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  private destroy$ = new Subject<void>();

  natureOfDSASC: { displayName: string; formControlName: string; }[];

  get isDsaRequestControl() {
    return this.form.get('isDsaRequest');
  }

  get isDsaRequest() {
    return this.isDsaRequestControl.value;
  }

  get isLegalOrderCompliantControl() {
    return this.form.get('isLegalOrderCompliant')
  }

  get isLegalOrderCompliant() {
    return this.isLegalOrderCompliantControl.value
  }

  get isMicrosoftRequiredNotice() {
    return this.form.get('isMicrosoftRequiredNoticeAcknowledgement').value
  }

  get isValidNDOInPlace() {
    return this.form.get('isValidNDOInPlace').value
  }

  get displayWarning() {
    return this.isDsaRequest && !this.isLegalOrderCompliant && this.isLegalOrderCompliantControl.dirty
  }

  yesno: { label: string; value: boolean; icon: string }[] = [
    { label: "Yes", value: true, icon: "pi pi-check" },
    { label: "No", value: false, icon: "pi pi-times" }
  ];

  _minDate: Date = new Date();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addFormControls();
    this.subscribeToValidationState();
    this.subscribeToFormChanges();

    this.form.setValidators(dsaComplianceValidator());
    this.updateDsaDependentValidators();
  }

  private subscribeToFormChanges() {
    this.isDsaRequestControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(_ => this.updateDsaDependentValidators()))
      .subscribe();

    this.isLegalOrderCompliantControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(_ => this.updateDsaDependentValidators()))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToValidationState(): void {
    this.form.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(status => this.isValid.emit(status === 'VALID')))
      .subscribe();
  }

  addFormControls() {
    this.natureOfDSASC = DsaContentTypes.getPropertyValues()
      .map((item) => ({
        displayName: item,
        formControlName: DsaContentTypes.modifyFormControlName(item),
      }));
    this.form.addControl("isLegalOrderCompliant", new FormControl(null));
    this.form.addControl("isMicrosoftRequiredNoticeAcknowledgement", new FormControl(false));
    this.form.addControl("isValidNDOInPlace", new FormControl(null));
    this.form.addControl("isTemporaryNonDisclosure", new FormControl(null));
    this.form.addControl("nonDisclosureExpirationDate", new FormControl(null));
    this.setupDsaSelectionsFormGroup();
  }

  setupDsaSelectionsFormGroup() {
    const dsaSelectionsControls = this.natureOfDSASC.reduce((controls, item) => {
      controls[item.formControlName] = new FormControl(false);
      return controls;
    }, {});

    const dsaSelectionsFormGroup = this.fb.group(dsaSelectionsControls);

    this.form.addControl("dsaSelections", dsaSelectionsFormGroup);
  }

  // The human readable validation logic here is if it's a dsa request and 
  // legally compliant, they get additional selections with validators. 
  // If not, we can't have those validators rendering this form group invalid, so we remove them. 
  // Since they can also go back and change their minds, we also want to reset the values to prevent a non-DSA request 
  // from having dsa selections.
  updateDsaDependentValidators() {
    const isDsaRequest = this.isDsaRequest;
    const isLegalOrderCompliant = this.isLegalOrderCompliant;

    const isLegalOrderCompliantControl = this.form.get('isLegalOrderCompliant');
    const isMicrosoftRequiredNoticeAcknowledgementControl = this.form.get('isMicrosoftRequiredNoticeAcknowledgement');
    const dsaSelectionsControl = this.form.get('dsaSelections');
    const isValidNDOInPlaceControl = this.form.get('isValidNDOInPlace');
    const isTemporaryNonDisclosureControl = this.form.get('isTemporaryNonDisclosure');
    const nonDisclosureExpirationDateControl = this.form.get('nonDisclosureExpirationDate');

    if (isDsaRequest && isLegalOrderCompliant) {
      isMicrosoftRequiredNoticeAcknowledgementControl.setValidators(Validators.requiredTrue);
      dsaSelectionsControl.setValidators(atLeastOneChecked);
      isValidNDOInPlaceControl.setValidators(Validators.required);
      isTemporaryNonDisclosureControl.setValidators(Validators.required);
      nonDisclosureExpirationDateControl.setValidators(Validators.required);
    } else {
      isMicrosoftRequiredNoticeAcknowledgementControl.clearValidators();
      dsaSelectionsControl.clearValidators();
      isValidNDOInPlaceControl.clearValidators();
      isTemporaryNonDisclosureControl.clearValidators();
      nonDisclosureExpirationDateControl.clearValidators();

      isMicrosoftRequiredNoticeAcknowledgementControl.reset();
      dsaSelectionsControl.reset();
      isValidNDOInPlaceControl.reset();
      isTemporaryNonDisclosureControl.reset();
      nonDisclosureExpirationDateControl.reset();
    }

    // Update the validity of the controls
    isMicrosoftRequiredNoticeAcknowledgementControl.updateValueAndValidity();
    dsaSelectionsControl.updateValueAndValidity();
    isValidNDOInPlaceControl.updateValueAndValidity();
    isTemporaryNonDisclosureControl.updateValueAndValidity();
    nonDisclosureExpirationDateControl.updateValueAndValidity();

    // Manually trigger a status update on the parent form. 
    this.form.updateValueAndValidity();
  }

  toggleCheckbox(event: MouseEvent, control: AbstractControl) {
    event.preventDefault();
    if (control instanceof FormControl) {
      control.setValue(!control.value);
    }
  }

}
