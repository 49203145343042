import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService, 
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authorizationService.GetLePortalUser().pipe(
        map((user: User) => {
          return user.IsOutsideCounsel
              ? this.handleIsOutsideCounsel()
              : true
            
        })
      )
  }
  
  handleIsOutsideCounsel(): boolean {
    this.router.navigate(['/international-dashboard']);
    return false;
  }
}
