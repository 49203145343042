import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EmailMatchValidator } from '../validators/email.validator';

@Component({
  selector: "request-agent-info",
  templateUrl: "./request-agent-info.component.html",
  styleUrls: ["./request-agent-info.component.scss"]
})
export class RequestAgentInfoComponent implements OnInit {
  @Input() form: FormGroup;
  constructor() {}

  ngOnInit() {
    this.addRequestingAgentInformationControlGroup();
  }

  addRequestingAgentInformationControlGroup() {
    this.form.addControl('agencyName', new FormControl(null, Validators.required));
    this.form.addControl('agentFirstName', new FormControl(null, Validators.required));
    this.form.addControl('agentLastName', new FormControl(null, Validators.required));
    this.form.addControl('agentEmail', new FormControl(null, [Validators.required, Validators.email]));
    this.form.addControl('agentEmailConfirm', new FormControl(null, Validators.required));
    this.form.addControl('agentPhoneNumber', new FormControl(null, Validators.required));
    this.form.setValidators([EmailMatchValidator('agentEmail','agentEmailConfirm')]) ;    
    this.form.updateValueAndValidity();
  }
}
