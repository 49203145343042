import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private httpClient: HttpClient) {}

  headers = new HttpHeaders({
    'Access-Control-Allow-Credentials': 'true',
  });

  GetQuickReferenceDocument() {
    return this.httpClient.get(environment.base + '/faq/referenceDoc', {headers: this.headers, responseType: 'blob' });
  }
}
