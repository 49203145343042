<h5>Supported browsers</h5>
<p-table id="browser-table" [value]="supportedBrowsers">
  <ng-template pTemplate="header">
    <tr>
      <th>Browser</th>
      <th>Supported Versions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-browser>
    <tr>
      <td>{{browser.name}}</td>
      <td>{{browser.version}}</td>
    </tr>
  </ng-template>
</p-table>
<br>
<h5>Browser settings</h5>
<p>
  If you do have one of the above supported browsers and are still experiencing issues, you may need to adjust
  your browser settings or have your IT department adjust them for you.
</p>
<p>
  The required settings to access the portal are:
</p>
  <ul>
    <li>Allow cookies from *.live.com</li>
    <li>Add the following URLs to an allowlist that permits network traffic:
      <ul>
        <li>https://leportal.microsoft.com/home</li>
        <li>https://login.live.com</li>
        <li>https://login.microsoftonline.com</li>
      </ul>
    </li>
  </ul>
<br>
<h5>Still having problems?</h5>
<div>
  Contact your help desk to have supported browsers installed and/or configured as needed
</div>
