import { Injectable } from '@angular/core';
import { ExchangeRequest } from '../models/exchange-request.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  ResetPassword(request: ExchangeRequest): Observable<string> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json'
    });

    const body = JSON.stringify(request);

    return this.httpClient
      .post<string>(environment.exchangeRequestsUrl + `/resetPassword`, body, {
        headers: headers,
        responseType: 'text' as 'json',
        withCredentials: false
      })
      .pipe();
  }  
}
