import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  unauthorizedReason$: Observable<boolean>;
  unauthorizedReasonText: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.unauthorizedReason$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.unauthorizedReasonText = params.get('unauthorizedReason');
        if (this.unauthorizedReasonText) {
          return of(true);
        }
        return of(false);
      })
    );
  }
}
