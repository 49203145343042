<!-- Basic Form -->
<div [formGroup]="international">
  <h3 class="first">New Request - {{ country.Name }}</h3>
  <p-steps
    class="stepper"
    [model]="items"
    [activeIndex]="activeIndex.state$ | async"
  ></p-steps>

  <div *ngFor="let step of steps; let i = index">
    <ng-container *ngIf="(activeIndex.state$ | async) === i">
      <!-- Special handling for the first step -->
      <ng-container *ngIf="i === 0">
        <div class="step-container" *ngIf="i === 0">
  
            <div class="p-col" *ngIf="isUK">
                <label>Is this a Grade 2?</label>
                <div class="input-switch-container">
                    <p-selectButton [options]="yesno"
                                    formControlName="isGradeTwo"></p-selectButton>
                </div>
                <hr />
            </div>
            <div class="p-col">
                <label>
                    Are you submitting a law enforcement request related to Child
                    Exploitation?
                </label>
                <div class="input-switch-container">
                    <p-selectButton [options]="yesno"
                                    formControlName="cpConcern"></p-selectButton>
                </div>
                <hr />
            </div>
            <div class="p-col">
                <label>Is this a preservation request?</label>
                <div class="input-switch-container">
                    <p-selectButton [options]="yesno"
                                    formControlName="isPreservationRequest"></p-selectButton>
                </div>
                <hr />
            </div>
            <div class="p-col" *ngIf="isPreservationRequestControl.value && isPreservationRequestControl.dirty">
                <label>I attest that I have requested preservation of this account data because I am in the process of obtaining the required legal process to obtain this data.</label>
                <div>
                    <p-selectButton [options]="yesno"
                                    formControlName="isPreservationAttestion"></p-selectButton>
                </div>
                <hr />
            </div>
            <!-- LE Reference Number -->
            <div class="p-col-12">
                <label for="leReferenceNumber">LE Reference Number</label>
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon">
                        <i class="pi pi-table" style="line-height: 1.25;"></i>
                    </span>
                    <input id="leReferenceNumber"
                           style="border-width: thin;"
                           type="text"
                           formControlName="leReferenceNumber"
                           pInputText
                           placeholder="Reference Number"
                           aria-label="Reference Number"
                           title="Reference Number"
                      />
                </div>
                <hr />
            </div>
      
      
            <div class="p-col" *ngIf="!isPreservationRequestControl.value && isPreservationRequestControl.dirty">
                <label>Do you want notifications from the Law Enforcement portal sent to another agent?</label>
                <div>
                    <p-selectButton [options]="yesno"
                                    formControlName="hasDifferentRecipient"></p-selectButton>
                </div>
                <hr />
      
                <div *ngIf="isDifferentRecipientControl.value" class="p-col">
                    <span class="ui-float-label">
                        <input id="email"
                               type="email"
                               size="50"
                               formControlName="deliveryEmail"
                               autocomplete="off"
                               pInputText />
                        <label for="email">Recipient's Government Email Address</label>
                    </span>
                    <hr />
                </div>
            </div>
        </div>
      </ng-container>
  
      <!-- Special handling for the last step -->
      <ng-container *ngIf="i === steps.length - 1">
        <div class="step-container" *ngIf="i === steps.length - 1">
          <div class="p-grid p-dir col">
            <div class="p-col-12">
              <h3>Request Submission Review</h3>
            </div>
            <div class="p-col-12">
              <p-message
                severity="warn"
                text="Your submission is not complete until you click Submit."
              ></p-message>
              <hr />
            </div>
            <div class="p-col-6">
              <dl style="margin-bottom: 0px;">
                <dt>Is target notification permitted?</dt>
                <dd>{{ notificationPermitted }}</dd>
      
                <dt>Agency Name</dt>
                <dd>{{ agencyName }}</dd>
      
                <dt>Agent Name</dt>
                <dd>{{ agentFirstName }} {{ agentLastName }}</dd>
      
                <dt>Agent Phone Number</dt>
                <dd>{{ agentPhoneNumber }}</dd>
      
                <dt>Country of Origin</dt>
                <dd>{{ country.Name }}</dd>
      
                <dt>Uploaded Files</dt>
                <dd style="margin-bottom: 0px;" *ngFor="let fileName of fileNames">
                  {{ fileName }}&nbsp;&nbsp;&nbsp;&nbsp;
                  <button class="btn btn-link" (click)="doGetFile(fileName)">
                    <span class="pi pi-arrow-down"></span>&nbsp;Click to Download
                  </button>
                </dd>
                
                <ng-container *ngIf="isDsaEnabled && country.IsDSACountry">
                  <dt>Is DSA Request</dt>
                  <dd>{{ isDsaRequest ? "Yes" : "No" }}</dd>
                </ng-container>
              </dl>
            </div>
            <div class="p-col-6">
                <dl style="margin-bottom: 0px;">
                    <dt>Request related to Child Exploitation?</dt>
                    <dd>{{ childExploitation  }}</dd>
      
                    <dt>Preservation Request?</dt>
                    <dd>{{ isPreservation }}</dd>
      
                    <dt>Alternate Recipient</dt>
                    <dd>{{ differentRecipientReview }}</dd>
      
                    <dt>Agent Email Address</dt>
                    <dd>{{ agentEmail }}</dd>
      
                    <dt>LE Reference Number</dt>
                    <dd>{{ leReferenceNumber }}</dd>
      
                    <dt>Selected Service(s)</dt>
                    <dd style="margin-bottom: 0px;" *ngFor="let service of services">
                        {{ service }}&nbsp;&nbsp;
                    </dd>
                    
                    <ng-container *ngIf="isDsaEnabled && isDsaRequest">
                      <dt>DSA Selections</dt>
                      <dd style="margin-bottom: 0px;" *ngFor="let dsaSelection of dsaDisplayNames">
                        {{ dsaSelection }}&nbsp;&nbsp;
                      </dd>
                    </ng-container>
                </dl>
            </div>
          </div>
          <hr />
          <div class="p-col">
            <p-message
              severity="warn"
              text="Please note that Microsoft may give notice to users and customers whose data is sought by a law enforcement agency or other government entity, except where prohibited by law."
            ></p-message>
          </div>
          <hr />
          <ng-container *ngIf="isDsaEnabled && isDsaRequest">
            <div class="p-col">
              <acknowledgement 
                [form]="international" 
                [declarationText]="dsaAcknowledgementText"
                [formControlId]="1">
              </acknowledgement>
            </div>
          </ng-container>
        </div>
      </ng-container>
  
      <!-- Dynamically loaded components for other steps -->
      <ng-container *ngIf="i !== 0 && i !== steps.length - 1">
        <div [ngSwitch]="step.component?.name" class="step-container">
          
          <!-- Digital Services Act (DSA) -->
          <div *ngIf="isDsaEnabled">
            <ng-container *ngSwitchCase="'DsaItemsComponent'">
              <dsa-items [form]="dsaItemsForm" (isValid)="dsaFormIsValid = $event"></dsa-items>
            </ng-container>
          </div>

          <!-- Requesting Nature of crimes details -->
          <ng-container *ngSwitchCase="'NatureOfCrimeComponent'">
            <nature-of-crime [form]="natureOfCrimeForm"></nature-of-crime>
          </ng-container>

          <!-- Requesting Agent Information -->
          <ng-container *ngSwitchCase="'RequestAgentInfoComponent'">
            <request-agent-info [form]="requestingAgentInformationForm"></request-agent-info>
          </ng-container>

          <!-- Type of Service -->
          <ng-container *ngSwitchCase="'TypeOfServiceComponent'">
            <type-of-service [form]="typeOfServiceForm"></type-of-service>
          </ng-container>

          <!-- Legal Notification -->
          <ng-container *ngSwitchCase="'LegalNotificationComponent'">
            <legal-notification [form]="notificationForm"></legal-notification>
          </ng-container>

          <!-- File Upload and International Notes -->
          <ng-container *ngSwitchCase="'FileUploadComponent'">
            <div class="p-grid p-dir-col">
              <div class="p-col">
                <p-message
                  severity="warn"
                  text="Your legal request must specify which Microsoft service and data types you require."
                ></p-message>
              </div>
            </div>
            <file-upload [form]="fileUploadForm"> </file-upload>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Footer Buttons -->
  <div>
    <p-button
      type="button"
      label="Previous"
      (onClick)="previous()"
      *ngIf="
        (activeIndex.state$ | async) > 0 &&
        (activeIndex.state$ | async) != reviewAndSubmitStep
      "
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      label="Cancel"
      styleClass="ui-button-secondary"
      (onClick)="toggleSimpleCancelDialog()"
      *ngIf="(activeIndex.state$ | async) != reviewAndSubmitStep"
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      label="Next"
      (onClick)="next()"
      *ngIf="(activeIndex.state$ | async) != reviewAndSubmitStep"
    ></p-button>
    <p-button
      type="button"
      label="Cancel Request"
      data-testid="cancelBtn"
      styleClass="ui-button-secondary"
      (onClick)="toggleCancelWithFileDeleteDialog()"
      *ngIf="(activeIndex.state$ | async) == reviewAndSubmitStep"
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      [disabled]="isSubmitDisabled"
      label="Submit"
      data-testid="submitBtn"
      (onClick)="submit()"
      *ngIf="(activeIndex.state$ | async) == reviewAndSubmitStep"
    ></p-button>
  </div>
</div>

<!-- Dialog Warnings -->
<!-- DSA Question -->
<p-dialog header="EU Digital Services Act (DSA) Compliance" [(visible)]="isDsaEnabled && displayDsa" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <p>This portal processes orders in the usual manner, as well as orders impacted by the EU Digital Services Act (DSA).</p>
  <p>Is the order you are submitting today an Article 10 Digital Services Act-impacted order?</p>

  <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="onDsaResponse(true)" label="Yes"></button>
      <button type="button" pButton icon="pi pi-times" (click)="onDsaResponse(false)" label="No" class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>

<!-- Cancel Warning with File Delete-->
<p-dialog
  header="Cancel Submission Request"
  [(visible)]="showCancelWithFileDeleteDialog"
  [style]="{ width: '450px' }"
>
  You are about to cancel and clear the form. All of your selections and
  uploaded files will be removed. If you would like to proceed, please press
  Continue. Otherwise, click Cancel.
  <p-footer>
    <div class="p-grid">
      <button
        type="button"
        pButton
        class="ui-button-secondary p-col"
        (click)="toggleCancelWithFileDeleteDialog()"
        label="Cancel"
      ></button>
      <button
        type="button"
        pButton
        class="p-col"
        label="Continue"
        (click)="deleteUploadedFilesAndCancel()"
      ></button>
    </div>
  </p-footer>
</p-dialog>

<!-- Cancel Warning without File Delete -->
<p-dialog
  header="Cancel Submission"
  [(visible)]="showCancelSimpleDialog"
  [style]="{ width: '450px' }"
>
  You are about to cancel and clear the form. All of your selections will be
  removed. If you would like to cancel your submission, click Yes. To continue
  working on your submission, click No.
  <p-footer>
    <div class="p-grid">
      <button
        type="button"
        pButton
        class="ui-button-secondary p-col"
        (click)="toggleSimpleCancelDialog()"
        label="No"
      ></button>
      <button
        type="button"
        pButton
        class="p-col"
        label="Yes"
        [routerLink]="['/dashboard']"
      ></button>
    </div>
  </p-footer>
</p-dialog>

<!-- International Warning -->
<p-dialog
  [(visible)]="showInternationalWarning"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-header>
    {{ country.InternationalWarning.Header }}
  </p-header>

  <p>{{ country.InternationalWarning.WarningText }}</p>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="closeInternational()"
      label="Ok"
    ></button>
  </p-footer>
</p-dialog>
