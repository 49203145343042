import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[ptable508]'
})
export class PTable508Directive implements AfterViewChecked {
    constructor(private elRef: ElementRef) { }

    ngAfterViewChecked(): void {
        this.checkForDropDown();
        this.checkForListbox();
    }

    checkForDropDown() {
        const dropdowns = document.querySelectorAll('div.ui-dropdown-trigger');
        dropdowns.forEach((icon, _index) => {
            this.updateTitleAndLabel(icon, 'Number of records to display per page')
        });
    }

    checkForListbox() {
        const listboxes = document.querySelectorAll('p-dropdown input[type="text"][readonly][role="listbox"]');
        listboxes.forEach((icon, _index) => {
            icon.removeAttribute('role');
        });
    }

    updateTitleAndLabel(el: Element, msg: string) {
        if (!el.hasAttribute('title')) {
            el.setAttribute('title', msg);
        }
        if (!el.hasAttribute('aria-label')) {
            el.setAttribute('aria-label', msg);
        }
    }
}