<header>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <p-messages [(value)]="msgs"></p-messages>
    <h2 class="clearfix">
        My Requests
        <span class="pull-right">
            <p-button icon="pi pi-list" styleClass="ui-button-warning ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" pTooltip="Click here to go to the NDO Extensions Dashboard" placeholder="Bottom" tooltipPosition="bottom" label="NDO Extensions" type="button" (click)="showNDODashboard()"></p-button>&nbsp;&nbsp;
            <p-button icon="pi pi-plus" label="New Request" (click)="toggleNotForEmergenciesDialog()" queryParamsHandling="preserve"></p-button>
        </span>
    </h2>
    <div>
        <b>Attention!</b><br>
        By accessing any data through this portal, you declare and attest that your request does not relate to an investigation into criminal or civil liability related to the provision or receipt of gender-affirming treatment or reproductive health care services, in accordance with the laws of the state of Washington.  Please contact us at <a href="mailto:lenotice@microsoft.com">lenotice@microsoft.com</a> with any questions or concerns. 
    </div>
</header>

<my-submissions></my-submissions>


<p-dialog header="Emergency Request Notice" [visible]="showNotForEmergenciesDialog$ | async" [style]="{'width': '450px'}">
    <emergency-dialog></emergency-dialog>
    <p-footer>
        <div class="p-grid">
            <button type="button" pButton class="ui-button-secondary p-col" (click)="toggleNotForEmergenciesDialog()" label="Cancel"></button>
            <button type="button" pButton class="p-col" label="Continue" (click)="continue()"></button>
        </div>
    </p-footer>
</p-dialog>
