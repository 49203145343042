import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { isValidTicketNumberValidator } from "src/app/validators/isValidTicketNumberValidator";

// ReadMe: Business Rules to know
// If it's a cpconcern or preservation, there's no need for an NDO
// See unit tests for examples

@Component({
  selector: "ndo",
  templateUrl: "./ndo.component.html",
  styleUrls: ["./ndo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NdoComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() needsNdo: boolean;
  @Output() hasNdo: EventEmitter<boolean> = new EventEmitter();
  ngUnsub: Subject<void> = new Subject();
  yesno: { label: string; value: boolean; icon: string }[];

  constructor() {}

  ngOnInit() {
    this.yesno = [
      { label: "Yes", value: true, icon: "pi pi-check" },
      { label: "No", value: false, icon: "pi pi-times" }
    ];

    if (this.needsNdo) {
      // Account for navigating BACK to this component and the control already exists.
      if(!this.form.get('hasNdo')){
        this.form.addControl("hasNdo", new FormControl(null, Validators.required));
        this.form.addControl("isNdoConfirm", new FormControl(null, Validators.requiredTrue));                
      }
      this.form
        .get("hasNdo")
        .valueChanges.pipe(
          takeUntil(this.ngUnsub),
          tap(val => {
            if (val === true) {
              this.hasNdo.emit(true);
              this.form.removeControl("isNdoConfirm");
            } else {
              this.hasNdo.emit(false);
              this.form.addControl("isNdoConfirm", new FormControl(null, Validators.required));
            }
            this.form.updateValueAndValidity();
          })
        )
        .subscribe();
    }else{
      this.form.removeControl('hasNdo');
      this.form.removeControl('isNdoConfirm');
    }
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  get hasNdoControl() {
    return this.form.get("hasNdo");
  }

}
