import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class AppConfigService {
  constructor(private httpClient: HttpClient) { }
  headers = new HttpHeaders({
    "Access-Control-Allow-Credentials": "true",
  });

  GetSettings():Observable<string> {
      return this.httpClient.get<string>(environment.base + "/appConfig/maintenance", { headers: this.headers,  withCredentials: false });
  }
}