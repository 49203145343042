<div class="row justify-content-start">
  <div class="col">
    Files attached to this request:
  </div>
</div>
<div class="row justify-content-start">
  <div class="col">
    <ul class="list-group list-group-flush">
      <li *ngFor="let fileControl of files.controls; index as i;" class="list-group-item">        
        <i [ngClass]="!fileControl.errors ? 'pi pi-check text-success' : 'pi pi-times text-danger'"></i>
        {{fileControl.value.name}}
        <span *ngIf="fileControl.errors" class="text-danger">
          <i *ngIf="fileControl.errors.invalidExt">Invalid file type</i>
          <i *ngIf="fileControl.errors.invalidSize">File is too large</i>
        </span>
        <button aria-label="removeFile" class="btn btn-outline-warning pull-right" (click)="removeFile(i)"><i class="pi pi-trash"></i></button>
      </li>
    </ul>
  </div>
</div>
