import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[pcheckbox508]'
})
export class PCheckbox508Directive implements AfterViewInit, AfterViewChecked {
    @Input() value508 = "";
    constructor(private elRef: ElementRef) { }

    ngAfterViewChecked(): void {
        const checkboxes = document.querySelectorAll('div.ui-chkbox-box');
        checkboxes.forEach((icon, _index) => {
            this.updateTitleAndLabel(icon, this.value508)
        });
    }

    ngAfterViewInit(): void {
        const checkboxes = document.querySelectorAll('div.ui-chkbox-box');
        checkboxes.forEach((icon, _index) => {
            this.updateTitleAndLabel(icon, this.value508)
        });
    }

    updateTitleAndLabel(el: Element, msg: string) {
        if (!el.hasAttribute('title')) {
            el.setAttribute('title', msg);
        }
        if (!el.hasAttribute('aria-label')) {
            el.setAttribute('aria-label', msg);
        }
    }
}