import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'files-to-upload',
  templateUrl: './files-to-upload.component.html',
  styleUrls: ['./files-to-upload.component.scss']
})
export class FilesToUploadComponent implements OnInit {
  @Input() files: FormArray;
  @Output() remove = new EventEmitter();

  ngOnInit() {
  }
  removeFile(index) {
    this.remove.emit(index);
  }

}
