<header>
    <h2>Request &ndash; {{submissionData?.RecordLocatorId}}</h2>
</header>

<div class="row my-4">
    <div class="col-md-6">
        <dl>
            <dt>Total files</dt>
            <dd>{{!deliveryData ? "N/A" : deliveryData.length === 1 ? "1 file" : deliveryData.length + " files"}}</dd>

            <dt>Total size</dt>
            <dd *ngIf="getTotalSizeInMbytes() >= 1">{{getTotalSizeInMbytes()}} MB</dd>
            <dd *ngIf="getTotalSizeInMbytes() == -1">N/A</dd>
            <dd *ngIf="getTotalSizeInMbytes() < 1 && getTotalSizeInMbytes() >= 0">Less than 1 MB</dd>

            <dt>Total identifiers</dt>
            <dd>{{!exchangeRequests ? "N/A" : exchangeRequests.length === 1 ? "1 identifer" : exchangeRequests.length + " identifiers"}}</dd>
        </dl>
    </div>
    <div class="col-md-6">
        <dl>
            <dt>Submitted by</dt>
            <dd>{{submissionData?.Submitter}}</dd>

            <dt>Alternate Recipient</dt>
            <dd>{{getAuthorizedRecipient()}}</dd>
        </dl>
    </div>
</div>

<p-accordion [multiple]="true">
    <p-accordionTab>
        <p-header><span class="fa fa-fw fa-download"></span>&nbsp; File Downloads</p-header>

        <div class="ui-messages ui-messages-warn" *ngIf='submissionData?.HasExpiredContent'>
            <span class="ui-messages-icon pi pi-exclamation-triangle"></span>

            <ul>
                <li *ngIf='submissionData?.ContentRenewalStatus != 2 && submissionData?.ContentRenewalStatus != 4'>
                    One or more pieces of delivery content have expired.
                    <p-button type="button" (click)="doReactivateContent(submissionId)" label="Reactivate Expired Delivery Content"></p-button>
                    <span *ngIf='submissionData?.TimesReactivated >= 1'><br />Please note that you have previously
                        reactivated delivery content for this submission. Future requests will be subject to an
                        approval
                        process.</span>
                </li>
                <li *ngIf='submissionData?.ContentRenewalStatus == 4'>Your request to reactivate expired delivery
                    content
                    has been denied.</li>
            </ul>
        </div>

        <div class="expiredContentApproval" *ngIf='submissionData?.ContentRenewalStatus == 2'>
            <h5>Your delivery content reactivation request is under review.</h5>
        </div>
        <p-table #dt [columns]="cols" [value]="deliveryData" [responsive]="true" [tableStyle]="{'overflow':'hidden'}"
            [loading]="loading" [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5,10,20]" sortField="CreatedOnUtc"
            sortMode="single" sortOrder="-1" autoLayout="true" ptable508>
            <div class="row">
                <div class="col-md-6">
                    <h6>Total files: {{deliveryData?.length}}</h6>
                    <h6>Total size: {{getTotalSizeInMbytes()}} MB</h6>
                </div>
                <div class="col-md-6">
                    <h6>Submitted by: {{submissionData?.Submitter}}</h6>
                    <h6>Authorized Recipient: {{getAuthorizedRecipient()}}</h6>
                </div>
            </div>
            <ng-template pTemplate="header" let-columns>
                <tr class="text-center">
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-content let-rowData let-rowIndex="rowIndex">
                <tr style="border-style: none;">
                    <td class="ui-column-title">{{content.Name}}</td>
                    <td class="ui-column-title">{{content.SizeInMbytes}}</td>
                    <td class="ui-column-title">{{content.TimesAccessed}}</td>
                    <td class="ui-column-title-date" tooltipPosition="left" pTooltip="Date format is shown as 'yyyy/mm/dd'">{{content.LastAccessedOnUtc | date:'yyyy/MM/dd, hh:mm UTC' | default:'Never'}}</td>
                    <td class="ui-column-title-date" tooltipPosition="left" pTooltip="Date format is shown as 'yyyy/mm/dd'">{{content.CreatedOnUtc | date:'yyyy/MM/dd, hh:mm UTC'}}</td>
                    <td class="ui-column-title-date" tooltipPosition="left" pTooltip="Date format is shown as 'yyyy/mm/dd'">{{content.ExpiresOnUtc | date:'yyyy/MM/dd, hh:mm UTC'}}</td>
                    <td class="ui-column-title">
                        <div [ngSwitch]="displayPasswordStatus[content.UniqueId]">
                            <!-- Happy Path - they get to copy their password -->
                            <button
                            *ngSwitchCase="0"
                            pButton
                            label="Copy Password"
                            ngxClipboard
                            [cbContent]="content.BlobFilePassword"
                            (cbOnSuccess)="showSuccess()"
                            (cbOnError)="showPassword(content)">
                            </button>
                            <!-- Their copy failed. Display it and the countdown -->
                            <div *ngSwitchCase="2">
                                <div class="ui-g">
                                    <div class="ui-g-12">
                                        <div class="ui-inputgroup">
                                            <div>{{content.BlobFilePassword}}</div>
                                            <i class="pi pi-clock timer-space"></i>
                                            <span >{{countDown[content.UniqueId] | async}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                    <td class="ui-column-title">
                        <ng-container [ngSwitch]="content.BlobFileHash">
                            <div *ngSwitchCase="undefined">
                                None
                            </div>
                            <div *ngSwitchDefault >
                                <div [ngSwitch]="displayHashStatus[content.UniqueId]">
                                    <!-- Happy Path -->
                                    <p-button
                                    *ngSwitchCase="0"
                                    label="Copy Hash"
                                    ngxClipboard
                                    [cbContent]="content.BlobFileHash"
                                    (cbOnSuccess)="showSuccess()"
                                    (cbOnError)="showHash(content)">
                                    </p-button>
                                    <!-- Something Failed -->
                                    <div *ngSwitchCase="2">
                                        <div class="ui-g">
                                            <div class="ui-g-12">
                                                <div class="ui-inputgroup">
                                                    <div>{{content.BlobFileHash}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                    <td class="ui-column-title" *ngIf='!content.IsExpired'>
                        <button class="btn btn-link" (click)="doGetFile(submissionId, content.Name)">
                            <span class="pi pi-arrow-down"></span>&nbsp;Click to Download</button>
                    </td>
                    <td class="ui-column-title" *ngIf='content.IsExpired'>
                        Content has Expired
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="7">
                        There are no files available for download, or you are not the authorized recipient.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-accordionTab>
    <div>
        <p-accordionTab>
            <p-header><span class="fa fa-fw fa-envelope-o"></span>&nbsp; Mail Access</p-header>
            <email-monitoring [exchangeRequests]="exchangeRequests"></email-monitoring>
        </p-accordionTab>
    </div>
</p-accordion>

<div class="mt-4">
    <a href="#" [routerLink]="['/dashboard']" queryParamsHandling="preserve">
        <i class="pi pi-arrow-left"></i>&nbsp;
        Go Back</a>
</div>
