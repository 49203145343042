import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { Subject, of } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { AiLoggingService } from 'src/app/core/services/ai-logging.service';
import { SpinnerService } from "src/app/core/services/spinner.service";
import { Country } from "../../models/country";
import { MicrosoftService } from "../../models/microsoft-service";
import { NatureOfCrimesDTO } from "../../models/nature-of-crimes-dto.model";
import { NatureOfCrimes } from '../../models/nature-of-crimes-model';
import { NewRequestInternational } from '../../models/new-request-international';
import { SubmissionAttachment } from "../../models/submission-attachment.model";
import { FileUploadService } from "../../services/file-upload.service";
import { Store } from "../../services/store.service";
import { SubmissionService } from '../../services/submission.service';

@Component({
  selector: "new-request-international",
  templateUrl: "./new-request-international.component.html",
  styleUrls: ["./new-request-international.component.scss"]
})
export class NewRequestInternationalComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() country: Country;
  @Input() showInternationalWarning: boolean;
  private ngUnsub: Subject<void> = new Subject<void>();
  yesno: { label: string; value: boolean; icon: string }[] = [
    { label: "Yes", value: true, icon: "pi pi-check" },
    { label: "No", value: false, icon: "pi pi-times" }
  ];

  // Stepper
  activeIndex: Store<number>;
  items: MenuItem[];

  // Variables
  internationalHeader: string;
  internationalWarningText: string;
  submitting: boolean = false;
  submissionHistoryLocator: string;
  fileNames: string[];
  reviewAndSubmitStep: number;

  // Footer Buttons
  showCancelWithFileDeleteDialog: boolean;
  showCancelSimpleDialog: boolean;
  isFaulted: boolean = false;

  get isSubmitDisabled(): boolean {
    return this.form.invalid || this.submitting || this.isFaulted;
  }

  constructor(
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private router: Router,
    private spinnerService: SpinnerService,
    private submissionService: SubmissionService,
    private logger: AiLoggingService

  ) { }

  ngOnInit() {
    this.setInternationalWarningText();
    this.setStepperLabels();
    this.addBaseFormControls();
    this.activeIndex = new Store<number>(0);
    // Various buttons react different depending on the final step. We define that here for easy updating in the future.
    this.reviewAndSubmitStep = 6;
  }

  ngOnDestroy() {
    if (this.form) {
      this.form.removeControl("international");
    }
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  //#region Accessors
  // provided as forms to sub-components
  get childExploitation() {
    return this.international.get("cpConcern").value === true ? "Yes" : "No";
  }

  get international(): FormGroup {
    return this.form.get("international") as FormGroup;
  }
  get requestingAgentInformationForm(): FormGroup {
    return this.international.get("requestingAgentInformationForm") as FormGroup;
  }
  get typeOfServiceForm(): FormGroup {
    return this.international.get("typeOfServiceForm") as FormGroup;
  }
  get notificationForm(): FormGroup {
    return this.international.get("notificationForm") as FormGroup;
  }
  get fileUploadForm(): FormGroup {
    return this.international.get("fileUploadForm") as FormGroup;
  }
  get natureOfCrimeForm(): FormGroup {
    return this.international.get("natureOfCrimeForm") as FormGroup;
  }
  get isDifferentRecipientControl(): FormControl {
    return this.international.get("hasDifferentRecipient") as FormControl;
  }
  get isPreservationRequestControl(): FormControl {
    return this.international.get("isPreservationRequest") as FormControl;
  }
  get isPreservationAttestionControl(): FormControl {
    return this.international.get("isPreservationAttestion") as FormControl;
  }
  get isCPConcernControl(): FormControl {
    return this.international.get("cpConcern") as FormControl;
  }
  // for the review step
  get notificationPermitted() {
    return this.notificationForm.get("notification").value === true ? "Yes" : "No";
  }
  get isPreservation() {
    return this.isPreservationRequestControl.value
      ? "Yes"
      : "No";
  }
  get differentRecipientReview() {
    if (
      this.international.get("hasDifferentRecipient") &&
      this.international.get("hasDifferentRecipient").value
    ) {
      return this.international.get("deliveryEmail").value;
    } else {
      return "N/A";
    }
  }
  get agencyName() {
    return this.requestingAgentInformationForm.get("agencyName").value;
  }
  get agentFirstName() {
    return this.requestingAgentInformationForm.get("agentFirstName").value;
  }
  get agentLastName() {
    return this.requestingAgentInformationForm.get("agentLastName").value;
  }
  get agentEmail() {
    return this.requestingAgentInformationForm.get("agentEmail").value;
  }
  get sendToEmail() {
    return this.requestingAgentInformationForm.get("sendToEmail").value;
  }
  get agentPhoneNumber() {
    return this.requestingAgentInformationForm.get("agentPhoneNumber").value;
  }
  get leReferenceNumber() {
    return this.international.get("leReferenceNumber").value;
  }

 /**
 * This function retrieves the values of the NatureOfCrimes form.
 * 
 * It first gets the list of the field names of the NatureOfCrimes class.
 * It then iterates through these names and uses them to retrieve the corresponding values from the form controls.
 * 
 * The `reduce` function is used to build up an object of the NatureOfCrimesDTO type, where each property 
 * corresponds to a field name of the NatureOfCrimes class, and the value is the value of the corresponding form control.
 * 
 * This object will be used to send data to the backend in the required format.
 * 
 * @returns NatureOfCrimesDTO object which contains selected nature of crimes.
 */
  getNatureOfCrimeValues(): NatureOfCrimesDTO {
    const natureOfCrimesProperties = NatureOfCrimes.getPropertyNames();
    const natureOfCrimesObject: NatureOfCrimesDTO = natureOfCrimesProperties.reduce(
      (acc: NatureOfCrimesDTO, value: string) => {
        const formControlName = NatureOfCrimes.modifyFormControlName(NatureOfCrimes[value]);
        acc[value] = this.natureOfCrimeForm.get(formControlName).value;
        return acc;
      },
      {} as NatureOfCrimesDTO
    );
    natureOfCrimesObject["DescriptionOther"] = this.natureOfCrimeForm.get("isOtherDescription").value;
    return natureOfCrimesObject;
  }

  get isUK() {
    return this.country.Name == 'United Kingdom'
  }
  get services() {
    const names: Array<string> = [];
    const defaultOptionsSelected = this.typeOfServiceForm.get("selectedServices")
      .value as MicrosoftService[];
    const manuallyEntered = this.typeOfServiceForm.get("manualServices").value as Array<
      string
    >;
    defaultOptionsSelected.forEach(option => names.push(option.Name));

    // we remove any duplicates, in case they manually entered Skype or something
    const deDuped = Array.from(new Set(names.concat(manuallyEntered)));
    // remove any empty values
    return deDuped.filter(val => val != (undefined || null || "" || ''));
  }

  // errors
  get notSame() {
    return (
      this.international.get("acknowledgement").hasError("notSame") &&
      this.international.get("acknowledgement").dirty
    );
  }

  //#endregion

  private addBaseFormControls() {
    this.form.addControl(
      "international",
      new FormGroup({
        requestingAgentInformationForm: new FormGroup({}),
        typeOfServiceForm: new FormGroup({}),
        notificationForm: new FormGroup({}),
        fileUploadForm: new FormGroup({}),
        natureOfCrimeForm: new FormGroup({}, Validators.required),
        countryOrigin: new FormControl(this.country.Id),
        isPreservationRequest: new FormControl(null, Validators.required),
        isGradeTwo: new FormControl(false),
        hasDifferentRecipient: new FormControl(false),
        deliveryEmail: new FormControl(null),
        leReferenceNumber: new FormControl(null, Validators.required),
        isPreservationAttestion: new FormControl(null, Validators.required),
        cpConcern: new FormControl(null)
      })
    );

    // If it's preservation, we don't offer altenrative delivery. Wipe any prior value
    this.isPreservationRequestControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsub),
        tap(isPreservation => {
          if (isPreservation) {
            this.isDifferentRecipientControl.setValue(null);
            this.isDifferentRecipientControl.clearValidators();
            this.isDifferentRecipientControl.markAsPristine();
            this.isDifferentRecipientControl.updateValueAndValidity();
          }
          else {
            this.isPreservationAttestionControl.clearValidators();
            this.isPreservationAttestionControl.markAsPristine();
            this.isPreservationAttestionControl.updateValueAndValidity();
            this.isDifferentRecipientControl.setValidators(Validators.required);
            this.international.updateValueAndValidity();
          }
        })
      )
      .subscribe()

    // If they decide they don't want a different recipient, wipe the value that may be in the delivery control
    this.isDifferentRecipientControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsub),
        tap(isDifferent => {
          if (isDifferent) {
            this.international.get('deliveryEmail').setValidators([Validators.email, Validators.required]);
          } else {
            this.international.get('deliveryEmail').setValue(null);
            this.international.get('deliveryEmail').clearValidators();
            this.international.get('deliveryEmail').markAsPristine();
            this.international.get('deliveryEmail').updateValueAndValidity();
          }
        })
      )
      .subscribe()

  }

  addControlValuesToForm(
    formGroup: FormGroup | FormArray,
    formToSend: FormData
  ): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as
        | FormControl
        | FormGroup
        | FormArray;
      // handle our files. Brittle as this assumes only our files use formarray, but that's the case today
      if (control instanceof FormArray) {
        for (const file of control.controls) {
          const fileToUpload = file.value;
          formToSend.append(fileToUpload.name, fileToUpload);
        }
      } else if (control instanceof FormControl) {
        formToSend.append(key, control.value);
      } else if (control instanceof FormGroup) {
        this.addControlValuesToForm(control, formToSend);
      } else {
        console.log("ignoring control");
      }
    });
  }

  // Used to download the files a user uploads during the submission process
  doGetFile(fileName) {
    this.fileUploadService
      .getFile(this.submissionHistoryLocator, fileName)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(value => {
          const file: string = value;
          window.open(file, "_blank");
        }),
        catchError(error => {
          this.sendUserMessagePopup(
            "error",
            "Content Download Error",
            "Your attempt to download this file failed. Please try again."
          );

          throw error;
        })
      )
      .subscribe();
  }

  // This method cancels the submission request the user is working on
  deleteUploadedFilesAndCancel() {
    this.showCancelWithFileDeleteDialog = false;
    this.spinnerService.startSpinner();
    this.fileUploadService
      .removeSubmissionRequest(this.submissionHistoryLocator)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(
          () => {
            this.sendUserMessagePopup(
              "success",
              "Submission Canceled",
              "Successfully canceled your submission request."
            );

            this.spinnerService.stopSpinner();
            // navigate to success page
            this.router.navigate(["/dashboard"]);
          },
          (error: any) => {
            this.sendUserMessagePopup(
              "error",
              "Cancel Error",
              "Part of the clean-up failed."
            );

            this.spinnerService.stopSpinner();
            this.router.navigate(["/dashboard"]);
          }
        )
      )
      .subscribe();
  }

  prepareForm(): NewRequestInternational {
    return {
      CountryOrigin: this.country,
      DeliveryEmail: this.international.get("deliveryEmail")
        ? this.international.get("deliveryEmail").value
        : "",
      HasDifferentRecipient: this.international.get("hasDifferentRecipient")?.value || false,
      IsPreservation: this.international.get("isPreservationRequest").value,
      IsGradeTwo: this.international.get("isGradeTwo").value,
      SubmissionHistoryLocator: this.submissionHistoryLocator,
      AgencyName: this.requestingAgentInformationForm.get('agencyName').value,
      AgentFirstName: this.requestingAgentInformationForm.get('agentFirstName').value,
      AgentLastName: this.requestingAgentInformationForm.get('agentLastName').value,
      AgentEmail: this.requestingAgentInformationForm.get('agentEmail').value,
      AgentPhone: this.requestingAgentInformationForm.get('agentPhoneNumber').value,
      LEReferenceNumber: this.international.get('leReferenceNumber').value,
      NotifyTarget: this.notificationForm.get('notification').value,
      Services: this.services,
      NatureOfCrimes: this.getNatureOfCrimeValues(),
      IsChildExploitation: this.international.get("cpConcern").value
    };
  }

  prepareFormData(): FormData {
    const formToSend = new FormData();
    this.addControlValuesToForm(this.international, formToSend);
    return formToSend;
  }


  previous() {
    this.activeIndex.setState(this.activeIndex.state - 1);
  }

  next() {
    switch (this.activeIndex.state) {
      // Type of Request
      case 0:
        if (this.isCPConcernControl.value == null) {
          this.sendUserMessagePopup(
            "error",
            "Child Exploition",
            "Please indicate if this is a Child Exploitation request."
          );
        }
        if (this.isPreservationRequestControl.value) {
          if (!this.isPreservationAttestionControl.value || this.isPreservationAttestionControl.value == null )
          this.sendUserMessagePopup(
            "error",
            "Preservation Request - Attestation",
            "Please provide the preservation request attestation."
          );
        }
        if (this.isPreservationRequestControl.invalid
            || this.isDifferentRecipientControl.invalid
            || this.international.get('deliveryEmail').invalid
            || this.international.get('leReferenceNumber').invalid) {
          if (this.isPreservationRequestControl.invalid) {
            this.sendUserMessagePopup(
              "error",
              "Preservation Request",
              "Please indicate if this is a preservation request."
            );
          }
          if (this.isDifferentRecipientControl.invalid) {
            this.sendUserMessagePopup(
              "error",
              "Different Recipient",
              "Please indicate if you would like to designate a different recipient."
            );
          }
          if (this.international.get('deliveryEmail').invalid) {
            this.sendUserMessagePopup(
              "error",
              "OnBehalfOf",
              "Please enter a valid email."
            );
          }
          if (this.international.get('leReferenceNumber').invalid) {
            this.sendUserMessagePopup(
              "error",
              "LE Reference Number",
              "Value cannot be left blank."
            );
          }
        } else {
          this.activeIndex.setState(this.activeIndex.state + 1);
        }
        break;
         // Nature of crimes
         case 1:    
         if (this.natureOfCrimeForm.valid) {
           this.activeIndex.setState(this.activeIndex.state + 1);
         }
         else if (this.natureOfCrimeForm.get('isOtherDescription').invalid) {
           this.sendUserMessagePopup(
             "error",
             "Nature Of Crime Other Description is missing.",
             "Description is required when Other is selected."
           );
         } else {
           this.sendUserMessagePopup(
             "error",
             "Incomplete Nature of Crime(s) Step",
             "Please validate your Nature of crime(s) selections."
           );
         }
         break;    
      // Requesting Agent Information
      case 2:
        if (this.requestingAgentInformationForm.valid) {
          this.activeIndex.setState(this.activeIndex.state + 1);
        } else if (this.requestingAgentInformationForm.hasError("noMatch")) {
          this.sendUserMessagePopup(
            "error",
            "Confirmed Email does not match",
            "Please ensure the email confirmation fields match."
          );
        } else {
          this.sendUserMessagePopup(
            "error",
            "Incomplete Requesting Agent Step",
            "Please ensure all the fields are filled out with valid data."
          );
        }
        break;
      // Type of Service
      case 3:
        if (this.typeOfServiceForm.valid) {
          this.activeIndex.setState(this.activeIndex.state + 1);
        } else {
          this.sendUserMessagePopup(
            "error",
            "Incomplete Type of Service",
            "Please select or enter at least one service."
          );
        }
        break;
      // Notification
      case 4:
        if (this.notificationForm.valid) {
          this.activeIndex.setState(this.activeIndex.state + 1);
        } else {
          this.sendUserMessagePopup(
            "error",
            "Target Notification Permitted",
            "Please select the permission for this request."
          );
        }
        break;
      // File Upload
      case 5:
        this.uploadValidation(this.fileUploadForm);
        break;
      default:
        this.sendUserMessagePopup(
          "error",
          "Error: Invalid Index",
          "You should not be here. Please go back."
        );
        break;
    }
  }

  // Utility method for creating pop messages that the user will see
  sendUserMessagePopup(type: string, title: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }

  setInternationalWarningText() {
    this.internationalHeader = this.country.InternationalWarning.Header;
    this.internationalWarningText = this.country.InternationalWarning.WarningText;
  }

  private setStepperLabels() {
    this.items = [
      { label: "Type of Request" },
      { label: "Nature of Crimes Details" },
      { label: "Requesting Agent Information" },
      { label: "Type of Service" },
      { label: "Notification" },
      { label: "File Upload" },
      { label: "Acknowledge & Submit" }
    ];
  }

  submit() {
    this.logger.logInformation("Submitting new request.");
    const formToSend = this.prepareForm();
    this.submitting = true;
    this.spinnerService.startSpinner();

    this.submissionService
      .postInternationalSubmission(formToSend)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(() => {
          this.logger.logInformation("Successfully submitted new request.");
          this.sendUserMessagePopup(
            "success",
            "Submission Confirmation",
            "Thank you for your submission.  Your request will be reviewed."
          );

          this.submitting = false;
          this.spinnerService.stopSpinner();
          // navigate to success page
          this.router.navigate(["/dashboard"], {
            queryParamsHandling: "preserve",
          });
        }),
        catchError((error) => {
          this.logger.logInformation("Failed to submit new request.");
          this.sendUserMessagePopup(
            "error",
            "Submission Error",
            "Submission failed.  Please try again."
          );
          this.submitting = false;
          this.isFaulted = true;
          this.spinnerService.stopSpinner();
          // throw so the Global handler can log this
          return of(error);
        })
      )
      .subscribe();
  }

  // This method is used to show/hide the cancel dialog box
  toggleCancelWithFileDeleteDialog() {
    this.showCancelWithFileDeleteDialog = !this.showCancelWithFileDeleteDialog;
  }

  // This is used to toggle the simple cancel function, which simply routes the user back to the dashboard.
  // This is only used in steps 1-3 before they upload any files.
  toggleSimpleCancelDialog() {
    this.showCancelSimpleDialog = !this.showCancelSimpleDialog;
  }

  uploadAttachmentsAndSaveRequestState() {
    const formModel: FormData = this.prepareFormData();
    this.spinnerService.startSpinner();
    this.fileUploadService
      .postAttachment(formModel)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(resp => {
          let sub: SubmissionAttachment = resp.body;
          this.submissionHistoryLocator = sub.SubmissionHistoryLocator;
          this.fileNames = sub.FileNames;
          this.spinnerService.stopSpinner();
          this.sendUserMessagePopup(
            "success",
            "Attachments Upload Confirmation",
            "Successfully uploaded your attachment, please continue to finish the submission."
          );
          this.activeIndex.setState(this.activeIndex.state + 1);
        }),
        catchError((error: any) => {
          this.spinnerService.stopSpinner();
          this.sendUserMessagePopup(
            "error",
            "File Upload Error",
            "Submission failed. Please try again."
          );
          // throw so the Global handler can log this
          throw error;
        })
      )
      .subscribe();
  }

  uploadValidation(uploadForm: FormGroup) {
    if (uploadForm.get("files").hasError("invalidMinCount")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "You must upload a file before you continue.  Please try again."
      );
    } else if (uploadForm.get("files").hasError("invalidSize")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file did not meet the size requirements.  Please try again."
      );
    } else if (uploadForm.get("files").hasError("invalidExt")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file has an invalid file extension.  Please try again."
      );
    } else {
      // Upload will handle incrementing the activeIndex depending on successful upload status
      this.uploadAttachmentsAndSaveRequestState();
    }
  }
}
