<div id="main-container">
  <div id="title-fluid">
    <div id="title">
      Frequently Asked Questions
    </div>
  </div>

  <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
    <!-- Subject -->
    <p-accordionTab header="Law Enforcement and National Security Team Guidance">
      <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
        <!-- Question -->
        <p-accordionTab header="What is the Hotline Number?">
          <!-- Answer -->
          <div>Call 425-722-1299</div>
        </p-accordionTab>
      </p-accordion>
      <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
        <!-- Question -->
        <p-accordionTab header="How do I submit Emergency legal process?">
          <!-- Answer -->
          <div>Call 425-722-1299 (press 1 when prompted)</div>
        </p-accordionTab>
      </p-accordion>
      <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
        <!-- Question -->
        <p-accordionTab header="How do I submit ‘General Inquiries’?">
          <!-- Answer -->
          <div>
            United States: usleainfo@microsoft.com<br>
            EU Member States: euleainfo@microsoft.com<br>
            Other: intleainfo@microsoft.com
          </div>
          <div>
            <br>
            Phone: 425-722-1299, option 2
          </div>
        </p-accordionTab>
      </p-accordion>
      <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
          <!-- Question -->
          <p-accordionTab header="Can you provide legal process guidance?">
            <!-- Answer -->
            <div>
                Microsoft is not able to advise on specific language for your legal process.  However, please be as specific as possible and identify the service (Skype, Xbox, etc.) from which you are requesting information.
            </div>            
          </p-accordionTab>
        </p-accordion>
    </p-accordionTab>
    <!-- Subject -->
    <p-accordionTab header="LE Portal Tool Usage Guidance">
        <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
          <!-- Question -->
          <p-accordionTab header="Do you have a Quick Reference Guide for this new tool?">
            <!-- Answer -->            
            <div class="download-button" (click)="GetDocument()">                
              
                <i class="pi pi-download">

                </i>
                <span>
                  Quick Reference Guide
                </span> 
              
            </div>
          </p-accordionTab>
        </p-accordion>
        <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
          <!-- Question -->
          <p-accordionTab header="How do I decrypt the packaged data?">
            <!-- Answer -->
            <div>The data packages are compressed in .7z format (https://www.7-zip.org) and utilize AES-256 encryption, which is a security industry standard.</div>
            <div>We have implemented new zipping functionality that guarantees zip files will be no larger than a given chunk size as selected by the Microsoft fulfillment manager. All of the 7z files that are associated with the delivery package need to be download prior to performing the unzip operation (using a 7z supported utility). Select all of the downloaded files and paste the password when performing the unzip/decrypt operation. For example, if there are three delivery packages associated with your submission, then all three packages need to be downloaded prior to performing the .7z unzip operation.</div>
            <div>To ensure that your capabilities meet the requirements to handle the data package, please verify the following:</div>
            <ul>
              <li>
                Your compression/extraction utility supports the .7z archive format
              </li>
              <li>
                Your compression/extraction utility supports AES-256 encryption
              </li>
              <li>
                You are using the correct password, which can be found by clicking the Copy Password on the Access Data page. This password will be automatically copied to your system's clipboard and can be accessed by pressing CTRL-V or by right-clicking with your mouse and selecting Paste. If there is a failure copying the password, it will display on the screen and can be copied manually using CTRL-C or your mouse.
              </li>
            </ul>
            <div>
              Should you find that your compression utility does not support the above requirements, we suggest using the 7-zip utility (https://www.7-zip.org). If you continue to have difficulty extracting the data after verifying whether your systems are adequate to extract the data as described above, please feel free to contact our Law Enforcement Hotline for further assistance at 425-722-1299.
            </div>
          </p-accordionTab>
        </p-accordion>
        <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <!-- Question -->
            <p-accordionTab header="Training videos">
              <!-- Answer -->
              <div class="download-button video-button" (click)="showRegistrationVideo()">                
              
                  <i class="pi pi-video">
  
                  </i>
                  <span>
                      Account Registration and Login Training Video
                  </span> 
                
              </div>
              <br>
              <div class="download-button video-button" (click)="showDataDownloadVideo()">                
              
                  <i class="pi pi-video">
  
                  </i>
                  <span>
                      Downloading Data Training Video
                  </span> 
                
              </div>
            </p-accordionTab>
          </p-accordion>        
      </p-accordionTab>
  </p-accordion>
</div>

<div id="go-back" (click)="GoBack()">
  <div>
    <i class="pi pi-arrow-left"></i>&nbsp;
    <span> Go Back </span>
  </div>
</div>

<p-dialog header="{{trainingVideoTitle}}" [modal]="true" [(visible)]="showTrainingVideo" [style]="{ width: '682px' }">
    <video controls #videoPlayer>
      <source src="{{trainingVideoUrl}}" type="video/mp4" />
      Browser not supported
    </video>
  </p-dialog>  