<div class="p-grid p-dir-col" [formGroup]="form">
  <div class="p-col">
    <label
      >Are you submitting a law enforcement request related to Child
      Exploitation?
    </label>
    <div class="input-switch-container">
      <p-selectButton
        [options]="yesno"
        formControlName="cpConcern"
      ></p-selectButton>
    </div>
    <hr />
  </div>

  <div class="p-col">
    <label>Is this a preservation request?</label>
    <div class="input-switch-container">
      <p-selectButton
        [options]="yesno"
        formControlName="isPreservationRequest"
      ></p-selectButton>
    </div>
    <hr />
  </div>

    <div class="p-col-12">
      <label for="leReferenceNumber">LE Reference Number</label>
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon"
          ><i class="pi pi-table" style="line-height: 1.25;"></i
        ></span>
        <input
          id="leReferenceNumber"
          style="border-width: thin;"
          type="text"
          formControlName="leReferenceNumber"
          pInputText
          placeholder="Reference Number"
          aria-label="Reference Number"
          title="Reference Number"
        />
      </div>
      <hr />
    </div>
  
      
  <div class="p-col" *ngIf="!cpConcern && !isPreservationRequest">
    <label
      >Do you want notifications from the Law Enforcement portal sent to another agent?</label
    >
    <div>
      <p-selectButton
        [options]="yesno"
        formControlName="hasDifferentRecipient"
      ></p-selectButton>
    </div>
    <hr *ngIf="!isDifferentRecipient" />

    <div *ngIf="isDifferentRecipient" class="p-col">
      <span class="ui-float-label">
        <input
          id="email"
          type="email"
          size="50"
          formControlName="deliveryEmail"
          autocomplete="off"
          pInputText
        />
        <label for="email">Recipient's Government Email Address</label>
      </span>
      <hr />
    </div>
  </div>
  
  <div class="p-col">
    <label>Does your request seek documents, information, or testimony relating to an investigation into, or the enforcement of, a law that asserts criminal or civil liability for the provision, receipt, attempted provision or receipt, assistance in provision or receipt of, or attempted assistance in the provision or receipt of gender-affirming treatment or reproductive health care services, that are lawful in the state of Washington?</label>
    <div class="input-switch-container">
      <p-selectButton
        [options]="yesno"
        formControlName="isWAShieldLaw"
      ></p-selectButton>
    </div>
    <hr />
  </div>

</div>
