import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  unSub: Subject<void>;
  constructor(private faqService: FaqService, private location: Location) {}

  showTrainingVideo: boolean = false;
  trainingVideoTitle: string;
  trainingVideoUrl: string;

  videoPlayer: HTMLVideoElement;
  @ViewChild('videoPlayer')
    set mainVideoEl(el: ElementRef) {
      this.videoPlayer = el.nativeElement;
      this.videoPlayer.width = 640;
    }

  ngOnInit() {
    this.unSub = new Subject<void>();
  }

  ngOnDestroy(){
    this.unSub.next();
    this.unSub.complete();
  }

  GetDocument() {
    this.faqService.GetQuickReferenceDocument()
    .pipe(
      takeUntil(this.unSub),
      tap((data: Blob) => {
        // Create a Blob from the response data and trigger a download
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'LE Portal - Quick Reference Guide_HA.docx');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }))
    .subscribe();
  }
  
  
  GoBack() {
    this.location.back();
  }

  showRegistrationVideo(): void {
    this.showTrainingVideo = true;
    this.trainingVideoTitle = 'Account Registration and Login';
    this.trainingVideoUrl = environment.videoAssets.registrationVideoUrl;
    this.playTrainingVideo();
  }

  showDataDownloadVideo(): void {
    this.showTrainingVideo = true;
    this.trainingVideoTitle = 'Downloading Data';
    this.trainingVideoUrl = environment.videoAssets.dataDownloadVideoUrl;
    this.playTrainingVideo();
  }

  playTrainingVideo() {
    this.videoPlayer.src = this.trainingVideoUrl;
    this.videoPlayer.play();
  }
}
