import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject, combineLatest } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
// ReadMe: Business Rules to know
// If it's a cpConcern or a preservation request, they don't get the option to provide an alternate email
// The recipient email will add itself and remove itself from the form onInit/onDestroy. Validation updates with this.
// Since we've broken apart the NDO steps, we also need to let the ndo component know it needs an NDO
// to do this, we emit when both controls are false

@Component({
  selector: "type-of-request",
  templateUrl: "./type-of-request.component.html",
  styleUrls: ["./type-of-request.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeOfRequestComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() activeIndex: number;
  @Output() needsNdo: EventEmitter<boolean> = new EventEmitter();
  private ngUnsub: Subject<void> = new Subject<void>();

  yesno: { label: string; value: boolean; icon: string }[];

  constructor() {}

  ngOnInit() {
    this.form.addControl(
      "cpConcern",
      new FormControl(null, Validators.required)
    );
    this.form.addControl(
      "isPreservationRequest",
      new FormControl(null, Validators.required)
    );
    this.form.addControl(
      "leReferenceNumber",
      new FormControl(null, Validators.required)
    );
    this.form.addControl(
      "isWAShieldLaw",
      new FormControl(null, Validators.required)
    );

    this.addHasDifferentRecipientControl();

    this.yesno = [
      { label: "Yes", value: true, icon: "pi pi-check" },
      { label: "No", value: false, icon: "pi pi-times" }
    ];

    //combines the state of our controls so we can determine if we need hasDifferentRecipient.
    combineLatest(
      this.form.get("cpConcern").valueChanges,
      this.form.get("isPreservationRequest").valueChanges
    )
      .pipe(
        takeUntil(this.ngUnsub),
        tap(([cp, pre]) => {
          if (cp || pre) {
            this.needsNdo.emit(false);
            this.form.removeControl("deliveryEmail");
            this.form.removeControl("hasDifferentRecipient");
          }
          if (!cp && !pre) {
            this.needsNdo.emit(true);
            this.addHasDifferentRecipientControl();
          }
          this.form.updateValueAndValidity();
        })
      )
      .subscribe();
  }

  private addHasDifferentRecipientControl() {
    this.form.addControl(
      "hasDifferentRecipient",
      new FormControl(false, Validators.required)
    );
    // if they have a different recipient, we need the email and it's now required
    this.form
      .get("hasDifferentRecipient")
      .valueChanges.pipe(
        takeUntil(this.ngUnsub),
        tap((isDifferentRecipient: FormControl) => {
          if (isDifferentRecipient) {
            this.form.addControl(
              "deliveryEmail",
              new FormControl(null, [Validators.email, Validators.required])
            );
          } else {
            this.form.removeControl("deliveryEmail");
          }
          this.form.updateValueAndValidity();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  get cpConcern(): boolean {
    return this.form.get("cpConcern").value === true;
  }
  get isPreservationRequest(): boolean {
    return this.form.get("isPreservationRequest").value === true;
  }
  get isWAShieldLaw(): boolean {
    return this.form.get("isWAShieldLaw").value === true;
  }
  get isDifferentRecipient(): boolean {
    return this.form.get("hasDifferentRecipient").value === true;
  }

  get leReferenceNumber() {
    return this.form.get("leReferenceNumber").value;
  }
}
