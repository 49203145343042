import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AiLoggingService } from '../../core/services/ai-logging.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { User } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private lePortalUser$: Observable<User>;
  public msalUser$ = new BehaviorSubject<AccountInfo>(null);

  constructor(
    private httpClient: HttpClient,
    private logger: AiLoggingService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinnerService: SpinnerService) {
    this.handleAccountActions();
  }

  /**
   * Subscribe for the User as represented in the LE Portal database.
   */
  public GetLePortalUser(): Observable<User> {
    if (!this.IsAuthenticated()) {
      return of(null);
    }
    
    if (this.lePortalUser$) {
      return this.lePortalUser$;
    } else {
      this.setLePortalUser();
      return this.lePortalUser$;
    }

  }

  public RefreshLePortalUser(){
    this.setLePortalUser();
  }


  private setLePortalUser(){
    this.lePortalUser$ = this.getUser().pipe(shareReplay(1));
  }

  /**
 * Gets the authenticated user Account Info.
 */
  public GetMsalUser(): Observable<AccountInfo> {
    return this.msalUser$.asObservable();
  }

  /**
 * Returns true if a user is authenticated via msal.
 */
  public IsAuthenticated(): boolean {
    return this.msalService.instance.getActiveAccount() !== null;
  }


  /**
 * Logs out the authenticated MSAL account.
 */
  public Logout() {
    this.msalUser$.next(null);
    this.msalService.logout();
  }

  private handleActiveUserMonitor() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
    }
    if (activeAccount && !this.msalUser$.value) {
      this.msalUser$.next(this.msalService.instance.getActiveAccount())
    }
  }

  private getUser(): Observable<User> {
    this.spinnerService.startSpinner();
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
    });
    return this.httpClient
      .get<User>(environment.base + '/login/GetUser', { headers: headers, withCredentials: false })
      .pipe(
        tap((user: User) => {
          this.logger.setUserId(user.OfficialEmailAddress);
        }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => this.spinnerService.stopSpinner())
      );
  }

  private handleAccountActions() {
    this.handleActiveUserMonitor();
    this.handleAquireTokenFailure();
    this.handleLogin();
    this.handleLogout();
  }

  handleAquireTokenFailure() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe(() => {
        this.logger.logInformation("Aquire token failure.");
      })
  }

  handleLogin() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe(() => {
        this.logger.logInformation("User successfully logged in.");
        this.checkAndSetActiveAccount();
      })
  }

  handleLogout() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS),
      )
      .subscribe(() => {
        this.logger.logInformation("User successfully logged out.");
      })
  }
}


