<div id="my-submissions">
  <p-table #dt [value]="submissionData" [responsive]="true" [tableStyle]="{'overflow':'hidden'}"
    [loading]="loading$ | async" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,20]"
    sortField="SubmittedOnUTC" sortMode="single" autoLayout="true">
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'RecordLocatorId'">Reference Number <p-sortIcon [field]="'RecordLocatorId'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'LEReferenceNumber'">LE Reference Number <p-sortIcon [field]="'LEReferenceNumber'">
          </p-sortIcon>
        </th>
        <th [pSortableColumn]="'Status'">Request Status <p-sortIcon [field]="'Status'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'IntlReviewStatus'">Country Contact Status <p-sortIcon [field]="'IntlReviewStatus'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'Country.Name'">Country<p-sortIcon [field]="'Country.Name'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'Type'">Type <p-sortIcon [field]="'Type'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'Submitter'">Submitted By <p-sortIcon [field]="'Submitter'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'SubmittedOnUTC'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Submitted <p-sortIcon
            [field]="'SubmittedOnUTC'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'IntlReviewApprovedOnUTC'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Approved <p-sortIcon
            [field]="'IntlReviewApprovedOnUTC'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'FulfilledOnUTC'" pTooltip="Date format is shown as 'yyyy-mm-dd'">Fulfilled <p-sortIcon
            [field]="'FulfilledOnUTC'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'AttachmentTotalSizeInBytes'">Size (Mb) <p-sortIcon [field]="'AttachmentTotalSizeInBytes'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sub>
      <tr style="border-style: none">
        <td class="ui-column-title"><a
            [routerLink]="['/international-review', sub.SubmissionHistoryLocator]">{{sub.RecordLocatorId}}</a></td>
        <td class="ui-column-title">{{sub.LEReferenceNumber}}</td>
        <td class="ui-column-title">{{submissionStatus[sub.Status]}}</td>
        <td class="ui-column-title">{{intlReviewStatus[sub.IntlReviewStatus]}}</td>
        <td class="ui-column-title">{{sub.Country.Name}}</td>
        <td class="ui-column-title">{{sub.Type}}</td>
        <td class="ui-column-title">{{sub.Submitter}}</td>
        <td class="ui-column-title">{{sub.SubmittedOnUTC | date:'yyyy-MM-dd HH:mm UTC'}}</td>
        <td class="ui-column-title">{{sub.IntlReviewApprovedOnUTC | date:'yyyy-MM-dd HH:mm UTC'}}</td>
        <td class="ui-column-title">{{sub.FulfilledOnUTC | date:'yyyy-MM-dd HH:mm UTC'}}</td>
        <td class="ui-column-title">{{sub.AttachmentTotalSizeInBytes/1024/1024 | number:'0.0-3'}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="5">
          <i>You have no submissions of this type.</i>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
