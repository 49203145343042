import {
  AbstractControl,
  ValidatorFn,
  FormControl,
  FormGroup,
  FormArray,
  Form
} from "@angular/forms";

// You can give this validator a FormArray or FormGroup and it will return valid if at least one control has a value
// It does not matter how nested the controls
const error = { invalid: true };

export function AtLeastOneValue(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const controlToCheck = control as FormControl | FormGroup | FormArray;

    // you should probably use Validators.required, but we'll help you out
    if (controlToCheck instanceof FormControl) {
      if (controlHasValue(controlToCheck)) {
        return null;
      } else {
        return error;
      }
    }

    // Here's our magic sauce
    if (nestedControlsHaveValue(controlToCheck)) {
      return null;
    } else {
      return error;
    }
  };
}

function nestedControlsHaveValue(control: FormGroup | FormArray): boolean {
  let hasValue = false;

  Object.keys(control.controls).forEach(key => {
    const controlToCheck = control.controls[key] as
      | FormControl
      | FormGroup
      | FormArray;

    if (controlToCheck instanceof FormControl) {
      if (controlHasValue(controlToCheck)) {
        hasValue = true;
      }
    } else if (
      controlToCheck instanceof FormGroup ||
      (FormArray && hasValue == false)
    ) {
      hasValue = this.nestedControlsHaveValue(control);
    }
  });

  return hasValue;
}

//checks if one value is checked and equals true
export function atLeastOneChecked(formGroup: FormGroup) {
  const controls = formGroup.controls;
  const checkedControls = Object.values(controls).filter((control) => control.value === true);

  if (checkedControls.length === 0) {
    return { atLeastOneChecked: true };
  }
  return null;
}

function controlHasValue(control: FormControl): boolean {
  const val = control.value;
  if (Array.isArray(val)) {
    return val.length != 0;
  } else {
    if (
      val == null ||
      val === null ||
      typeof val === "undefined" ||
      val !== ""
    ) {
      return false;
    } else {
      return true;
    }
  }
}
