<div id="feature">
  <div id="content">
      <h1 id="unavailable">LE Portal - Unauthorized</h1>
      <br />
      <ng-container *ngIf="unauthorizedReason$ | async">
        <div>
          {{ (unauthorizedReasonText) }}
        </div>
      </ng-container>
      <ng-container *ngIf="!(unauthorizedReason$ | async)">
          <div>
              The page or action you are attempting is not authorized.   
          </div>
      </ng-container> 
  </div>
</div>
