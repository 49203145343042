import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubmissionService } from '../../services/submission.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../../../core/services/spinner.service';
import { AcknowledgementValidator } from "../validators/acknowledgement.validator";
import { AiLoggingService } from '../../../core/services/ai-logging.service';
import { FileUploadService } from "../../services/file-upload.service";
import { SubmissionAttachment } from '../../models/submission-attachment.model';
import { IncidentResponse, NonDisclosureOrderRequest, NonDisclosureOrderResponse } from '../../models/le-portal-dto';
import { LEPortalApiResponse, LEPortalApiResponseDiff } from '../../models/le-portal-api';

@Component({
  selector: 'dashboard-ndo',
  templateUrl: './dashboard-ndo.component.html',
  styleUrls: ['./dashboard-ndo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardNdoComponent implements OnInit {
  _frmNDOSearch: FormGroup;
  _frmNDOSearchleLensNumber: FormControl;
  _frmNDOExpirationDate: FormControl;
  _isNdoAttest: FormControl;
  _isNdoConfirm: FormControl;
  _acknowledgement: FormControl;
  _isNDOUploadFinalConfirm: FormControl;
  _frmNDOFileName: FormControl;
  
  _resetDataGrid: Subject<boolean> = new Subject<boolean>();
  _title: string = "";
  _steps: MenuItem[];
  _activeIndex: number = 0;
  _msgs: Message[] = [];
  _searchLNSList: Array<IncidentResponse> = [];
  _minDate: Date;
  _expDate: Date;
  _expDateDays: number = 90;
  _showValidationDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  _showSearchNotFoundDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  _showInternationalNotAllowedDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  _showSearchDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  _loading$ = new BehaviorSubject<boolean>(false);
  _validationErrors: string[] = []; 
  _notSame: boolean = false;
  _submissionHistoryLocators: string[] = [];
  _fileName: string = "";

  constructor(protected readonly _route: ActivatedRoute, private _router: Router, private _submissionService: SubmissionService, private _messageService: MessageService, private _spinnerService: SpinnerService, private _confirmationService: ConfirmationService, private _logger: AiLoggingService, private _fileUploadService: FileUploadService ){}

  ngOnInit()
  {
    this._steps = [
      {
        label: 'General',
        command: (event: any) => {
          this._activeIndex = 1;
        },
        tabindex: "-1"
      },
      {
        label: 'Search',
        command: (event: any) => {
          this._activeIndex = 1;
        },
        tabindex: "-1"
      },
      {
        label: 'Expiration',
        command: (event: any) => {
          this._activeIndex = 2;
        },
        tabindex: "-1"
      },
      {
        label: 'Upload',
        command: (event: any) => {
          this._activeIndex = 3;
        },
        tabindex: "-1"
      },
      {
        label: 'Submit',
        command: (event: any) => {
          this._activeIndex = 4;
        },
        tabindex: "-1"
      },
    ];
    this._frmNDOSearch = new FormGroup(
      {
        _frmNDOSearchleLensNumber: new FormControl(null),
        _frmNDOExpirationDate: new FormControl(null, Validators.required),
        _isNdoConfirm: new FormControl(null, Validators.required),
        _acknowledgement: new FormControl(null, [Validators.required, AcknowledgementValidator()]),
        _isNDOUploadFinalConfirm: new FormControl(null, Validators.required),
        _frmNDOSearch: new FormControl(null, Validators.required),
        _frmNDOFileName: new FormControl(null, Validators.required)
      }
    );
    this.formReset();

    if (this._router.url.includes("create")) {
      this.searchDialogOpen();
      // After the first Create remove the query parameter, so that if a user picks refresh it does not display the create/search screen again.
      this.deleteCreateQueryParameterFromCurrentRoute();
    }
  }

  deleteCreateQueryParameterFromCurrentRoute() {
    const params = { ...this._route.snapshot.queryParams };
    delete params.create;
    this._router.navigate([], { queryParams: params });
  }

  formReset()
  {
    try
    {
      this._title = "";
      this._validationErrors = [];
      this._searchLNSList = [];
      this.getfrmNDOSearchleLensNumber().setValue("");
      this._minDate = new Date();
      this._expDate = new Date();
      this._expDate.setDate(this._expDate.getDate() + this._expDateDays);
      this.getfrmNDOExpirationDate().setValue(this._expDate);
      let files = this.getfrmNDOFiles();
      if (files != null) {
        if (files.length > 0) {
          files.removeAt(0);
        }
      }
      this.getfrmIsNDOConfirm().setValue( false );
      this.getfrmNDOFileName().setValue("");
      this.getfrmIsNDOUploadFinalConfirm().setValue(false);
      this.getfrmAcknowledgement().setValue("");
      this._submissionHistoryLocators = [];
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  getfrmAcknowledgement() {
    return this._frmNDOSearch.get("_acknowledgement");
  }

  getfrmIsNDOConfirm() {
    var chk = this._frmNDOSearch.get("_isNdoConfirm");
    if (chk.value == null) {
      chk.setValue(false);
    }
    return chk;
  }

  getfrmIsNDOUploadFinalConfirm() {
    var chk = this._frmNDOSearch.get("_isNDOUploadFinalConfirm");
    if (chk.value == null) {
      chk.setValue(false);
    }
    return chk;
  }

  getfrmNDOExpirationDate() {
    return this._frmNDOSearch.get("_frmNDOExpirationDate");
  }

  getfrmNDOFileName() {
    return this._frmNDOSearch.get("_frmNDOFileName");
  }

  getfrmNDOFiles()
  {
    return this._frmNDOSearch.get("files") as FormArray;
  }

  getfrmNDOSearchleLensNumber(): AbstractControl {
    return this._frmNDOSearch.get("_frmNDOSearchleLensNumber");
  }

  getNDOExtensionById(gccOrLensNumber: string)
  {
    try
    {
      let lnsSearch: AbstractControl = this.getfrmNDOSearchleLensNumber();
      let inputLengthCutoff: number = 25;

      if (!lnsSearch || !lnsSearch.value || lnsSearch.value.length >= inputLengthCutoff) {
        this.showUserMessagePopup("info", "Notice", "MS Reference Number required");
        return;
      }

      if (this._searchLNSList.some(incidentResponse => incidentResponse.TicketNumber === lnsSearch.value))
      {
        this.showUserMessagePopup("info", "Notice", "Record already retrieved");
        return;
      }

      this.showLoading(true);

      this._submissionService
        .getNDOExtensionById(gccOrLensNumber)
        .pipe(
          tap((result: LEPortalApiResponse<IncidentResponse>) => {
            if (!result?.Data) {
              this.searchNotFoundDialogOpen();
              return;
            }

            if (result.Exception) {
              this.showUserMessagePopup("error", "Error", "An error occurred when retrieving the data from the API Service");
            }

            if (result.Data.length <= 0)
            {
              this.searchNotFoundDialogOpen();
              return;
            }

            let resultIncidentResponse: IncidentResponse = result.Data[0];

            if (!resultIncidentResponse || !resultIncidentResponse.Country || !resultIncidentResponse.TicketNumber || resultIncidentResponse.TicketNumber.length <= 0)
            {
              this.searchNotFoundDialogOpen();
              return;
            }

            if (resultIncidentResponse.Country.toUpperCase() !== "USA") {
              this.internatonalNotAllowedDialogOpen();
              return;
            }

            if (this._searchLNSList.some(incidentResponse => incidentResponse.TicketNumber === resultIncidentResponse.TicketNumber))
            {
              this.showUserMessagePopup("info", "Notice", "Record already retrieved");
              return;
            }

            this._searchLNSList.push(resultIncidentResponse);
            lnsSearch.reset('');
          }),
          finalize(() => {
            this.showLoading(false);
          })
        ).subscribe();
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  postSubmissionNDOExtensions() {
  try
  {
    this.showLoading(true);
    var ndoList: NonDisclosureOrderRequest[] = [];
    this.showLoading(true);
    this._searchLNSList.forEach((element, index) =>
    {
      let ndo: NonDisclosureOrderRequest = {
        ExpirationDate : this.getfrmNDOExpirationDate().value,
        IncidentId: element.IncidentId,
        Name: this.getfrmNDOFileName().value,
        SasUris: [],
        SubmissionHistoryLocator: this._submissionHistoryLocators[index],
        SubmissionFileName: this._fileName
      }
      ndoList.push(ndo);
    });
    this._submissionService
      .postSubmissionNDOExtensions(ndoList, this._submissionHistoryLocators , this._fileName )
      .pipe(
        tap((result: LEPortalApiResponseDiff<NonDisclosureOrderRequest,NonDisclosureOrderResponse>) =>
        {
          this.showLoading(false);
          if (result.Exception) {
            this._logger.logInformation("Failed to submit New NDO Extension.");
            this.showUserMessagePopup(
              "error",
              "NDO Extension Submission Error",
              "Submission failed. Please try again."
            );
          }
          else {
            this._logger.logInformation("Successfully submitted your New NDO Extension.");
            this.showUserMessagePopup(
              "success",
              "Submission Confirmation",
              "Thank you for your New NDO Extension Submission. Your request will be reviewed."
            );
            this.formReset();
            this._showSearchDialog$.next(false);
            this.resetDataGridForm(true);
          }
        }),
        catchError((error) => {
          this._logger.logInformation("Failed to submit New NDO Extension.");
          this.showUserMessagePopup(
            "error",
            "Submission NDO Extensions Error",
            "Submission failed.  Please try again."
          );
          this._spinnerService.stopSpinner();
          return of(error);
        })
      )
      .subscribe();
    }
  catch (err) {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  stepPrevious() {
    try {
      this._activeIndex = this._activeIndex - 1;
    }
    catch (err) {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  stepNext()
  {
    try
    {
      this.showLoading(true);
      this._validationErrors=[];
      switch (this._activeIndex) {
        case 1:
          if ( this._searchLNSList.length < 1 )
          {
            this._validationErrors.push( "Please add at least one MS Reference Number" );
          };
          break;
        case 2:
          if (!this.getfrmNDOExpirationDate().valid)
          {
            this._validationErrors.push( "Please select an Expiration Date" );
          }
          break;
        case 3:
            this._submissionHistoryLocators = [];
            this.validationUpload();
            if (this._validationErrors.length == 0) {
                this.uploadAttachment();
                this._activeIndex = this._activeIndex + 1;
              return;
            }
          break;
        case 4:
          if (this._submissionHistoryLocators.length == 0) {
            this.stepPrevious();
            this.showLoading(false);
            return;
          }
          if (!this.getfrmIsNDOUploadFinalConfirm().value) {
            this._validationErrors.push("Please confirm you have reviewed the uploaded file(s) and confirm that you have submitted a compliant Non-Disclosure Order.");
          }
          if (this.getfrmAcknowledgement().value != "I acknowledge") {
            this._notSame = true;
            this._validationErrors.push("Please type I acknowledge in the box below to acknowledge your NDO decision.");
          }
          if (this._validationErrors.length < 1)
          {
            this.postSubmissionNDOExtensions();
            return;
          }
          break;
      }
      if (this._validationErrors.length > 0) {
        this.validationDialogOpen();
        return;
      }
      this._activeIndex = this._activeIndex + 1;
      this.showLoading(false);
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }
 
  searchDialogOpen(): void
  {
    try
    {  
      this._activeIndex = 0;
      this._showSearchDialog$.next(true);
      this.formReset();
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  resetDataGridForm(refresh: boolean) {
    this._resetDataGrid.next(refresh);
  }

  searchDialogClose()
  {
    try
    {
      this._confirmationService.confirm({
        message: "Are you sure you want to cancel? Your progress will be lost.",
        accept: () => {
          this.uploadedAttachmentCancel();
          this._showSearchDialog$.next(false);
        }
      });
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  searchNotFoundDialogOpen()
  {
    try
    {
      this._showSearchNotFoundDialog$.next(true);
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  searchNotFoundDialogClose()
  {
    try
    {
      this._showSearchNotFoundDialog$.next(false);
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  internatonalNotAllowedDialogOpen() {
    try {
      this._showInternationalNotAllowedDialog$.next(true);
    }
    catch (err) {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  internatonalNotAllowedDialogClose() {
    try {
      this.getfrmNDOSearchleLensNumber().setValue("");
      this._showInternationalNotAllowedDialog$.next(false);
    }
    catch (err) {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  searchById()
  {
    try {
      this._validationErrors = [];
      if (!this.getfrmNDOSearchleLensNumber().valid)
      {
        this._validationErrors.push( "You must enter a valid LNS or MS Reference Number" );
        this.validationDialogOpen();
        return;
      }
      this.getNDOExtensionById(this.getfrmNDOSearchleLensNumber().value);
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  searchRowDelete(row: IncidentResponse)
  {
    try
    {
      this._confirmationService.confirm({
        message: "Are you sure that you want to remove " + row.TicketNumber + "?",
        accept: () => {
          this._searchLNSList.forEach((element, index) => {
            if (element == row) this._searchLNSList.splice(index, 1);
          });
        }
      }); 
    }
    catch (err)
    {
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  showDashboard(): void {
    this._router.navigateByUrl('dashboard');
  }

  showLoading(visible: boolean) {
    this._loading$.next(visible);
    if (visible) {
      this._spinnerService.startSpinner();
    }
    else {
      this._spinnerService.stopSpinner();
    }
  }

  showUserMessagePopup(type: string, title: string, message: string) {
    this.showLoading(false);
    this._messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }

  uploadAddControlValuesToForm(
    formGroup: FormGroup | FormArray,
    formToSend: FormData
  ): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key] as
        | FormControl
        | FormGroup
        | FormArray;
      if (control instanceof FormArray) {
        for (const file of control.controls) {
          const fileToUpload = file.value;
          formToSend.append(fileToUpload.name, fileToUpload);
        }
      } else if (control instanceof FormControl) {
        formToSend.append(key, control.value);
      } else if (control instanceof FormGroup) {
        this.uploadAddControlValuesToForm(control, formToSend);
      }
    });
  }

  uploadPrepareFormData(): FormData {
    const formToSend = new FormData();
    this.uploadAddControlValuesToForm(this._frmNDOSearch, formToSend);
    return formToSend;
  }

  uploadAttachment() {
    const formModel: FormData = this.uploadPrepareFormData();
    this._submissionHistoryLocators = [];
    var attachmentUpload = new Promise<void>((resolve, reject) => {
      this._searchLNSList.forEach((value, index, array) =>
      {
        this._fileUploadService
          .postAttachment(formModel)
          .pipe(
            tap((resp) => {
              let sub: SubmissionAttachment = resp.body;
              this._submissionHistoryLocators.push(sub.SubmissionHistoryLocator);
              this._fileName = sub.FileNames[0];
              if (index === array.length - 1) resolve();
            }),
            catchError((error) => {
              reject();
              throw of(error);
            })
          )
          .subscribe();
      });
    }).then(
      onfulfilled =>
      {
        this.showLoading(false);
        this.showUserMessagePopup(
          "success",
          "Attachment Upload Confirmation",
          "Successfully uploaded your attachment, please continue to finish the submission."
        );
      },
      onrejected => {
        this.showLoading(false);
        this.showUserMessagePopup(
          "error",
          "File Upload Error",
          "Submission failed. Please try again."
        );
      });
  }

  uploadedAttachmentCancel() {
    if (this._submissionHistoryLocators.length < 1) {
      return;
    }
    this._spinnerService.startSpinner();
    var attachmentCancel = new Promise<void>((resolve, reject) => {
      this._searchLNSList.forEach((value, index, array) => {
        this._fileUploadService
          .removeSubmissionRequest(this._submissionHistoryLocators[index])
          .pipe(
            tap(
              () => {
                if (index === array.length - 1) resolve();
              },
              catchError((error) => {
                reject();
                throw of(error);
              })
            )
          )
          .subscribe();
      });
    }).then(
      onfulfilled =>
      {
        this.showLoading(false);
        this.showUserMessagePopup(
          "success",
          "Attachment Cleanup Confirmation",
          "Successfully cleaned up your attachment."
        );
      },
      onrejected =>
      {
        this.showLoading(false);
        this.showUserMessagePopup(
          "error",
          "Cancel Error",
          "Part of the clean-up failed."
        );
      });
  }

  validationDialogClose() {
    this._showValidationDialog$.next(false);
  }

  validationDialogOpen() {
    this.showLoading(false);
    this._showValidationDialog$.next(true);
  }

  validationUpload() {
    let files = this.getfrmNDOFiles();
    var docName = false;
    if (this.getfrmNDOFileName().value == null) {
      docName = true;
    }
    else if (this.getfrmNDOFileName().value == '') {
      docName = true;
    }
    if (docName) {
      this._validationErrors.push("Please enter a Document Name.");
    }
    if (files.hasError("invalidMinCount")) {
      this._validationErrors.push("Please upload legal documentation associated with this request.");
    }
    else if (files.hasError("invalidSize")) {
      this._validationErrors.push("Your file did not meet the size requirements.");
    }
    else if (files.hasError("invalidExt")) {
      this._validationErrors.push("Your file has an invalid file extension.");
    }
    if (!this.getfrmIsNDOConfirm().value) {
      this._validationErrors.push("Please confirm you have uploaded a compliant Non-Disclosure Order.");
    }
  }
}
