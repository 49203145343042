import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { NatureOfCrimes } from "../../models/nature-of-crimes-model";
import { tap } from "rxjs/operators";
import { atLeastOneChecked } from '../validators/at-least-one.validator';

@Component({
  selector: "nature-of-crime",
  templateUrl: "./nature-of-crime.component.html",
  styleUrls: ["./nature-of-crime.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NatureOfCrimeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() crimeNature: string;
  @Input() crimeNatureInputLength = 0;
  natureOfCrimes: { displayName: string; formControlName: string; }[];
  crimeNatureMaxLength = 100;
  yesno: { label: string; value: boolean; icon: string }[];

  constructor() {}

  ngOnInit(): void {
    this.natureOfCrimes = NatureOfCrimes.getPropertyValues().map((item) => ({
      displayName: item,
      formControlName: NatureOfCrimes.modifyFormControlName(item),
    }));

    
    this.natureOfCrimes.forEach((noc) => {
      this.form.addControl(noc.formControlName, new FormControl(false, Validators.required));
    });

    this.form.setValidators(atLeastOneChecked);
    this.form.updateValueAndValidity();

    this.form.addControl(
      "isOtherDescription",
      new FormControl("", [
        Validators.maxLength(this.crimeNatureMaxLength),
      ])
    );
    this.setupOtherDescriptionSubscription();

    this.yesno = [
      { label: "Yes", value: true, icon: "pi pi-check" },
      { label: "No", value: false, icon: "pi pi-times" },
    ];
  }


  // clears the form if 'other' gets unchecked
  setupOtherDescriptionSubscription() {
    const otherControl = this.form.get("other");
    const otherDescriptionControl = this.form.get("isOtherDescription");

    otherControl.valueChanges.subscribe((value: boolean) => {
      if (value) {
        otherDescriptionControl.setValidators([Validators.required]);
      } else {
        otherDescriptionControl.clearValidators();
        otherDescriptionControl.setValue("");
        otherDescriptionControl.markAsPristine();
        otherDescriptionControl.markAsUntouched();
      }
      otherDescriptionControl.updateValueAndValidity();
    });
  }

  // toggle checkbox function
  toggleCheckbox(event: MouseEvent, control: AbstractControl) {
    event.preventDefault();
    if (control instanceof FormControl) {
      control.setValue(!control.value);
    }
  }
}
