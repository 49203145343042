
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../portal/services/authorization.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  public navBrandingText = 'LE Portal';
  public intlNavBrandingText = 'LE Portal - International Dashboard';
  public isScrolled = false;
  public showInternationalDashboard = false;
  public portalUrl: string;

  constructor(private authorizationService: AuthorizationService) { }

  ngOnInit() {
    this.portalUrl = environment.redirectUrl?.replace('auth', 'dashboard');
  }

  getUser() {
    return this.authorizationService.GetLePortalUser();
  }

  @HostListener('window:scroll')
  windowScroll() {
    this.isScrolled = window.pageYOffset >= 50;
  }
}
