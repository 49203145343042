<div class="error-container">

  <div [ngSwitch]="error || !!error">
    <h1 *ngSwitchCase="404"> Not found :(</h1>
    <h1 *ngSwitchCase="false">Unknown error</h1>
    <h1 *ngSwitchDefault>ERROR {{ data?.error}}</h1>
    <a mat-raised-button [routerLink]="'/home'">
      <h5>Go Home</h5>
    </a>
  </div>

  <div *ngIf="message">
    <h1 *ngIf="status"> ERROR {{ routeParams?.status }}</h1><br/>
    <h3 *ngIf="message">{{ routeParams?.message }}</h3><br/>
    <ng-container *ngIf="user != 'Unknown User'">
      <h1 *ngIf="url && url !== '/'">Error in {{ routeParams?.url | uppercase }} page, sorry {{ firstName }} :(</h1>
    </ng-container>
    <ng-container *ngIf="user === 'Unknown User'">
        <h1 *ngIf="url && url !== '/'">Error in {{ routeParams?.url | uppercase }} page</h1>
    </ng-container>
    <h4 *ngIf="id">This error has been reported to the Administrator with the ID:<br> {{ routeParams?.id}}</h4>
    <a mat-raised-button
      [routerLink]="url"
      *ngIf="url && status !== '404'">
        <h5>Go Back to {{url}}</h5>
    </a>  
  </div>  
</div>

<div class="pre-container" *ngIf="message">
  <p>Error sent to the server</p>
  <pre>
    {{ this.routeParams | json }}
  </pre>
</div>