import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '../core/components/error/error.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardNdoComponent } from './components/dashboard-ndo/dashboard-ndo.component';
import { DeliveryContentComponent } from './components/delivery-content/delivery-content.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { InternationalContentReviewComponent } from './components/international-dashboard/international-content-review/international-content-review.component';
import { InternationalDashboardComponent } from './components/international-dashboard/international-dashboard/international-dashboard.component';
import { NewUserAccountComponent } from './components/newuseraccount/newuseraccount.component';
import { NewUserVerifyComponent } from './components/newuserverify/newuserverify.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { ViewSubmissionsComponent } from './components/view-submissions/view-submissions.component';
import { AuthorizationGuard } from './services/authorization.guard';
import { DashboardGuard } from './services/dashboard.guard';
import { InternationalDashboardGuard } from './services/international-dashboard.guard';
import { NewUserGuard } from './services/new-user.guard';
import { NewRequestContainerComponent } from './components/new-request-container/new-request-container.component';

export const appRoutes: Routes = [
  { path: 'auth', component: MsalRedirectComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
    path: '',
    canActivateChild: [MsalGuard],
    children: [
      { 
        path: '',
        canActivate: [AuthorizationGuard],
        children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardGuard] },
          { path: 'dashboard-ndo', component: DashboardNdoComponent, canActivate: [DashboardGuard] },
          { path: 'international-dashboard', component: InternationalDashboardComponent, canActivate: [InternationalDashboardGuard] },
          { path: 'international-review/:id', component: InternationalContentReviewComponent, canActivate: [InternationalDashboardGuard] },
          { path: 'new-request', component: NewRequestContainerComponent},
          { path: 'view-submissions', component: ViewSubmissionsComponent},
          { path: 'delivery-content/:id', component: DeliveryContentComponent },
          { path: 'faq', component: FaqComponent },        ]
        },
        { path: 'newuseraccount', component: NewUserAccountComponent, canActivate: [ NewUserGuard] },
        { path: 'newuserverify', component: NewUserVerifyComponent, canActivate: [ NewUserGuard] },
    ]      
  },
    { path: 'home', component: HomePageComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'under-construction', component: UnderConstructionComponent},
    { path: 'under-maintenance', component: UnderMaintenanceComponent},
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // {enableTracing: true} // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})

export class PortalAppRoutingModule {
  getRoutes(): Routes {
    return appRoutes;
  }
}
