import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-trouble-dialog',
  templateUrl: './login-trouble-dialog.component.html',
  styleUrls: ['./login-trouble-dialog.component.scss']
})
export class LoginTroubleDialogComponent implements OnInit {
  supportedBrowsers: any = [
    {name: "Chrome", version: "Latest"},
    {name: "Firefox", version: "Latest and extended support release (ESR)"},
    {name: "Edge", version: "2 most recent major versions"},
    {name: "Safari", version: "2 most recent major versions"},
  ];
  
  constructor() { }

  ngOnInit(): void {
  
  }

}
