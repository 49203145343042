<div>
        <div id="registration-form-container" class="row h-100 p-3 my-5 mx-auto justify-content-center">
            <form [formGroup]="registrationForm" class="col" >
                <p-card>
                    <div class="row">
                        <div id="registration-title" class="col">
                            Verify Email
                        </div>
                        <div class="w-100"></div>
                        <div id="registration-subtitle" class="col">
                            <ng-container>Enter the code we sent to {{pendingEmailLink || 'the email address you submitted'}}. If you didn't get the email, check your junk folder or go back to the previous page to re-enter the validation email account for a new validation email.  If your 'validation email account' is a new account make sure that your email box exists prior to completing this step.</ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col"></div>
                        <div id="registration-fields-container"  class="col" >
                                <span class="ui-float-label">
                                    <input id="verifyCode" type="text" size="30" formControlName="verifyCode" pInputText>
                                    <label for="verifyCode">Enter code</label>
                                </span>
                            </div>      
                        <div class="col"></div>
                    </div>
                    <div class="row">
                        <div class="col"></div>
                        <div class="col m-2">
                            <div *ngIf="!newCodeSent && !errorResendingCode" id="resend-code-link" (click)="ResendVerificationCode()">Didn't receive a code?</div>                            
                            <div *ngIf="newCodeSent">New code sent</div>       
                            <div *ngIf="errorResendingCode" id="resend-code-link" (click)="GoBack()">Go Back</div>       

                        </div>
                        <div class="col"></div>
                    </div>
                    <div  class="row">                  
                            <div id="registration-fields-container" class="col">                                                      
                                <div id="registration-form-submit" class="row justify-content-center">
                                    <p-button [disabled]="IsPageInvalid()" (onClick)="SubmitVerification()" label="Submit"></p-button>
                                </div>
                            </div>
                    </div>                               
                    <div id="registration-form-footer">
                        <hr />
                        <h5>
                            <p class="registration-footer m-1">
                                <strong>Warning:</strong> This portal may only be used by authorized law enforcement officials to submit legal production orders related to official investigations pursuant to Title 18, United States Code,
                                Sections 2703 and 2711. Unauthorized use of this portal may be referred to law enforcement for investigation and prosecution.  By requesting access, you are acknowledging that you are a government official
                                making a request in official capacity.
                            </p>
                        </h5>
                    </div>
                </p-card>
            </form>
        </div>
      </div>      
	  
	  