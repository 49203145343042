<div id="login-display" *ngIf="(msalUser() | async)?.username">
  <a class="dropdown-toggle" (click)="toggleCollapse()">{{ (msalUser() | async).username }} <span class="caret"></span> </a>
  <div id="login-menu"class="collapse" [class.show]="show">
    <ul class="list-group">
      <li class="list-group-item">
        <a (click)="signOut()"><span class="pi pi-sign-out"></span>&nbsp;&nbsp;&nbsp;&nbsp;Sign Out</a>
      </li>
    </ul>
  </div>
</div>

