<header>
  <h2>Request &ndash; {{submissionData?.RecordLocatorId }}</h2>
</header>

<div class="row">
  <div class="col-md-12">
    <div class="info-bar" id="requestDuration" *ngIf="completed"> Request Duration: {{submissionData?.SubmittedOnUTC | dateAgo: submissionData?.IntlReviewLastActionOnUTC}}
    </div>
    <div class="info-bar" id="requestDuration" *ngIf="!completed"> Request Duration: {{submissionData?.SubmittedOnUTC | dateAgo: submissionData?.FulfilledOnUTC}}
    </div>
    <div class="info-bar">Current Review Status: {{this.intlReviewStatus[submissionData?.IntlReviewStatus]}}</div>
  </div>
</div>

<div class="row">
  <div class="col-10">
    <div class="row">
      <div class="col-md-4">
        <dl>
          <dt>Submitted by</dt>
          <dd>{{submissionData?.Submitter | default: 'None'}}</dd>

          <dt>Alternate Recipient</dt>
          <dd>{{submissionData?.OnBehalfOf | default: 'None'}}</dd>

          <dt>Nature of Crimes</dt>
          <ng-container *ngIf="(natureOfCrimes$ | async) as natureOfCrimes; else showNone">
            <div *ngFor="let item of natureOfCrimes">
              <ng-container *ngIf="item.displayName === 'Other'; else showDisplayName">
                <Label>{{ item.displayName }}: {{ submissionData.NatureOfCrimes?.DescriptionOther | default: 'None' }}</Label>
              </ng-container>
              <ng-template #showDisplayName>
                <label>{{ item.displayName }}</label>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #showNone>
            <label>None</label>
          </ng-template>
        </dl>
      </div>
      <div class="col-md-4">
        <dl>
          <dt>Submitted</dt>
          <dd class="col-md-4-date" pTooltip="Date format is shown as 'yyyy/mm/dd'" tooltipPosition="left">{{ submissionData?.SubmittedOnUTC | date:'yyyy/MM/dd, hh:mm UTC' | default: 'N/A' }}</dd>

          <dt>Approved</dt>
          <dd class="col-md-4-date" pTooltip="Date format is shown as 'yyyy/mm/dd'" tooltipPosition="left">{{submissionData?.IntlReviewApprovedOnUTC | date:'yyyy/MM/dd, hh:mm UTC' | default: 'Not Approved'}}</dd>
        </dl>
      </div>
      <div class="col-md-4">
        <dl>
          <dt>Fulfilled</dt>
          <dd class="col-md-4-date" pTooltip="Date format is shown as 'yyyy/mm/dd'" tooltipPosition="left">{{submissionData?.FulfilledOnUTC | date:'yyyy/MM/dd, hh:mm UTC'| default: 'Not Complete' }}</dd>

          <dt>Data Access</dt>
          <dd class="col-md-4-date" pTooltip="Date format is shown as 'yyyy/mm/dd'" tooltipPosition="left">{{submissionData?.AccessedOnUTC | date:'yyyy/MM/dd, hh:mm UTC'| default: 'Never'}}</dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div class="row">
      <div class="col-md-12" *ngIf="!completed">
        <div class="stacked-buttons-wrapper pull-right">
          <div>
            <p-button class="stacked-buttons" icon="pi pi-check" [disabled]="loading" label="Approve" (click)="submitApprove()" queryParamsHandling="preserve"></p-button>
            <p-button class="stacked-buttons" icon="pi pi-times" [disabled]="loading" label="Reject" (click)="toggleRejectDialog()" queryParamsHandling="preserve"></p-button>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="submissionData?.Country.ManualDelivery">
        <div class="stacked-buttons-wrapper pull-right">
          <div>
            <p-button [routerLink]="['/delivery-content/', submissionData?.SubmissionHistoryLocator]" class="delivery-buttons" icon="pi pi-check" [disabled]="loading" label="Perform Manual Delivery" queryParamsHandling="preserve"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-accordion [multiple]="true">
  <p-accordionTab>
    <p-header><span class="pi pi-arrow-down"></span>&nbsp; Uploaded Files</p-header>
    <p-table #dt [value]="submissionAttachments" [responsive]="true"
             [loading]="loading" sortField="CreatedOnUTC"
             sortMode="single" sortOrder="-1" autoLayout="true">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'FileName'">
            Name <p-sortIcon [field]="'FileName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'CreatedOnUTC'">
            Created (UTC)<p-sortIcon [field]="'CreatedOnUTC'"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-content let-rowData let-rowIndex="rowIndex">
        <tr style="border-style: none">
          <td class="ui-column-title">{{content.FileName}}</td>
          <td class="ui-column-title">{{content.CreatedOnUTC | date:'yyyy/MM/dd, hh:mm UTC'}}</td>
          <td class="ui-column-title">
            <button class="btn btn-link" (click)="doGetFile(content.RecordHistoryLocatorGuid, content.FileName)">
              <span class="pi pi-arrow-down"></span>&nbsp;Click to Download
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="5">
            <i>You have no submissions of this type.</i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>

<div class="mt-4" *ngIf="showAddMoreFilesButton && !completed">
  <p-button class="" icon="pi pi-plus" label="Add more files" (click)="toggleAddMoreFiles()" id="addMoreFilesButton"
            queryParamsHandling="preserve"></p-button>
</div>

<form [formGroup]="approveSubmissionForm">
  <div style="margin-top: 1rem;" *ngIf="showAddMoreFilesForm">
    <h4>Add more files</h4>
    <div class="form-group mb-4">
      <label>Please upload additional legal documentation associated with this request.</label>
      <ng-container [ngSwitch]="uploadButton">
        <file-upload-button *ngSwitchCase="true" (fileChanged)="handleFileChange($event)"></file-upload-button>
        <file-upload-button *ngSwitchDefault (fileChanged)="handleFileChange($event)"></file-upload-button>
      </ng-container>
      <files-to-upload class="submission-form-files" [files]="filesFormArray" (remove)="removeFile($event)"></files-to-upload>
      <div class="my-3">
        <p-message *ngIf="filesFormArray.errors && filesFormArray.errors.invalidMinCount" severity="info" text="No files added for upload."></p-message>
        <p-message *ngIf="filesFormArray.errors && filesFormArray.errors.invalidSize" severity="error" text="The total size exceeds 25 MB."></p-message>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <div class="row">
      <div class="col-2">
        <p-checkbox label="Flag for Escalation" id="flagForEscalation" binary="true" formControlName="flagForEscalation" ariaLabelledBy="flagForEscalation"></p-checkbox>
      </div>
      <div class="col-2" aria-label="Notice Permitted">
        <p-checkbox label="Notice to User is Permitted" id="noticePermitted" binary="true" formControlName="noticePermitted" ariaLabelledBy="noticePermitted"></p-checkbox>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <div class="d-inline font-weight-bold">
      Notes
    </div>
    <div class="d-inline text-warning" *ngIf="formNeedsNotes">
      * this field is required when rejecting a request
    </div>

    <textarea aria-label="notes field" class="mt-2" *ngIf="!completed" [disabled]="loading" formControlName="reviewNote" style="width: 100%; height: 200px;"></textarea>
    <textarea aria-label="notes field" class="mt-2" *ngIf="completed" disabled="true" style="width: 100%; height: 200px;">{{submissionData?.IntlReviewNotes}}</textarea>
  </div>
</form>

<div class="mt-4">
  <a href="#" [routerLink]="['/international-dashboard']" queryParamsHandling="preserve">
    <i class="pi pi-arrow-left"></i>&nbsp;
    Back to International Dashboard
  </a>
</div>

<p-dialog header="Reject Submission" [(visible)]="showRejectDialog" [style]="{ width: '450px' }">
  You are about to <strong>Reject</strong> this submission. If you would like to proceed, please press Continue. Otherwise, click Cancel.
  <p-footer>
    <div class="p-grid">
      <button type="button" pButton class="ui-button-secondary p-col" (click)="toggleRejectDialog()" label="Cancel"></button>
      <button type="button" pButton class="p-col" label="Continue" (click)="submitReject()"></button>
    </div>
  </p-footer>
</p-dialog>
