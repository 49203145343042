<div class="p-grid p-dir-col input-switch-container" [formGroup]="form">
  <div class="p-col">
    <p-message
      severity="warn"
      text="Please note that Microsoft may give notice to users and customers whose data is sought by a law enforcement agency or other government entity, except where prohibited by law."
    ></p-message>
    <hr />
  </div>
  <div class="p-col">
    <label
      >Is Notification to the Targeted User/Customer Permitted for this
      Request?</label
    >
    <div>
      <p-selectButton
        [options]="yesno"
        formControlName="notification"
      ></p-selectButton>
    </div>
  </div>
  <div class="p-col" *ngIf="!canNotify && form.dirty">
    <p-message
      severity="warn"
      text="Please be advised that your submission must reference law or national legislation that prohibits notice to the target for this request."
    ></p-message>
    <hr />
  </div>
</div>
