<p-toast [style]="{marginTop: '80px'}"></p-toast>
<p-dialog appendTo="body" header="We want to hear from you!" [draggable]="true" [(visible)]="display" [modal]="true" [responsive]="true"
  [baseZIndex]="10000" [formGroup]="form" (onHide)="resetForm()">
  <form  [formGroup]="form">
    <span class="ui-float-label">
      <input id="subject" type="text" size="30" pInputText formControlName="subject">
      <label for="subject">Subject</label>
    </span>
    <textarea id="comments" class="feedback-field" rows="10%" cols="100%" pInputTextarea formControlName="comments" placeholder="Please provide your comments, questions, or concerns."></textarea>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" [disabled]="form.invalid" (click)="submit()" label="Submit"></button>
      <button type="button" pButton icon="pi pi-close" (click)="display=false" label="Cancel" class="ui-button-secondary"></button>
    </p-footer>
  </form>
</p-dialog>

<div class="d-inline px-4" id="link-text" (click)="toggleFeedback()">
  Feedback
</div>