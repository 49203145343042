import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { SelectItem } from "primeng/api";

@Component({
  selector: "legal-notification",
  templateUrl: "./legal-notification.component.html",
  styleUrls: ["./legal-notification.component.scss"]
})
export class LegalNotificationComponent implements OnInit {
  @Input() form: FormGroup;
  yesno: SelectItem[];

  constructor() {}

  ngOnInit() {
    this.form.addControl(
      "notification",
      new FormControl(null, Validators.required)
    );

    this.yesno = [
      { label: "Yes", value: true, icon: "pi pi-check" },
      { label: "No", value: false, icon: "pi pi-times" }
    ];
  }

  get canNotify(): boolean {
    return this.form.get('notification').value;
  }
}
