import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
    private spinnerSource = new BehaviorSubject<boolean>(false)
    public SpinnerState$ = this.spinnerSource.asObservable();

    stopSpinner() {
      this.spinnerSource.next(false);
    }

    startSpinner() {
      this.spinnerSource.next(true);      
    }
}
