
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { ServiceMessage } from "../../models/service-message.model";
import { MessageCenterService } from "../../services/message-center.service";

@Component({
  selector: "message-center",
  templateUrl: "./message-center.component.html",
  styleUrls: ["./message-center.component.css"]
})
export class MessageCenterComponent implements OnInit, OnDestroy {
  public serviceMessages: ServiceMessage[];
  private ngUnsub: Subject<void> = new Subject<void>();

  constructor(
    private messageCenterService: MessageCenterService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.messageCenterService
      .GetMessages().pipe(
      takeUntil(this.ngUnsub))
      .subscribe({
        next: data => {
          this.serviceMessages = data;
        },
        complete: () => {
          if (this.serviceMessages.length > 0) {
            for (const msg of this.serviceMessages) {
              if (msg.Severity.toLowerCase() === "info") {
                this.messageService.add({
                  severity: msg.Severity,
                  summary: msg.Title,
                  detail: msg.Message,
                  key: "messageCenterInfo"
                });
              }

              if (msg.Severity.toLowerCase() === "warn") {
                this.messageService.add({
                  severity: msg.Severity,
                  summary: msg.Title,
                  detail: msg.Message,
                  key: "messageCenterWarn"
                });
              }

              if (msg.Severity.toLowerCase() === "error") {
                this.messageService.add({
                  severity: msg.Severity,
                  summary: msg.Title,
                  detail: msg.Message,
                  key: "messageCenterError"
                });
              }
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}
