import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  // template binding
  isGov: boolean = false;
  showTrainingVideo: boolean = false;
  showLoginTroubleDialog: boolean = false;
  registrationVideoUrl: string;

  constructor(
    private router: Router
    ) {}

  ngOnInit() {   
    this.registrationVideoUrl = environment.videoAssets.registrationVideoUrl;  
  } 

  login(){    
    this.router.navigate(["dashboard"])
  }

  toggleShowTrainingVideo(): void {
    this.showTrainingVideo = !this.showTrainingVideo;
  }

  toggleLoginTrouble(){
    this.showLoginTroubleDialog = !this.showLoginTroubleDialog;
  }
}
