import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import * as StackTraceParser from 'error-stack-parser';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/portal/services/authorization.service';
import { LEPortalError } from '../../portal/models/le-portal-error';
import { AiLoggingService } from './ai-logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private logger: AiLoggingService,
    private authService: AuthorizationService,
    private location: LocationStrategy
    ) {}

  log(error) {
    // Log the error to the console if we're in dev mode
    if (isDevMode()) {
      console.error(error);
    }
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    this.logger.logHandledException(errorToSend);
    return of(errorToSend);
  }

  addContextInfo(error) {
    // All the context details that you want (usually coming from other services; Constants, UserService...)
    const name = error.name || null;
    const url = this.location instanceof PathLocationStrategy ? this.location.path() : '';
    const status = error.status || null;
    const message = error.message || String(error);
    let user: string = null;
    var stack = null;

    this.authService.GetMsalUser().pipe(take(1)).subscribe(msalUser => user = msalUser?.username ?? "Unknown User");

    try
    {
      stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    }
    catch(err)
    {
      stack = null;
    }

    const errorToSend: LEPortalError = {
      name,
      user,
      url,
      status,
      message,
      stack
    };

    return errorToSend;
  }
}
