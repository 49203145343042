
<div [formGroup]="domestic">
  <h3 class="first">New Request - {{ country.Name }}</h3>
  <p-steps
    class="stepper"
    [model]="items"
    [activeIndex]="activeIndex.state$ | async"
  ></p-steps>
  <!-- Step 1 -->
  <!-- Use Hidden not NgIf Here to address parent-child bug with combineLatest -->
  <div [hidden]="(activeIndex.state$ | async) != 0" class="step-container">
    <type-of-request [form]="step1Form" (needsNdo)="updateNeedsNdo($event)">
    </type-of-request>
  </div>

 <!-- Step 2 -->
 <div *ngIf="(activeIndex.state$ | async) == 1" class="step-container">
  <nature-of-crime [form]="step2Form"></nature-of-crime>
</div>

  <!-- Step 3 -->
  <div *ngIf="(activeIndex.state$ | async) == 2" class="step-container">
    <ndo
      [form]="step3Form"
      [needsNdo]="needsNdo"
      (hasNdo)="updateHasNdo($event)"
    ></ndo>
  </div>

  <!-- Step 4 -->
  <div *ngIf="(activeIndex.state$ | async) == 3" class="step-container">
    <file-upload [form]="step4Form" [hasNdo]="hasNdo"> </file-upload>
  </div>

  <!-- Step 5 -->
  <div *ngIf="(activeIndex.state$ | async) == 4" class="step-container">
    <div class="p-grid p-dir col">
      <div class="p-col-12">
        <h3>Request Submission Review</h3>
      </div>
      <div class="p-col-12">
        <p-message
          severity="warn"
          text="Your submission is not complete until you click Submit"
        ></p-message>
        <hr />
      </div>
      <div class="p-col-6">
        <dl style="margin-bottom: 0px;">
          <dt>Request related to Child Exploitation?</dt>
          <dd>{{ childExploitation }}</dd>

          <dt>Are you submitting a Non-Disclosure Order (NDO)?</dt>
          <dd>{{ isSubmittingNDO }}</dd>

          <dt>Uploaded Files</dt>
          <dd style="margin-bottom: 0px;" *ngFor="let fileName of fileNames">
            {{ fileName }}&nbsp;&nbsp;&nbsp;&nbsp;
            <button class="btn btn-link" (click)="doGetFile(fileName)">
              <span class="pi pi-arrow-down"></span>&nbsp;Click to Download
            </button>
          </dd>
        </dl>
      </div>
      <div class="p-col-6">
        <dl style="margin-bottom: 0px;">
          <dt>Preservation Request?</dt>
          <dd>{{ isPreservation }}</dd>
          <dt>Alternate Recipient</dt>
          <dd>{{ differentRecipient }}</dd>
          <dt>Country of Origin</dt>
          <dd>{{ country.Name }}</dd>
          <dt>LE Reference Number</dt>
          <dd>{{ leReferenceNumber }}</dd>
        </dl>
      </div>
    </div>
    <hr />

    <div class="p-col">
      <p-message
        *ngIf="needsNdo"
        severity="warn"
        text="Please note that Microsoft gives prior notice to users and customers whose data is sought by a law enforcement agency or other governmental entity, except where prohibited by a valid and applicable nondisclosure order. Microsoft also provides delayed notice to users upon expiration of a valid and applicable nondisclosure order unless Microsoft, in its sole discretion, believes that providing notice could result in danger to identifiable individuals or groups or be counterproductive."
      ></p-message>
    </div>

    <div class="p-col ndo-confirm" *ngIf="hasNdo">
      <p-checkbox
        formControlName="isNdoUploadFinalConfirm"
        binary="true"
        label="I have reviewed the uploaded file(s) and confirm that I have submitted a Non-Disclosure Order that complies with 18 U.S.C. § 2705(b) and/or the legal process clearly identifies applicable federal/state law that may restrict notification to the user."
      ></p-checkbox>
      <hr />
    </div>

    <div class="p-col" *ngIf="needsNdo && hasNdo">
      <div
        style="background-color: lightgray; border: 1px solid black; padding: 1% 1% 1% 1%"
      >
        <p>
          I acknowledge that if I have not uploaded a legally valid
          Non-Disclosure Order or clearly referenced applicable federal/state
          law as part of this submission, Microsoft will, in its discretion and
          consistent with Microsoft’s obligations to its users and customers,
          take steps to notify the user or customer of this law enforcement
          request for information or will reject this request for discrepancies
          in the submission process.
        </p>
        <p>
          Please type "I acknowledge" in the box below to acknowledge your NDO
          decision.&nbsp;<span style="color: red">*</span>
        </p>
        <span class="ui-label mt-3">
          <input
            type="text"
            size="50"
            formControlName="acknowledgement"
            autocomplete="off"
            pInputText
          />
        </span>
        <ng-container *ngIf="notSame">
          <div>
            <span class="ui-messages-error" style="color: red;"
              >Does not match (case sensitive): I acknowledge</span
            >
          </div>
        </ng-container>
      </div>
      <hr />
    </div>

    <div class="my-3" *ngIf="needsNdo && !hasNdo">
      <div
        style="background-color: lightgray; border: 1px solid black; padding: 1% 1% 1% 1%"
      >
        <p>
          <u
            ><b
              >I acknowledge that I have indicated through this submission that
              I am NOT seeking to restrict notification to the targeted user or
              customer.</b
            ></u
          >
          Accordingly, Microsoft will, in its discretion and consistent with
          Microsoft’s obligations to its users and customers, take steps to
          notify the user or customer of this law enforcement request for
          information or will reject this request for discrepancies in the
          submission process.
        </p>
        <p>
          Please type "I acknowledge" in the box below to acknowledge your NDO
          decision.&nbsp;<span style="color: red">*</span>
        </p>
        <span class="ui-label mt-3">
          <input
            type="text"
            size="50"
            formControlName="acknowledgement"
            autocomplete="off"
            pInputText
          />
        </span>
        <ng-container *ngIf="notSame">
          <div>
            <span class="ui-messages-error" style="color: red;"
              >Does not match (case sensitive): I acknowledge</span
            >
          </div>
        </ng-container>
      </div>
      <hr />
    </div>

    <div class="my-3">
      <div style="background-color: lightgray; border: 1px solid black; padding: 1% 1% 1% 1%">
        <p>
          <b>I declare and attest, under penalty of perjury, under the laws of the United States and the State of Washington, that the foregoing is true and correct and that I am authorized to submit the accompanying legal request.</b>
        </p>
        <p>
          Please type "I acknowledge" in the box below to acknowledge.&nbsp;<span style="color: red">*</span>
        </p>
        <span class="ui-label mt-3">
          <input type="text" size="50" formControlName="WAShieldLawAcknowledgement" autocomplete="off" pInputText/>
        </span>
        <ng-container *ngIf="notSameWAShieldLawAcknowledgement">
          <div>
            <span class="ui-messages-error" style="color: red;">Does not match (case sensitive): I acknowledge</span>
          </div>
        </ng-container>
      </div>
    </div>

  </div>

  <div>
    <p-button
      type="button"
      label="Previous"
      (onClick)="previous()"
      *ngIf="
        (activeIndex.state$ | async) > 0 && (activeIndex.state$ | async) != 4
      "
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      label="Cancel"
      styleClass="ui-button-secondary"
      (onClick)="toggleSimpleCancelDialog()"
      *ngIf="(activeIndex.state$ | async) != 4"
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      label="Next"
      (onClick)="next()"
      *ngIf="(activeIndex.state$ | async) != 4"
    ></p-button>
    <p-button
      type="button"
      label="Cancel Request"
      styleClass="ui-button-secondary"
      data-testid="cancelBtn"
      (onClick)="toggleCancelWithFileDeleteDialog()"
      *ngIf="(activeIndex.state$ | async) == 4"
    ></p-button>
    &nbsp;
    <p-button
      type="button"
      [disabled]="isSubmitDisabled"
      label="Submit"
      data-testid="submitBtn"
      (onClick)="submit()"
      *ngIf="(activeIndex.state$ | async) == 4"
    ></p-button>
  </div>
</div>

<p-dialog
  header="Cancel Submission Request"
  [(visible)]="showCancelWithFileDeleteDialog"
  [style]="{ width: '450px' }"
>
  You are about to cancel and clear the form. All of your selections and
  uploaded files will be removed. If you would like to proceed, please press
  Continue. Otherwise, click Cancel.
  <p-footer>
    <div class="p-grid">
      <button
        type="button"
        pButton
        class="ui-button-secondary p-col"
        (click)="toggleCancelWithFileDeleteDialog()"
        label="Cancel"
      ></button>
      <button
        type="button"
        pButton
        class="p-col"
        label="Continue"
        (click)="deleteUploadedFilesAndCancel()"
      ></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="Cancel Submission"
  [(visible)]="showCancelSimpleDialog"
  [style]="{ width: '450px' }"
>
  You are about to cancel and clear the form. All of your selections will be
  removed. If you would like to cancel your submission, click Yes. To continue
  working on your submission, click No.
  <p-footer>
    <div class="p-grid">
      <button
        type="button"
        pButton
        class="ui-button-secondary p-col"
        (click)="toggleSimpleCancelDialog()"
        label="No"
      ></button>
      <button
        type="button"
        pButton
        class="p-col"
        label="Yes"
        [routerLink]="['/dashboard']"
      ></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  [(visible)]="showDomesticWarning"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-header>
    {{ domesticHeader }}
  </p-header>

  <p>{{ domesticWarningText }}</p>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="showDomesticWarning = false"
      label="Ok"
    ></button>
  </p-footer>
</p-dialog>
