<nav id="navbar-container" class="navbar fixed-top" [ngClass]="{'scrolled': isScrolled}">
    <div class="container-fluid">
        <div id="navbar-brand" *ngIf='!showInternationalDashboard'>
            <a href="{{portalUrl}}">
                <span>{{navBrandingText}}</span>
            </a>
        </div>
        <div id="navbar-brand" *ngIf='showInternationalDashboard'>
            <a href="{{portalUrl}}">
                <span>{{intlNavBrandingText}}</span>
            </a>
        </div>
        <ng-container *ngIf="(getUser() | async)?.IsVerified">
            <div class="navbar-item">
                <a href="faq">
                    FAQ
                </a>
            </div>
        </ng-container>
        <div id="navbar-signout" class="navbar-right">
            <login-display></login-display>
        </div>
    </div>
</nav>