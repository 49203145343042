import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AiLoggingService } from '../core/services/ai-logging.service';
import { SpinnerService } from '../core/services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './portal-app.component.html',
  styleUrls: ['./portal-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalAppComponent implements OnInit, OnDestroy {
  private ngUnsub: Subject<void> = new Subject<void>();
  public title = 'Submission Portal';
  public validSession = false;

  public constructor(
    private titleService: Title,
    private spinnerService: SpinnerService,
    private logger: AiLoggingService,    
    private router: Router    
  ) { }


  ngOnInit() {
    this.SetupPageLogging();
    this.titleService.setTitle('Microsoft Law Enforcement Portal');
    this.logger.logInformation("LE Portal App initialized");
  }

  get SpinnerState$(): Observable<boolean>{
    return this.spinnerService.SpinnerState$
  }
  
  private SetupPageLogging() {
    this.router.events.pipe(
      takeUntil(this.ngUnsub),
      tap(event => {
        if (event instanceof NavigationStart) {
          this.logger.logInformation(`Navigating to ${event.url}`);
        }
        if (event instanceof NavigationEnd) {
          this.logger.logInformation(`Navigated to ${event.url}.`);
        }
      }))
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}
