import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        
        // Handle specific error codes
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status == 403) {
            // Log it and send them on their way
            console.log("Pretending to log 401");
            this.router.navigate(['unauthorized']);
          }
          else if(err.status == 0) {
            console.log("status == 0? Something went wrong")
          }
          else if (err.status == 503) {
            console.log("Maintenance")
            this.router.navigate(['under-maintenance'])
          }
        }

        // Default behavior - bubble the error back up.
        return throwError(err);
      })
    );
  }
}
