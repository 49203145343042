import { AbstractControl, FormArray, ValidatorFn, FormControl, FormGroup } from '@angular/forms';

export function FileCountRange(minLimit?: number, maxLimit?: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    const filesToUpload = control as FormArray;

    if (maxLimit && filesToUpload.length > maxLimit) {
      return { invalidMaxCount: true };
    }
    else if (minLimit && filesToUpload.length < minLimit) {
      return { invalidMinCount: true };
    }
    else {
      return null;
    }
  }  
};

export function FileSize(bytesLimit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    const controlType = control as FormArray | FormControl;

    let cumulativeFileSizeBytes = 0;
    
    if(controlType instanceof FormArray){
      for (let control of controlType.controls) {
        cumulativeFileSizeBytes += control.value.size;
      }
      
    }else if(controlType instanceof FormControl){
      const file = controlType.value as File;
      cumulativeFileSizeBytes += file.size;
    }else{
      throw Error("Validator attached to invalid control type");
    }
    
    if (cumulativeFileSizeBytes > bytesLimit) {
      return { invalidSize: true };
    }

    return null;
  }
};

export function FileExtension(validFileExtensions: Array<string>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    let filesToUpload = control.value;
    let isValid = true;

    // multiple files
    if (Array.isArray(filesToUpload)) {
      if (filesToUpload.length > 0) {
        for (let fileToUpload of filesToUpload) {
          let ext = fileToUpload.name.toUpperCase().split('.').pop() || fileToUpload.name;
          isValid = validFileExtensions.indexOf(ext) > -1;
          if(!isValid){
            break;
          }
        }
        return isValid ? null : { invalidExt: true };
      }
    }

    // just one file
    else if (filesToUpload) {      
      let ext = filesToUpload.name.toUpperCase().split('.').pop() || filesToUpload.name;
      isValid = validFileExtensions.indexOf(ext) > -1;
      return isValid ? null : { invalidExt: true };
    }

    return null;
  }
};
