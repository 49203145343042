<div *ngIf="!selectedCountry">
    <h2>
        <u>
            Law Enforcement Submitting Request
        </u>
    </h2>
    <div class="grid">
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-10">
                <br />
                <em>As of 1st January 2024, Microsoft will only accept legal orders from the 27 EU Member States which are in English or accompanied with an English translation. This is in accordance with Article 4, Directive (EU) 2023/1544.</em>
            </div>
            <div class="col-1">
            </div>
        </div>
    </div>
    <p-listbox id="test"
               [options]="countries | async"
               [(ngModel)]="selectedCountry"
               optionLabel="Name"
               [style]="{ 'font-size': '44px', 'border-style': 'none' }"></p-listbox>
    <p-button (click)="toggleShowCountryNotListedDialog()" label="My country is not listed"></p-button>
    <p-dialog header="Country Not Listed"
              [(visible)]="showCountryNotListedDialog"
              [style]="{ width: '450px' }">
        If your country is not listed on this page, you are not yet able to use the LE Portal for submission of a new request.
        <p-footer>
            <div class="p-grid">
                <button type="button"
                        pButton
                        class="p-col"
                        (click)="toggleShowCountryNotListedDialog()"
                        label="Look again"></button>
                <button type="button"
                        pButton
                        class="ui-button-secondary p-col"
                        label="Cancel Request"
                        [routerLink]="['/dashboard']"></button>
            </div>
        </p-footer>
    </p-dialog>
</div>

<form [formGroup]="form" *ngIf="selectedCountry">
    <ndoredirect *ngIf="showDomestic && !_newRequest" [_ndoRedirectHide]="_ndoRedirectHide"></ndoredirect>
    <new-request-domestic [form]="form"
                          [country]="selectedCountry"
                          [showDomesticWarning]="true"
                          *ngIf="showDomestic && _newRequest"></new-request-domestic>
    <new-request-international [form]="form"
                               [country]="selectedCountry"
                               [showInternationalWarning]="true"
                               *ngIf="!showDomestic"></new-request-international>
</form>
