import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BlobContainer } from '../models/blob-container.model';

@Injectable({
  providedIn: 'root'
})
export class DeliveryLocationService {
  constructor(private httpClient: HttpClient) {}

  GetBlobContainerLocations(submissionId: string): Observable<BlobContainer[]> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json'
    });

    return this.httpClient.get<BlobContainer[]>(
      environment.deliveryUrl + `/listBlobContainers/${submissionId}`,
      { headers: headers, withCredentials: false }
    );
  }

  getSasUri(
    submissionId: string,
    containerLocatorId: string
  ): Observable<string> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json'
    });

    return this.httpClient.get<string>(
      environment.deliveryUrl +
        `/getSasUri/${submissionId}/${containerLocatorId}`,
      { headers: headers, withCredentials: false }
    );
  }
}
