import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, Subject, throwError as observableThrowError, timer, throwError } from 'rxjs';
import { finalize, map, take, takeUntil, tap, catchError } from 'rxjs/operators';
import { GenericActionStatus } from '../../enums/generic-action-status.enum';
import { DeliveryContent } from '../../models/delivery-content.model';
import { ExchangeRequest } from '../../models/exchange-request.model';
import { SubmissionHistory } from '../../models/submission-history.model';
import { DeliveryContentService } from '../../services/delivery-content.service';
import { ExchangeDataAccessService } from '../../services/exchange-data-access.service';
import { SubmissionService } from '../../services/submission.service';

@Component({
  selector: 'delivery-content',
  templateUrl: './delivery-content.component.html',
  styleUrls: ['./delivery-content.component.scss']
})
export class DeliveryContentComponent implements OnInit, OnDestroy {
  public loading = true;
  public deliveryData: DeliveryContent[];
  public submissionData: SubmissionHistory;
  public submissionId: string;
  private ngUnsub: Subject<void> = new Subject<void>();
  exchangeRequests: ExchangeRequest[];
  cols: any[];
  maximumDownloadAttempts: number;
  public showMailFeature: boolean;


  displayTimeDefault: number = 60;
  actionStatus: typeof GenericActionStatus = GenericActionStatus;

  // To track password statuses
  displayPasswordStatus: { [key: string]: number } = {}; // returns action status for the index
  countDown: { [key: string]: Observable<number> } = {}; // observable countdown to show timer for index
  passwordDisplayTime: { [key: string]: number } = {}; // time left to display, tracked by countDown

  // To Track Hash statuses
  displayHashStatus: { [key: string]: number } = {}; // returns action status for the index

  constructor(
    private deliveryService: DeliveryContentService,
    private exchangeDataService: ExchangeDataAccessService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private submissionService: SubmissionService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('id')) {
        this.submissionId = params.get('id');
      }
    });

    this.cols = [
      { field: 'Name', header: 'Name' },
      { field: 'SizeInMbytes', header: 'Size(MB)' },
      { field: 'TimesAccessed', header: 'Download Attempts' },
      { field: 'LastAccessedOnUtc', header: 'Last Accessed' },
      { field: 'CreatedOnUtc', header: 'Created' },
      { field: 'ExpiresOnUtc', header: 'Expires' },
      { field: 'BlobFilePassword', header: 'Password' },
      { field: 'BlobFileHash', header: 'Hash Value' }
    ];

    this.getMaximumDownloadAttempts();
    this.getSubmissionItem(this.submissionId);
    this.getExchangeRequests();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  getMaximumDownloadAttempts() {
    this.deliveryService
      .getMaximumDownloadAttempts()
      .pipe(
        tap(maxAttempts => this.maximumDownloadAttempts = maxAttempts),
        takeUntil(this.ngUnsub))
      .subscribe();
  }

  getSubmissionItem(submissionId) {
    this.submissionService
      .getSubmission(submissionId)
      .pipe(
        tap(val => this.submissionData = val),
        finalize(() => this.getDeliveryContent(submissionId)),
        takeUntil(this.ngUnsub))
      .subscribe();
  }

  getDeliveryContent(submissionId) {
    this.deliveryService
      .getContent(submissionId)
      .pipe(
        tap(value => {
          value.forEach((content: DeliveryContent) => {
            content.UniqueId = content.Name + this.randomString()
            this.displayPasswordStatus[
              content.UniqueId
            ] = this.actionStatus.WaitingToAct;
            this.displayHashStatus[
              content.UniqueId
            ] = this.actionStatus.WaitingToAct;
          });
          this.deliveryData = value;
        }),
        catchError(err => {
          this.messageService.add({
            severity: 'error',
            summary: 'Delivery Content Error',
            detail:
              'Your attempt to retrieve a list of deliverable content has failed. Refresh the page to try again.',
            key: 'toast-popup'
          });
          return throwError(err);
        }),
        finalize(() => this.loading = false),
        takeUntil(this.ngUnsub)
      )
      .subscribe();
  }

  showPassword(content: DeliveryContent) {
    // display password
    this.displayPasswordStatus[content.UniqueId] = this.actionStatus.Acted;

    // display password for a set amount of time
    this.passwordDisplayTime[content.UniqueId] = this.displayTimeDefault;
    this.countDown[content.UniqueId] = timer(0, 1000).pipe(
      take(this.passwordDisplayTime[content.UniqueId]),
      map(() => --this.passwordDisplayTime[content.UniqueId]),
      finalize(() => {
        this.displayPasswordStatus[
          content.UniqueId
        ] = this.actionStatus.WaitingToAct;
      })
    );
    this.messageService.add({
      severity: 'warn',
      summary: 'Copy Failed!',
      detail:
        'See input box to manually copy value',
      key: 'toast-popup'
    });
  }

  showHash(content: DeliveryContent) {
    // display hash
    this.displayHashStatus[content.UniqueId] = this.actionStatus.Acted;
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Copy Success!',
      detail:
        'Ctrl+V or right-click and paste to use',
      key: 'toast-popup'
    });
  }

  getExchangeRequests() {
    this.exchangeDataService
      .GetExchangeRequests(this.submissionId)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        requests => (this.exchangeRequests = requests),
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Exchange Requests Error',
            detail:
              'Your attempt to retrieve a list of exchange accounts has failed. Refresh the page to try again.',
            key: 'toast-popup'
          });
          this.loading = false;
          observableThrowError(error);
        },
        () => {
          this.loading = false;
        }
      );
  }


  randomString() {
    return String((Math.floor(Math.random() * 256) + 1));
  }

  getAuthorizedRecipient(): string {
    let authorizedRecipient = '';

    if (this.submissionData) {
      authorizedRecipient =
        this.submissionData.OnBehalfOf || this.submissionData.Submitter;
    }

    return authorizedRecipient;
  }

  // Get the total size of available files in Megabytes.
  // If there are no files available, return -1.
  getTotalSizeInMbytes(): number {
    let totalSize = 0;
    if (this.deliveryData) {
      for (const item of this.deliveryData) {
        totalSize += item.SizeInMbytes;
      }
    } else {
      totalSize = -1;
    }

    return parseFloat(totalSize.toPrecision(3));
  }

  doReactivateContent(submissionId) {
    this.loading = true;
    this.deliveryService
      .reactivateContent(submissionId)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        value => {
          const response = value;

          this.messageService.add({
            severity: response ? 'success' : 'warn',
            summary: 'Content Reactivation Submitted',
            detail: response ? 'Reactivation Submitted' : 'Pending Approval',
            key: 'toast-popup'
          });
          this.loading = false;
        },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Content Reactivation Error',
            detail: 'Content Reactivation request failed.  Please try again.',
            key: 'toast-popup'
          });
          this.loading = false;
          observableThrowError(error);
        },
        () => {
          this.loading = false;
          this.getSubmissionItem(this.submissionId);
        }
      );
  }

  doGetFile(submissionId, fileName) {
    this.loading = true;
    this.deliveryService
      .getFile(submissionId, fileName)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        value => {
          const file: DeliveryContent = value;
          window.open(file.Uri, '_blank');
          this.getSubmissionItem(this.submissionId);
        },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Content Download Error',
            detail:
              'Your attempt to download this file failed. Please try again.',
            key: 'toast-popup'
          });
          this.loading = false;
          observableThrowError(error);
        },
        () => {
          this.loading = false;
        }
      );
  }
}
