import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../services/app-config.service';
import { Observable } from 'rxjs';

@Component({
selector: 'under-maintenance',
templateUrl: './under-maintenance.component.html',
styleUrls: ['./under-maintenance.component.scss']
})

export class UnderMaintenanceComponent  implements OnInit {
  message$: Observable<string>;

  constructor(private appConfigService: AppConfigService)
  {    
  }

  ngOnInit() {
    this.message$ = this.appConfigService.GetSettings();
  }

}