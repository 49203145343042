import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AcknowledgementValidator } from '../validators/acknowledgement.validator';

@Component({
  selector: 'acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() declarationText: string;
  @Input() formControlId: string; 
  @Input() acknowledgementText: string = 'I acknowledge';
  @Input() errorMessage: string = 'Does not match (case sensitive): I acknowledge';
  public acknowledgementFormControlName: string;

  ngOnInit(): void {
    this.acknowledgementFormControlName = `acknowledgement_${this.formControlId}`;
    this.form.addControl(this.acknowledgementFormControlName, new FormControl(null, AcknowledgementValidator(this.acknowledgementText)));
  }

  get isInvalid() {
    const control = this.form.get(this.acknowledgementFormControlName);
    return control ? control.invalid && (control.dirty || control.touched) : false;
  }
  
}
