import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SortableColumn } from "primeng/table";
import { Subject, throwError, BehaviorSubject } from "rxjs";
import { catchError, takeUntil, tap, finalize } from "rxjs/operators";
import { AiLoggingService } from "src/app/core/services/ai-logging.service";
import { IntlReviewStatus } from "../../../enums/international-review-status.enum";
import { SubmissionStatus } from "../../../enums/submission-status.enum";
import { InternationalSubmissionHistory } from "../../../models/international-submission-history.model";
import { InternationalSubmissionService } from "../../../services/international-submission.service";

@Component({
  selector: "my-international-submissions",
  templateUrl: "./my-international-submissions.component.html",
  styleUrls: ["./my-international-submissions.component.scss"],
})
export class MyInternationalSubmissionsComponent implements OnInit, OnDestroy {
  private ngUnsub: Subject<void> = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(true);
  submissionStatus: typeof SubmissionStatus = SubmissionStatus;
  intlReviewStatus: typeof IntlReviewStatus = IntlReviewStatus;

  submissionData: InternationalSubmissionHistory[];

  constructor(
    private internationalSubmissionService: InternationalSubmissionService,
  ) { }

  ngOnInit() {
    this.getMySubmissions();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  getMySubmissions() {
    this.internationalSubmissionService
      .getInternationalSubmissions()
      .pipe(
        takeUntil(this.ngUnsub),
        tap((value) => this.submissionData = value),
        catchError(err => throwError(err)),
        finalize(() => this.loading$.next(false))
      )
      .subscribe();
  }
}
