
  <div class="p-grid p-dir-col" [formGroup]="form">
    <div class="p-col-12">
      <label id="selectServices">Select One or More Microsoft Services</label>
      <p-listbox [options]="services"
                [showToggleAll]="false"
                formControlName="selectedServices"
                [filter]="false"
                ariaLabelledBy="selectServices"
                multiple="true"
                checkbox="checkbox"
                optionLabel="Name"></p-listbox>
      <hr />
    </div>
    <div class="p-col-12">
      <label id="manualServices">Other Microsoft Services</label>
      <div>
        <p-chips formControlName="manualServices" 
                  addOnTab="true"
                  pTooltip="Selecting <Enter> or <Tab> is required after entering a Service in this free form input field."
                  showDelay="1000"
                  hideDelay="500"
                  tooltipEvent="hover"
                  life="2000"
                  ariaLabelledBy="manualServices">
          <ng-template let-item pTemplate="item">
            {{ item }}
            <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle"></i>
          </ng-template>
        </p-chips>
      </div>
      <p-message severity="info" text="Selecting <Enter> or <Tab> is required after entering a Service in this free form input field."></p-message>
      <hr />
    </div>
  </div>
