<p-confirmDialog #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Ok" (click)="cd.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>
<p-table #dt [columns]="cols" [value]="_exchangeRequests" [tableStyle]="{'overflow':'hidden'}" [paginator]="true" [rows]="10" sortField="gccNumber">

  <ng-template pTemplate="header" let-columns>
    <tr>      
      <th *ngFor="let col of columns"  [ngSwitch]="col.field">
          <div class="table-header">
              {{col.header}}
              <input id="targetFilter" aria-label="Account Filter" *ngSwitchCase="'TargetAccount'" placeholder="Account Filter" pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
            </div>
      </th>
      <th>
        Action
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td *ngFor="let col of columns">
        {{rowData[col.field]}}
      </td>
      <td>
        <div [ngSwitch]="passwordResetStatus[rowData.DestinationAccount]">
          <p-button *ngSwitchCase="0" label="Reset Password" (onClick)="confirmRequest(rowData)"></p-button>
          <button *ngSwitchCase="1" type="button" pButton label="Resetting..." class="ui-button-warning"></button>
          <button *ngSwitchCase="2" pButton label="Display New Password" (click)="confirmShowPassword(rowData)" class="ui-button-success"></button>
          <div *ngSwitchCase="3">
            <div class="ui-g">
              <div class="ui-g-12">
                <div class="ui-inputgroup">
                  <input type="text" pInputText value="{{passwordRequests[rowData.DestinationAccount]}}">
                  <span class="ui-inputgroup-addon">{{countDown[rowData.DestinationAccount] | async}}</span>
                </div>
              </div>
            </div>
          </div>
          <button *ngSwitchCase="4" type="button" pButton label="Failed Reset" class="ui-button-danger" (click)="resetPasswordRequestStatus(rowData)"></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
        <td [attr.colspan]="3">
            There is no content available for you to access, or you are not the authorized recipient.
        </td>
    </tr>
</ng-template>
</p-table>