import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { SpinnerService } from '../../../core/services/spinner.service';
import { Registration } from '../../models/registration.model';
import { AuthorizationService } from '../../services/authorization.service';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'newuserverify',
  templateUrl: './newuserverify.component.html',
  styleUrls: ['./newuserverify.component.scss']
})
export class NewUserVerifyComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  showDialog = false;
  statusText: string;
  submitEnabled = true;
  ngUnsub = new Subject<void>();
  pendingEmailLink: string;
  newCodeSent: boolean = false;
  errorResendingCode: boolean = false;
  

  constructor(
    private authService: AuthorizationService,
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.registrationForm = this.fb.group({
      verifyCode: ['', [Validators.required]]
    });
    if (this.registrationService.pendingAccountLinkEmail) {
      this.pendingEmailLink = this.registrationService.pendingAccountLinkEmail;
    }
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  get verifyCodeControl(): FormControl {
    return this.registrationForm.get('verifyCode') as FormControl;
  }

  IsPageInvalid() {
    return this.registrationForm.invalid;
  }

  GoBack(){
    this.router.navigate(['newuseraccount']);
  }

  ResendVerificationCode(){
    this.registrationService.ResendVerificationCode()
    .pipe(takeUntil(this.ngUnsub))
    .subscribe(
      success => {
        this.newCodeSent = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Verification Code Resent',
          detail:
            'A new code was sent to your inbox.',
          key: 'toast-popup'
        });
      },
      error => {
        this.errorResendingCode = true;
        this.messageService.add({
          severity: 'error',
          summary: 'Verification Code Failed to Resend',
          detail:
            'We were unable to send a new code at this time. Please try to Go Back and resubmit your associated email.',
          key: 'toast-popup'
        });
      }

    );    
  } 

  SubmitVerification() {
    this.spinnerService.startSpinner();
    const registration: Registration = new Registration();
    // Set the Verification code entered by the user.
    registration.VerifyCode = this.verifyCodeControl.value;
    this.registrationService
      .VerifyCode(registration)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(result => {
          if (result) {      
            this.authService.RefreshLePortalUser();                 
            this.router.navigate(['dashboard']);           
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Validation failed',
              detail:
                'The code was incorrect. Please verify this is the correct code, and try again.',
              key: 'toast-popup'
            });
          }
        }),
        catchError(error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Validation failed',
            detail:
              'Unable to verify your code at this time. Please wait, and try again.',
            key: 'toast-popup'
          });
          return error;
        }),
        finalize(() => this.spinnerService.stopSpinner()))
      .subscribe();
  }
}
