<div id="construction-image" class="d-flex flex-column justify-content-center outer">
  <div class="p-2 bd-highlight">
    <img src="../../../../assets/icon_construction.png" />
  </div>
</div>
<div id="construction-message" class="card text-center border-secondary mb-3 w-50">
  <div class="card-header">
    <h1><span class="pi pi-exclamation-triangle"></span></h1>
    <h2>This page is under construction</h2>
  </div>
  <div class="card-body">
    <p class="card-text">Thank you for your patience as we continue to add new features to this website.</p>
    <a href="" class="btn btn-primary">Back to Home</a>
  </div>
</div>
