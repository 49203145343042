import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class InternationalDashboardGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService, 
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizationService.GetLePortalUser().pipe(
      map((user: User) => {
        return user.IsOutsideCounsel
          ? true
          : this.handleIsNotOutsideCounsel();
      })
    )    
  }
  
  handleIsNotOutsideCounsel(): boolean {
    this.router.navigate(['/dashboard']);
    return false;
  }
}
