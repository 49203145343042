import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from "primeng/button";
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from "primeng/radiobutton";
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from "primeng/toast";
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ButtonModule,
    ChipsModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    RadioButtonModule,
    SelectButtonModule,
    SplitButtonModule,    
    ToastModule,
    AccordionModule,
    BrowserAnimationsModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    FieldsetModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    RadioButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    ToastModule,
    TooltipModule
  ],
  exports: [
    BrowserAnimationsModule,
    ButtonModule,
    ChipsModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    RadioButtonModule,
    SelectButtonModule,
    SplitButtonModule,    
    ToastModule,
    AccordionModule,
    BrowserAnimationsModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    FieldsetModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    RadioButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    ToastModule,
    TooltipModule
  ]
})
export class SharedPrimeNgModule {}
