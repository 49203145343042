import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { Country } from "../../models/country";
import { CountryListService } from "../../services/country-list.service";

@Component({
  selector: "new-request-container",
  templateUrl: "./new-request-container.component.html",
  styleUrls: ["./new-request-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewRequestContainerComponent implements OnInit {

  _newRequest = false;

  _ndoRedirectHide: Subject<boolean> = new Subject<boolean>();

  selectedCountry: Country;
  showCountryNotListedDialog: boolean = false;
  countries: Observable<Country[]>;
  form: FormGroup;

  constructor(private countryListService: CountryListService) {}

  ngOnInit() {
    this.form = new FormGroup({});
    this.countries = this.countryListService.GetCountryList();

    this._ndoRedirectHide.subscribe((hide) => {
      this._newRequest = hide;
    });
  }
  
  get showDomestic() {
    return this.selectedCountry.Name === "USA";
  }

  toggleShowCountryNotListedDialog(){
    this.showCountryNotListedDialog = !this.showCountryNotListedDialog;
  }
}
