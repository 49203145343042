<div id="page-container" class="container-fluid clearfix">
  <ngx-loading [show]="SpinnerState$ | async"
    [config]="{ primaryColour: '#d62d20', secondaryColour: '#ffffff', tertiaryColour: '#0057e7', fullScreenBackdrop: 'true' }">
  </ngx-loading>
  <navbar></navbar>
  <message-center *ngIf="validSession"></message-center>
  <p-toast key="toast-popup"></p-toast>
  <div id="body-container">
    <router-outlet></router-outlet>
  </div>
  <page-footer></page-footer>
</div>
