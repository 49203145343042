<div id="page-footer-container" class="fixed-bottom align-items-center">
    <div id="page-footer-links" class="text-right">
        <ul id="footer-links" class="mb-0">
            <li *ngIf="(getUser() | async)?.IsVerified" class="d-inline px-4">
                <feedback></feedback>
            </li>
            <li class="d-inline px-4">
                <a href="https://go.microsoft.com/fwlink/?LinkId=521839">Privacy & cookies</a>
            </li>
            <li class="d-inline px-4">
                <a href="https://go.microsoft.com/fwlink/?linkid=2259814">Consumer health privacy</a>
            </li>
            <li class="d-inline px-4">
                <a href="https://go.microsoft.com/fwlink/?LinkID=206977">Terms of use</a>
            </li>
            <li class="d-inline px-4">
                <a href="https://www.microsoft.com/trademarks">Trademarks</a>
            </li>
            <li class="d-inline px-4">
                <a href="https://choice.microsoft.com/">About our ads</a>
            </li>
            <li class="d-inline px-4">
                © Microsoft {{copyrightDate}}
            </li>
        </ul>
    </div>
</div>
