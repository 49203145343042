//This class is responsible for catching all unhandled errors.   
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AiLoggingService } from './core/services/ai-logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error) {
        const logger = this.injector.get(AiLoggingService);
        logger.logUnhandledException(error)        
    }
}