import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { LEPortalApiResponse, LEPortalApiResponseDiff } from '../../../models/le-portal-api';
import { IncidentResponse } from '../../../models/le-portal-dto';
import { AuthorizationService } from '../../../services/authorization.service';
import { SubmissionService } from '../../../services/submission.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { User } from '../../../models/user.model';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'ndo-my-submissions',
  templateUrl: './ndo-my-submissions.component.html',
  styleUrls: ['./ndo-my-submissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdoMySubmissionsComponent implements OnInit {
  @Input() _resetDataGrid: Subject<boolean> = new Subject<boolean>();

  public _loading$ = new BehaviorSubject<boolean>(true);
  public _selectedIncidentResponse: IncidentResponse;
  public _selectedIncidentResponses: IncidentResponse[];

  private _emailAddress: string = "";
  _msgs: Message[] = [];

  constructor(private _submissionService: SubmissionService, private _messageService: MessageService, private _authorizationService: AuthorizationService) { }

  ngOnInit()
  {
    try {

      this.getUserEmail();

      this._authorizationService.GetLePortalUser()
        .pipe(
          tap((user: User) => {
            this._emailAddress = user.OfficialEmailAddress;
          }))
        .subscribe();

      this.getMyNDOExtensions();
      this._resetDataGrid.subscribe((refresh) => {
        if (refresh) {
          this.newChanges();
        }
      });
    }
    catch (err) {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  newChanges() {
    this.getMyNDOExtensions();
  }

  getUserEmail()
  {
    try
    {
      this._authorizationService.GetLePortalUser()
      .pipe(
        tap((user: User) => {
          this._emailAddress = user.OfficialEmailAddress;
        }))
      .subscribe();
    }
    catch (err) {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  getMyNDOExtensions()
  {
    try
    {
      this.showLoading(true);
      this._submissionService
        .getMyNDOExtensions(this._emailAddress)
        .pipe(
          tap((result: LEPortalApiResponse<IncidentResponse>) => {
            this._selectedIncidentResponses = result.Data;
          }),
          finalize(() => {
            this.showLoading(false);
          })
        ).subscribe();
    }
    catch (err)
    {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  showLoading(visible: boolean)
  {
    this._loading$.next(visible);
  }

  showIncidentResponse(event, incidentResponse: IncidentResponse, overlaypanel: OverlayPanel)
  {
    try
    {
      this.showLoading(true);
      this._selectedIncidentResponse = incidentResponse;
      overlaypanel.toggle(event);
      this.showLoading(false);
    }
    catch (err)
    {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  showIncidentResponseDocument(ticketNumber:string) {
    try {
      this.showLoading(true);
      this._submissionService
        .getFileByRecordLocator(ticketNumber)
        .pipe(
          tap((result: LEPortalApiResponseDiff<string,string>) => {

            if (result.Exception) {
              this.showUserMessagePopup("error", "Error", "An error occurred when retrieving the file from the API Service");
            }
            else {
              if (result.DataResponse != null) {
                if (result.DataResponse.length > 0) {
                  window.open(result.DataResponse[0], "_blank");
                }
              }
              else {
                this.showUserMessagePopup("error", "Error", "An error occurred when retrieving the file from the API Service");
              }
            }
          }),
          catchError((error) => {
            this.showUserMessagePopup("error", "Error", "An error occurred when retrieving the file from the API Service");
            throw error;
          }),
          finalize(() => {
            this.showLoading(false);
          })
        )
        .subscribe();
    }
    catch (err) {
      this.showLoading(false);
      this.showUserMessagePopup("error", "Error", err);
    }
  }

  showUserMessagePopup(type: string, title: string, message: string)
  {
    this._messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }
}
