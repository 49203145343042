import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NewRequestDomestic } from '../models/new-request-domestic';
import { NewRequestInternational } from '../models/new-request-international';
import { SubmissionHistory } from '../models/submission-history.model';
import { LEPortalApiRequest, LEPortalApiRequestType, LEPortalApiResponse, LEPortalApiResponseDiff } from '../models/le-portal-api';
import { IncidentResponse, NonDisclosureOrderRequest, NonDisclosureOrderResponse } from '../models/le-portal-dto';
import { Observable } from 'rxjs';

@Injectable()
export class SubmissionService {

  _headers = new HttpHeaders({ 'Access-Control-Allow-Credentials': 'true' });

  constructor(private httpClient: HttpClient) { }

  getSubmissions() {
      const headers = new HttpHeaders
          ({
              'Access-Control-Allow-Credentials': 'true'
          });
        
      // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
      return this.httpClient
          .get<SubmissionHistory[]>(environment.mySubmissionsUrl, { headers: headers, withCredentials: false });
  }

  getSubmission(submissionHistoryId) {
      const headers = new HttpHeaders
          ({
              'Access-Control-Allow-Credentials': 'true'
          });

      // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
      return this.httpClient
          .get<SubmissionHistory>(environment.submissionUrl + `/${submissionHistoryId}`, { headers: headers, withCredentials: false });
  }

  postDomesticSubmission(newRequest: NewRequestDomestic) {   
          
      const headers = new HttpHeaders({
        "Access-Control-Allow-Credentials": "true"
      });
    
      return this.httpClient.post(
          environment.base +'/submission/new-request', newRequest,
        { headers: headers, withCredentials: false }
      );
  }    

  postInternationalSubmission(newRequest: NewRequestInternational) {   
          
      const headers = new HttpHeaders({
        "Access-Control-Allow-Credentials": "true"
      });
    
      return this.httpClient.post(
          environment.base +'/international/submission/new-request', newRequest,
        { headers: headers, withCredentials: false }
      );
  }

  getMyNDOExtensions(emailAddress: string): Observable<LEPortalApiResponse<IncidentResponse>> {
    let apiRequest: LEPortalApiRequest<IncidentResponse> = {
      ApiRequestType: LEPortalApiRequestType.GET,
      Data: [],
      UrlSuffix: `LERequest/getbyemail/${emailAddress}`
    }

    return this.httpClient.post<LEPortalApiResponse<IncidentResponse>>(environment.base + "/submission/getmyndoextensions/", apiRequest, { headers: this._headers, withCredentials: false });
  }

  getNDOExtensionById(gccOrLensNumber: string): Observable<LEPortalApiResponse<IncidentResponse>> {

    let apiRequest: LEPortalApiRequest<IncidentResponse> = {
      ApiRequestType: LEPortalApiRequestType.GET,
      Data: [],
      UrlSuffix: `LERequest/getbyid/${gccOrLensNumber}`
    }
    return this.httpClient.post<LEPortalApiResponse<IncidentResponse>>(environment.base + "/submission/getndoextensionbyid/", apiRequest, { headers: this._headers, withCredentials: false });
  }

  getFileByRecordLocator(recordLocatorID: string): Observable<LEPortalApiResponseDiff<string, string>> {
    let apiRequest: LEPortalApiRequest<string> = {
      ApiRequestType: LEPortalApiRequestType.GET,
      Data: [] = [],
      UrlSuffix: ``
    }
    apiRequest.Data.push(recordLocatorID);
    return this.httpClient.post<LEPortalApiResponseDiff<string, string>>(environment.base + "/submission/getfilebyrecordlocator", apiRequest, { headers: this._headers, withCredentials: false });
  }

  postSubmissionNDOExtensions(ndoList: NonDisclosureOrderRequest[], submissionHistoryLocatorIds: string[], submissionFileName: string): Observable<LEPortalApiResponseDiff<NonDisclosureOrderRequest, NonDisclosureOrderResponse>> {
    let apiRequest: LEPortalApiRequest<NonDisclosureOrderRequest> = {
      ApiRequestType: LEPortalApiRequestType.POST,
      Data: ndoList,
      UrlSuffix: "NonDisclosureOrder/new-ndo/"
    }
    return this.httpClient.post<LEPortalApiResponseDiff<NonDisclosureOrderRequest, NonDisclosureOrderResponse>>(environment.base + `/submission/submitndoextension/?submissionHistoryLocatorIds=${submissionHistoryLocatorIds}&submissionFileName=${submissionFileName}`, apiRequest, { headers: this._headers, withCredentials: false });
  }

}

