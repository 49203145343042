<h3 class="first">New Request - USA</h3>
<div class="grid">
    <div class="row"><div class="col-12">&nbsp;<!-- spacer row --></div></div>
    <div class="row">
        <div class="col-3">&nbsp;</div>
        <div class="col-9">
            <label>
                Are you submitting a Non-Disclosure Order (NDO) Extension to an existing request?
            </label>
        </div>
    </div>
    <div class="row"><div class="col-12">&nbsp;<!-- spacer row --></div></div>
    <div class="row">
        <div class="col-4">&nbsp;</div>
        <div class="col-4">
            <p-panel header="No - Create a New Request ">
                <div class="intentHeader">You <strong>do not have</strong> an existing LNS/GCC or MS Reference Number.</div>
                <br />
                <div class="intentSmall">&nbsp;&nbsp;&nbsp;&nbsp;Example: GCC/LNS-XXXXXX-XXXXXX</div>
                <br />
                <div class="intentGeneral">A request was not yet submitted and you wish to Create a New Request</div>
                <br />
                <div class="pull-right">
                    <p-button (click)="showNewRequest();" icon="pi pi-times" styleClass="ui-button-primary intentButton" label="No&nbsp;&nbsp;-&nbsp;&nbsp;Create New Request" queryParamsHandling="preserve" pTooltip="Click to Create New Request" tooltipPosition="left"></p-button>&nbsp;&nbsp;
                </div>
            </p-panel>
        </div>
        <div class="col-4">&nbsp;</div>
    </div>
    <div class="row"><div class="col-12">&nbsp;<!-- spacer row --></div></div>
    <div class="row">
        <div class="col-4">&nbsp;</div>
        <div class="col-4">
            <p-panel header="Yes -  Create NDO Extension">
                <div class="intentHeader">You <strong>have</strong> an existing LNS/GCC or MS Reference Number.</div>
                <br />
                <div class="intentSmall">&nbsp;&nbsp;&nbsp;&nbsp;Example: GCC/LNS-XXXXXX-XXXXXX</div>
                <br />
                <div class="intentGeneral">A request was already submitted and you wish to Create an NDO Extension</div>
                <br />
                <div class="pull-right">
                    <p-button (click)="showNDODashboard()" icon="pi pi-check" styleClass="ui-button-primary" label="Yes - Create NDO Extension" queryParamsHandling="preserve" pTooltip="Click to Create NDO Extension" tooltipPosition="left"></p-button>&nbsp;&nbsp;
                </div>
            </p-panel>
        </div>
        <div class="col-4">&nbsp;</div>
    </div>
</div>
