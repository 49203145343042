<div class="FlexContainerWrapper welcome-message-wrapper">
  <div class="mask">
    <div class="welcome-message ">
        <div class="row">
          <div class="justify-content-md-center FlexBannerItem FlexBannerItemCenter">
            <h3>Welcome to the Law Enforcement Request Portal</h3>
          </div>
        </div>
        <div class="row">          
          <div class="col "></div>
          <div class="FlexBannerItem col">
            This portal is only for the use of authorized law enforcement
            officials who wish to submit lawful orders seeking the production of
            evidence in connection with an official law enforcement
            investigation. Microsoft will evaluate each request on an
            individual, case-by-case basis consistent with applicable law, our
            customer commitments, and corporate policies.
          </div>
          <div class="col "></div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col FlexBannerItem " *ngIf="!isGov" id="acknowledgement">
            <label id="isAuthorizedUser">
              <p-checkbox binary="true" [(ngModel)]="isGov" pcheckbox508 [value508]="'Check I am an authorized user'"></p-checkbox></label>
            <span >
              I am an authorized law enforcement official or government employee, or have been granted access permission by Microsoft, and this is an official request.
            </span>
          </div>
          <div class="col FlexBannerItem FlexBannerItemCenter button-wrapper" *ngIf="isGov">
            <button id="login-button" pButton type="button" icon="pi pi-chevron-right" iconPos="right" (click)="login()" label="Login"></button>
            <div id="login-trouble" (click)="toggleLoginTrouble()" >Problems logging in?</div>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col "></div>
          <div class="col FlexBannerItem ">
            <h5>
              <p id="home-page-warning" class="m-1">
                <strong>Warning:</strong> This portal may only be used by
                authorized law enforcement officials to submit legal production
                orders related to official investigations pursuant to Title 18,
                United States Code, Sections 2703 and 2711. Unauthorized use of
                this portal may be referred to law enforcement for investigation
                and prosecution. By requesting access, you are acknowledging
                that you are a government official making a request in official
                capacity.
              </p>
            </h5>
          </div>
          <div class="col "></div>
        </div>
        <div class="row">
            <div class="col "></div>
            <div class="col FlexBannerItem FlexBannerItemCenter button-wrapper" *ngIf="isGov">
              <button id="training-button" pButton type="button" icon="pi pi-chevron-right" (click)="toggleShowTrainingVideo()" iconPos="right" label="View Account Registration and Login Training Video"></button>
            </div>
            <div class="col "></div>
        </div>        
    </div>
  </div>
</div>

<p-dialog header="Account Registration and Login" [modal]="true" [(visible)]="showTrainingVideo"  [style]="{ width: '682px' }">
  <video controls #videoPlayer>
    <source src="{{registrationVideoUrl}}" type="video/mp4" />
    Browser not supported
  </video>    
</p-dialog>

<p-dialog header="Login Troubles?" [modal]="true" [(visible)]="showLoginTroubleDialog" [style]="{'width': '450px'}">
  <login-trouble-dialog></login-trouble-dialog>    
</p-dialog>
