<div class="p-grid" [formGroup]="form">
  <!-- Agency Name -->
  <div class="p-col-12">
    <label for="agencyName">
      Requesting Law Enforcement Agency Name
    </label>
  </div>
  <div class="p-col-12">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-users" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agencyName"
        style="border-width: thin;"
        type="text"
        formControlName="agencyName"
        pInputText
        placeholder="Agency Name"
      />
    </div>
    <hr />
  </div>

  <!-- Requesting Agent -->
  <div class="p-col-12">
    Requesting Agent
  </div>
  <div class="p-col-4">
    <label for="agentFirstName">
      First Name
    </label>
  </div>
  <div class="p-col-4">
    <label for="agentLastName">
      Last Name
    </label>
  </div>
  <div class="p-col-4"></div>
  <div class="p-col-4">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-user" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agentFirstName"
        style="border-width: thin;"
        type="text"
        formControlName="agentFirstName"
        pInputText
        placeholder="First"
      />
    </div>
  </div>
  <div class="p-col-4">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-user" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agentLastName"
        style="border-width: thin;"
        type="text"
        formControlName="agentLastName"
        pInputText
        placeholder="Last"
      />
    </div>
  </div>
  <hr class="p-col-12" />

   <!-- Agent Email -->
  <div class="p-col-12">
    Requesting Agent
  </div>
  <div class="p-col-4">
    <label for="agentEmail">
      Email Address
    </label>
  </div>
  <div class="p-col-4">
    <label for="agentEmailConfirm">
      Confirm Email
    </label>
  </div>
  <div class="p-col-4"></div>
  <div class="p-col-4">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-envelope" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agentEmail"
        style="border-width: thin;"
        type="text"
        formControlName="agentEmail"
        pInputText
        placeholder="Email"
      />
    </div>
  </div>
  <div class="p-col-4">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-envelope" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agentEmailConfirm"
        style="border-width: thin;"
        type="text"
        formControlName="agentEmailConfirm"
        pInputText
        placeholder="Confirm"
      />
    </div>
  </div>
  <hr class="p-col-12" />

  <!-- Agent Phone -->
  <div class="p-col-12">
    <label for="agentPhoneNumber">
      Requesting Agent Phone Number
    </label>
  </div>
  <div class="p-col-12">
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon"
        ><i class="pi pi-mobile" style="line-height: 1.25;"></i
      ></span>
      <input
        id="agentPhoneNumber"
        style="border-width: thin;"
        type="text"
        formControlName="agentPhoneNumber"
        pInputText
        placeholder="Phone"
      />
    </div>
    <hr />
  </div>
