<div class="p-grid p-justify-center" [formGroup]="form">
    <table>
        <tr>
            <td class="dsaLabel">
                1. This portal processes orders in the usual manner, as well as orders impacted by the EU Digital Services Act (DSA). Is the order you are submitting today an Article 10 Digital Services Act-impacted order?
            </td>
            <td class="dsaColumnSeperator">&nbsp;</td>
            <td class="input-switch-container">
                <p-selectButton [options]="yesno" formControlName="isDsaRequest"></p-selectButton>
            </td>
        </tr>
        <tr *ngIf="isDsaRequest">
            <td>
                <p class="dsaPursuantText">
                    "Pursuant to Article 10.2 (a) of the EU Digital Services Act, your submitted legal order must include all of the following information, otherwise the order will be processed in the usual manner outside of the DSA:
                    <br />
                    <span class="dsaPursuantTextBold">§</span> The specific legal basis under either EU law or national law for obtaining this order (Art. 10.2.a.i).
                    <br />
                    <span class="dsaPursuantTextBold">§</span> The issuing authority (Art. 10.2.a.ii).
                    <br />
                    <span class="dsaPursuantTextBold">§</span> A valid identifier and the specific Microsoft services for which you are requesting data(Art. 10.2.a.iii).
                    <br />
                    <span class="dsaPursuantTextBold">§</span> Provide an explanation of why the information is required and why the required information is necessary and proportionate. If there is a reason why this information cannot be provided, please explain why (Art. 10.2.a.iv).
                    <br />
                    <span class="dsaPursuantTextBold">§</span> information about redress mechanisms available to the service provider and to the impacted user. (Art. 10.2.a.v).
                    <br />
                    <span class="dsaPursuantTextBold">§</span> where applicable, information about which authority is to receive the information regarding the effect given to the order (Art. 10.2.a.vi)."
                </p>
            </td>
            <td colspan="2">&nbsp;</td>
        </tr>
        <tr *ngIf="isDsaRequest">
            <td class="dsaLabel">
                2. Does your legal order comply with all of the above?" If your order does not contain all of the above information, please select "No", and you will be redirected to the usual legal order submission process.
            </td>
            <td class="dsaColumnSeperator">&nbsp;</td>
            <td class="input-switch-container">
                <p-selectButton [options]="yesno" formControlName="isLegalOrderCompliant"></p-selectButton>
            </td>
        </tr>
        <tr *ngIf="displayWarning">
            <td *ngIf="form.hasError('dsaComplianceError')" colspan="3" class="dsa-warning-text">
              If 'No' is selected, you must select 'No' for the above DSA selection to continue.
            </td>
          </tr>
        <tr *ngIf="isLegalOrderCompliant">
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr *ngIf="isLegalOrderCompliant">
            <td colspan="3" class="dsaLabel">3. Please identify the subcategory of illegal content that the order relates to:</td>
        </tr>
        <tr formGroupName="dsaSelections" *ngIf="isLegalOrderCompliant">
            <td colspan="3">
                <div class="flex-container">
                    <ng-container *ngFor="let dsaSC of natureOfDSASC; let i = index">
                        <div class="dsaSC-items-grid">
                            <p-checkbox [formControlName]="dsaSC.formControlName" binary="false"
                                        tabindex="{{i + 1}}" (keydown.space)="toggleCheckbox($event, form.get(dsaSC.formControlName))">
                            </p-checkbox>
                            <label [for]="'checkbox_' + i">{{ dsaSC.displayName }}</label>
                        </div>
                    </ng-container>
                </div>
            </td>
        </tr>
        <tr *ngIf="isLegalOrderCompliant">
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr *ngIf="isLegalOrderCompliant">
            <td colspan="3" class="dsaLabel">
                4. Please note that for orders processed under DSA, Microsoft is required to provide notice to the impacted user as per Article 10.5 of the DSA, unless you confirm that there is a valid non-disclosure order (NDO) in place.
            </td>
        </tr>
        <tr *ngIf="isLegalOrderCompliant">
            <td colspan="3">
                <table>
                    <tr>
                        <td>
                            <p class="microsoftRequiredNoticeText">
                                (i) I understand that Microsoft is required to provide notice to the impacted user as per Article 10.5, unless I confirm that there is a valid non-disclosure order (NDO) in place.
                            </p>
                        </td>
                        <td>
                            <p-checkbox binary="true" formControlName="isMicrosoftRequiredNoticeAcknowledgement"></p-checkbox>
                        </td>
                    </tr>
                </table>
                <table *ngIf="isMicrosoftRequiredNotice">
                    <tr>
                        <td>
                            <p class="microsoftRequiredNoticeText">
                                (ii) Is there a valid non-disclosure order (NDO) in place for this order?
                            </p>
                        </td>
                        <td>
                            <p-selectButton [options]="yesno" formControlName="isValidNDOInPlace"></p-selectButton>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="microsoftRequiredNoticeText">
                                (iii) If a valid NDO exists, does the order permit a delayed notice to the user?
                            </p>
                        </td>
                        <td>
                            <p-selectButton [options]="yesno" formControlName="isTemporaryNonDisclosure"></p-selectButton>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="microsoftRequiredNoticeText" style="text-align:right;">
                                Please indicate when notice can be provided:
                            </p>
                        </td>
                        <td>
                            <p-calendar [showTime]="false" showIcon="true" formControlName="nonDisclosureExpirationDate" [minDate]="_minDate"></p-calendar>
                        </td>
                    </tr>

                </table>
            </td>
        </tr>
    </table>
</div> 
<hr />

