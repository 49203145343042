import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { InternationalSubmissionHistory } from '../models/international-submission-history.model';

@Injectable({
  providedIn: 'root'
})
export class InternationalSubmissionService {

  constructor(private httpClient: HttpClient) { }

  getInternationalSubmissions() {
      const headers = new HttpHeaders
          ({
              'Access-Control-Allow-Credentials': 'true'
          });
      
      // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
      return this.httpClient
          .get<InternationalSubmissionHistory[]>(environment.base + `/international/submission/my`, { headers: headers, withCredentials: false });
  }

  getInternationalSubmission(submissionHistoryId) {
      const headers = new HttpHeaders
          ({
              'Access-Control-Allow-Credentials': 'true'
          });

      // Note - error handling should be handled by the HttpErrorInterceptor class, as well as the calling component.
      return this.httpClient
          .get<InternationalSubmissionHistory>(environment.base + `/international/submission/${submissionHistoryId}`, { headers: headers, withCredentials: false });
  }
}
