export enum SubmissionStatus {
  'All' = -1,
  'Under Review' = 0,
  'Ready for Download' = 1,
  'Ready for Delivery OnBehalfOf' = 2,
  'In Process' = 3,
  'Data Retrieved' = 4,
  'Expired' = 98,
  'Rejected' = 99
}
