import { Component, Input, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable, timer } from "rxjs";
import { finalize, map, take, catchError } from "rxjs/operators";
import { SpinnerService } from "../../../core/services/spinner.service";
import { PasswordResetStatus } from "../../enums/password-reset-status.enum";
import { ExchangeRequest } from "../../models/exchange-request.model";
import { PasswordService } from "../../services/password.service";

@Component({
  selector: "email-monitoring",
  templateUrl: "./email-monitoring.component.html",
  styleUrls: ["./email-monitoring.component.scss"]
})

export class EmailMonitoringComponent implements OnInit {

  @Input()
  submissionId: string;
  @Input()

  set exchangeRequests(requests: ExchangeRequest[]) {
    if (requests) {
      this._exchangeRequests = requests;
      requests.forEach(
        (request: ExchangeRequest) =>
        (this.passwordResetStatus[
          request.DestinationAccount
        ] = this.passwordResetStatuses.WaitingToReset)
      );
    }
  }

  _exchangeRequests: ExchangeRequest[];
  countDown: { [key: string]: Observable<number> } = {};
  passwordDisplayTime: { [key: string]: number } = {};
  displayTimeDefault: number = 60;
  passwordResetStatuses: typeof PasswordResetStatus = PasswordResetStatus;
  passwordResetStatus: { [key: string]: number } = {};
  passwordRequests: { [key: string]: string } = {};

  cols: any[];

  constructor(
    private passwordResetService: PasswordService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() :void {
    this.cols = [
      { field: "TargetAccount", header: "Identifier" },
      { field: "DestinationAccount", header: "Destination Account" }
    ];
  }

  sendUserMessagePopup(type: string, title: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }

  confirmRequest(exchangeRequest: ExchangeRequest) {
    this.confirmationService.confirm({
      message:
        "Resetting this password is permanent. Are you sure that you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinnerService.startSpinner();
        this.passwordResetStatus[
          exchangeRequest.DestinationAccount
        ] = this.passwordResetStatuses.Resetting;
        this.passwordRequests[exchangeRequest.DestinationAccount] = null;
        this.passwordResetService
          .ResetPassword(exchangeRequest)
          .pipe(
            take(1),
            catchError(error => {

              this.passwordResetStatus[exchangeRequest.DestinationAccount] = this.passwordResetStatuses.ResetFail;

              this.spinnerService.stopSpinner();

              this.sendUserMessagePopup(
                "error",
                "Password Reset Failure",
                "Your attempt to perform a Password Reset Failed."
              );

              throw error;

            })
          )
          .subscribe(newPassword => {

            this.passwordResetStatus[exchangeRequest.DestinationAccount] = this.passwordResetStatuses.ReadyToDisplay;

            this.passwordRequests[exchangeRequest.DestinationAccount] = newPassword;

            this.spinnerService.stopSpinner();

            this.sendUserMessagePopup(
              "success",
              "Password Reset Success",
              "Your attempt to perform a Password Reset Succeeded."
            );

          });
      },
      reject: () => {

      }

    });
  }

  confirmShowPassword(exchangeRequest: ExchangeRequest) {
    this.passwordDisplayTime[
      exchangeRequest.DestinationAccount
    ] = this.displayTimeDefault;
    this.countDown[exchangeRequest.DestinationAccount] = timer(
      0,
      1000
    ).pipe(
      // for DEFAULT time (e.g. 60 seconds)
      take(this.passwordDisplayTime[exchangeRequest.DestinationAccount]),
      // Decrement time and return
      map(
        () => --this.passwordDisplayTime[exchangeRequest.DestinationAccount]
      ),
      // Then reset the status to "waiting to reset" to hide the password
      finalize(
        () =>
        (this.passwordResetStatus[
          exchangeRequest.DestinationAccount
        ] = this.passwordResetStatuses.WaitingToReset)
      )
    );
    this.passwordResetStatus[
      exchangeRequest.DestinationAccount
    ] = this.passwordResetStatuses.DisplayPassword;
  }

  resetPasswordRequestStatus(request: ExchangeRequest) {
    this.passwordResetStatus[
      request.DestinationAccount
    ] = this.passwordResetStatuses.WaitingToReset
  }
}
